import { Suspense } from 'react';
import { Navigate } from 'react-router';
import { Row } from 'reactstrap';
import ErrorBoundary from '../ErrorBoundary';
import Loader from '../Loader';
import { Pages } from '../../enums';

const GuardedRoute = ({ auth, children }: { auth: boolean; children: any }) => {
  if (!auth) {
    return (
      <ErrorBoundary>
        <Suspense
          fallback={
            <Row className="align-items-center justify-content-center w-100">
              <Loader size="xl" />
            </Row>
          }
        >
          <Navigate to={Pages.LOGIN} />
        </Suspense>
      </ErrorBoundary>
    );
  }

  return (
    <ErrorBoundary>
      <Suspense
        fallback={
          <Row className="align-items-center justify-content-center w-100">
            <Loader size="xl" />
          </Row>
        }
      >
        {children}
      </Suspense>
    </ErrorBoundary>
  );
};

export { GuardedRoute };
