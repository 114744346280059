import { Accordion, AccordionBody, AccordionHeader, AccordionItem, List, Row } from 'reactstrap';
import { ReactNode, useEffect, useState } from 'react';

const CollapseList = ({
  addon,
  items,
  listItems,
  accordionListHeader,
  accordionFormHeader,
  closeAccordion,
  setCloseAccordion,
  children,
}: AccordionListProps) => {
  const [openAccordion, setOpenAccordion] = useState('1');

  useEffect(() => {
    if (!closeAccordion) {
      return;
    }
    setOpenAccordion('1');
    setCloseAccordion(false);
  }, [closeAccordion]);

  const onProductInputAccordionToggle = (id: string) => {
    if (id === '1' && (!items || !items.length)) {
      return;
    }
    if (openAccordion !== id) {
      setOpenAccordion(id);
    }
  };

  return (
    <Row>
      {/*TODO: might be some dependency issue, console logs that toggle is required, but linter says it's unknown*/}
      {/*@ts-ignore*/}
      <Accordion defaultOpen="1" open={openAccordion} toggle={(id) => onProductInputAccordionToggle(id)}>
        <AccordionItem className={items && items.length ? '' : 'accordion-disabled'}>
          <AccordionHeader targetId="1">
            <div className="d-flex align-items-center gap-4">
              <div>{accordionListHeader}</div>
              {addon}
            </div>
          </AccordionHeader>
          <AccordionBody accordionId="1">
            <List className="m-0 p-0">{listItems}</List>
          </AccordionBody>
        </AccordionItem>
        {!!children && !!accordionFormHeader && (
          <AccordionItem>
            <AccordionHeader targetId="2">{accordionFormHeader}</AccordionHeader>
            <AccordionBody accordionId="2">{children}</AccordionBody>
          </AccordionItem>
        )}
      </Accordion>
    </Row>
  );
};

type AccordionListProps = {
  children?: ReactNode;
  items: any[];
  listItems: ReactNode[];
  addon?: ReactNode;
  accordionListHeader: string;
  accordionFormHeader?: string;
  closeAccordion: boolean;
  setCloseAccordion: (close: boolean) => void;
};

export { CollapseList };
