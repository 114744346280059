const getBase64 = async (file: Blob): Promise<string | undefined> => {
  const reader = new FileReader();
  reader.readAsDataURL(file);

  return new Promise((resolve, reject) => {
    reader.onload = () => resolve(reader.result as any);
    reader.onerror = (error) => reject(error);
  });
};

export { getBase64 };
