enum PaymentMethod {
  PAYPAL = 'PAYPAL',
  BANK_TRANSFER = 'BANK_TRANSFER',
  CASH = 'CASH',
}

enum PaymentStatus {
  UNPAID = 'UNPAID',
  CREDITED = 'CREDITED',
  REFUNDED = 'REFUNDED',
  TO_REFUND = 'TO_REFUND',
  PAID = 'PAID',
}

export { PaymentMethod, PaymentStatus };
