import { useEffect, useState } from 'react';
import { Button, Col, Form, Row, Spinner } from 'reactstrap';
import { toastr } from 'react-redux-toastr';
import { FieldArray, Formik, FormikTouched, setNestedObjectValues } from 'formik';
import * as yup from 'yup';
import { v4 } from 'uuid';
import { Delete as DeleteIcon } from '@styled-icons/fluentui-system-filled/Delete';
import { Upload } from './components/Upload';
import { TranslationPairForm } from './components/TranslationPairForm';
import { SectionTranslationPairForm } from './components/SectionTranslationForm';
import { SimpleTranslationPairForm } from './components/CreateSimpleTranslationForm';
import { ContactForm } from './components/ContactForm';
import { CustomInput } from '../../components/Input/CustomInput';
import { CreateAddressForm } from '../../components/Forms/CreateAddressForm';
import Divider from '../../components/Divider';
import {
  apiRequest,
  getBase64,
  ACCEPTED_IMAGES,
  MAX_CRAFTSMAN_IMAGES,
  MAX_IMAGE_SIZE,
  TOAST_IMPORTANT_TIME_OUT_MS,
  handleError,
  MAX_CRAFTSMAN_PROFILE_IMAGES,
} from '../../utils';
import { CraftsmanType, Pages, SupportedCities } from '../../enums';
import {
  CreateAddressSchema,
  initialAddress,
  initialSection,
  initialSectionItem,
  initialSEO,
  initialSimpleTranslation,
  initialTranslation,
  SectionsSchema,
  SEOSchema,
  SimpleTranslationSchema,
  SimpleTranslationSchemaWorksPics,
  TranslationSchema,
} from '../../types';
import { useNavigate, useParams } from 'react-router';
import { CityDropdown } from '../../components/CityDropdown/CityDropdown';
import Tooltip from '../../components/Tooltip';
import { SEOTranslationPairForm } from './components/SEOTranslationPairForm';
import { Craftsman } from '../../types';
import BackDetailsLink from '../../components/BackDetailsLink/BackDetailsLink';

type CraftsmanDetailsProps = {
  type?: 'new' | 'existing';
};

const CraftsmanSchema = yup.object({
  _id: yup.string().optional().nullable(),
  number: yup.string().optional(),
  comment: yup.string().optional(),
  type: yup.mixed<CraftsmanType>().oneOf(Object.values(CraftsmanType)).required('This field is required.'),
  workPicturesTranslationKey: SimpleTranslationSchemaWorksPics,
  emailTexts: yup.object({
    de: TranslationSchema,
    en: TranslationSchema,
  }),
  seo: SEOSchema,
  addressPrimary: yup.string(),
  addressSecondary: CreateAddressSchema,
  profilePicUrl: yup.string(),
  additionalPicUrls: yup
    .array()
    .of(yup.string()),
  slogan: SimpleTranslationSchema,
  quote: SimpleTranslationSchema,
  sections: SectionsSchema,
  displayRank: yup.string().matches(/^\d+$/, 'This field must be numerical').required('This field is required.'),
  cityList: yup
    .array()
    .of(yup.mixed<SupportedCities>().oneOf(Object.values(SupportedCities)))
    .min(1, 'You should select at least one city')
    .required('This field is required.'),
  active: yup.boolean(),
});
type CraftsmanSchemaType = yup.InferType<typeof CraftsmanSchema>;

const CraftsmanDetails = ({ type = 'new' }: CraftsmanDetailsProps) => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [fullNameEntered, setCraftsmanNameEntered] = useState(type !== 'new');
  const [values, setValues] = useState<CraftsmanSchemaType>({
    _id: '',
    number: '',
    comment: '',
    type: CraftsmanType.Cobbler,
    workPicturesTranslationKey: initialSimpleTranslation,
    emailTexts: initialTranslation,
    seo: initialSEO,
    addressPrimary: '',
    addressSecondary: initialAddress,
    profilePicUrl: '',
    additionalPicUrls: [],
    slogan: initialSimpleTranslation,
    quote: initialSimpleTranslation,
    sections: initialSection,
    displayRank: '',
    cityList: [] as SupportedCities[],
    active: true,
  });
  const [loading, setLoading] = useState(false);
  const [craftsmen, setCraftsmen] = useState<Craftsman[]>([]);
  const [reloadCraftsmen, setReloadCraftsmen] = useState(false);

  const unpublishCraftsman = async (unpublishCraftsmanId: Craftsman['_id']) => {
    try {
      await apiRequest(`/tailor/unpublish/${unpublishCraftsmanId}`, { method: 'PATCH' });
      setCraftsmen((prevCraftsmen) => prevCraftsmen.map((craftsman) => (craftsman._id === unpublishCraftsmanId ? { ...craftsman, active: false } : craftsman)));
      setReloadCraftsmen(true);
    } catch (err) {
      console.log(err);
    }
  };

  const publishCraftsman = async (publishCraftsmanId: Craftsman['_id']) => {
    try {
      await apiRequest(`/tailor/publish/${publishCraftsmanId}`, { method: 'PATCH' });
      setCraftsmen((prevCraftsmen) => prevCraftsmen.map((craftsman) => (craftsman._id === publishCraftsmanId ? { ...craftsman, active: true } : craftsman)));
      setReloadCraftsmen(true);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    if (type === 'new') {
      return;
    }
    onLoad();
  }, [id]);

  const enableLoading = () => {
    setLoading(true);
  };
  const disableLoading = () => {
    setLoading(false);
  };
  const onCraftsmanNameChange = () => {
    setCraftsmanNameEntered(false);
  };
  const onCraftsmanNameBlur = () => {
    setCraftsmanNameEntered(true);
  };

  const onLoad = async () => {
    const abortController = new AbortController();
    try {
      enableLoading();
      const { data } = await apiRequest(`/tailor/by-id/${id}`, { signal: abortController.signal });
      const { message, data: tailor } = data;

      if (message) {
        if (Array.isArray(message)) {
          message.forEach((msg) => toastr.error('Error', msg, { timeOut: TOAST_IMPORTANT_TIME_OUT_MS }));
        } else {
          toastr.error('Error', message, { timeOut: TOAST_IMPORTANT_TIME_OUT_MS });
        }
        abortController.abort();
        return disableLoading();
      }
      const { firstName, lastName } = tailor.addressSecondary.contact;
      delete tailor.addressSecondary.contact.firstName;
      delete tailor.addressSecondary.contact.lastName;
      tailor.addressSecondary.contact['fullName'] = [firstName, lastName].join(' ');
      setValues(tailor);
      disableLoading();
    } catch (err) {
      handleError('FETCH_CRAFTSMAN', err);
      if (err instanceof DOMException && err.name === 'AbortError') {
        toastr.error('Error', 'Your browser aborted the request', { timeOut: TOAST_IMPORTANT_TIME_OUT_MS });
      } else {
        toastr.error('Error', 'Failed to fetch craftsman by ID', { timeOut: TOAST_IMPORTANT_TIME_OUT_MS });
      }
      abortController.abort();
    }
  };

  const onCreate = async (values: CraftsmanSchemaType, action: any) => {
    const abortController = new AbortController();
    try {
      enableLoading();
      const valuesCopy = { ...values };
      delete valuesCopy._id;
      delete valuesCopy.addressSecondary._id;
      const { data } = await apiRequest('/tailor', { method: 'POST', data: JSON.stringify(valuesCopy), signal: abortController.signal });
      const { message } = data;

      if (message) {
        if (Array.isArray(message)) {
          message.forEach((msg) => toastr.error('Error', msg, { timeOut: TOAST_IMPORTANT_TIME_OUT_MS }));
        } else {
          toastr.error('Error', message, { timeOut: TOAST_IMPORTANT_TIME_OUT_MS });
        }
        action.setSubmitting(false);
        abortController.abort();
        return disableLoading();
      }
      disableLoading();
      toastr.success('Success', 'Craftsman created');
      navigate(Pages.CRAFTSMEN);
    } catch (err) {
      handleError('CREATE_CRAFTSMAN', err);
      if (err instanceof DOMException && err.name === 'AbortError') {
        toastr.error('Error', 'Your browser aborted the request', { timeOut: TOAST_IMPORTANT_TIME_OUT_MS });
      } else {
        toastr.error('Error', 'Failed to create craftsman', { timeOut: TOAST_IMPORTANT_TIME_OUT_MS });
      }
      abortController.abort();
    }
  };

  const onUpdate = async (values: CraftsmanSchemaType, action: any) => {
    const abortController = new AbortController();
    try {
      enableLoading();
      const valuesCopy = { ...values };
      delete valuesCopy._id;
      const { data } = await apiRequest(`/tailor/${values._id}`, {
        method: 'PUT',
        data: JSON.stringify(valuesCopy),
        signal: abortController.signal,
      });
      const { message } = data;

      if (message) {
        if (Array.isArray(message)) {
          message.forEach((msg) => toastr.error('Error', msg, { timeOut: TOAST_IMPORTANT_TIME_OUT_MS }));
        } else {
          toastr.error('Error', message, { timeOut: TOAST_IMPORTANT_TIME_OUT_MS });
        }
        action.setSubmitting(false);
        return disableLoading();
      }
      disableLoading();
      toastr.success('Success', 'Craftsman updated');
      window.location.reload();
      navigate(`/craftsmen/${id}`);
    } catch (err) {
      handleError('UPDATE_CRAFTSMAN', err);
      if (err instanceof DOMException && err.name === 'AbortError') {
        toastr.error('Error', 'Your browser aborted the request', { timeOut: TOAST_IMPORTANT_TIME_OUT_MS });
      } else {
        toastr.error('Error', 'Failed to update craftsman', { timeOut: TOAST_IMPORTANT_TIME_OUT_MS });
      }
      abortController.abort();
    }
  };

  const onSubmit = async (formik: any) => {
    const formikErrors = await formik.validateForm();
    if (Object.keys(formikErrors).length) {
      formik.setTouched(setNestedObjectValues<FormikTouched<CraftsmanSchemaType>>(formikErrors, true));
      return null;
    }

    formik.values.addressPrimary = `${formik.values.addressSecondary.street}, ${formik.values.addressSecondary.zipCode} ${formik.values.addressSecondary.city}, ${formik.values.addressSecondary.country}`;
    formik.values.addressSecondary.id = v4();

    const { fullName } = formik.values.addressSecondary.contact;
    const [firstName, lastName] = fullName.split(' ');

    formik.values.addressSecondary.contact.firstName = firstName;
    formik.values.addressSecondary.contact.lastName = lastName;
    delete formik.values.addressSecondary.contact.fullName;
    formik.values.displayRank = +formik.values.displayRank;

    if (type === 'new') {
      await onCreate(formik.values, {
        setSubmitting: formik.setSubmitting,
      });
    } else if (type === 'existing') {
      await onUpdate(formik.values, {
        setSubmitting: formik.setSubmitting,
      });
    }
  };

  const onImageUpload = async (file: File, fullName: string, setUploading: (state: boolean) => void, imageType: 'image' | 'profile-image') => {
    const abortController = new AbortController();
    try {
      setUploading(true);
      const base64File = (await getBase64(file)) as string;

      if (!base64File) {
        return toastr.error('Error', `Error while uploading ${file.name}`);
      }

      const { data } = await apiRequest(`tailor/image/${fullName}?imageType=${imageType}`, {
        method: 'POST',
        data: JSON.stringify({ file: base64File, filename: file.name, filetype: file.type }),
        signal: abortController.signal,
      });
      const { message } = data;

      if (message) {
        if (Array.isArray(message)) {
          message.forEach((msg) => toastr.error('Error', msg, { timeOut: TOAST_IMPORTANT_TIME_OUT_MS }));
        } else {
          toastr.error('Error', message, { timeOut: TOAST_IMPORTANT_TIME_OUT_MS });
        }
        return setUploading(false);
      }

      setUploading(false);
    } catch (err) {
      handleError('UPLOAD_CRAFTSMAN_IMAGE', err);
      if (err instanceof DOMException && err.name === 'AbortError') {
        toastr.error('Error', 'Your browser aborted the request', { timeOut: TOAST_IMPORTANT_TIME_OUT_MS });
      } else {
        toastr.error('Error', 'Failed to upload craftsman image', { timeOut: TOAST_IMPORTANT_TIME_OUT_MS });
      }
      abortController.abort();
    }
  };

  const onImageDelete = async (filename: string, setDeleting: (state: boolean) => void, imageType: 'image' | 'profile-image') => {
    const abortController = new AbortController();
    try {
      setDeleting(true);
      const { data } = await apiRequest(`tailor/image?filename=${filename}&imageType=${imageType}`, {
        method: 'DELETE',
        signal: abortController.signal,
      });
      const { message } = data;

      if (message) {
        if (Array.isArray(message)) {
          message.forEach((msg) => toastr.error('Error', msg, { timeOut: TOAST_IMPORTANT_TIME_OUT_MS }));
        } else {
          toastr.error('Error', message, { timeOut: TOAST_IMPORTANT_TIME_OUT_MS });
        }
      }
    } catch (err) {
      handleError('DELETE_CRAFTSMAN_IMAGE', err);
      if (err instanceof DOMException && err.name === 'AbortError') {
        toastr.error('Error', 'Your browser aborted the request', { timeOut: TOAST_IMPORTANT_TIME_OUT_MS });
      } else {
        toastr.error('Error', 'Failed to delete craftsman image', { timeOut: TOAST_IMPORTANT_TIME_OUT_MS });
      }
      abortController.abort();
    } finally {
      setDeleting(false);
    }
  };

  const loadCraftsmanImages = async (fullName: string, imageType: 'image' | 'profile-image') => {
    const abortController = new AbortController();
    try {
      const { data } = await apiRequest(`tailor/images/${fullName}?imageType=${imageType}`, {
        method: 'GET',
        signal: abortController.signal,
      });
      const { message, images } = data;

      if (message) {
        if (Array.isArray(message)) {
          message.forEach((msg) => toastr.error('Error', msg, { timeOut: TOAST_IMPORTANT_TIME_OUT_MS }));
        } else {
          toastr.error('Error', message, { timeOut: TOAST_IMPORTANT_TIME_OUT_MS });
        }
        return [];
      }

      return images;
    } catch (err) {
      handleError('FETCH_CRAFTSMEN_IMAGES', err);
      if (err instanceof DOMException && err.name === 'AbortError') {
        toastr.error('Error', 'Your browser aborted the request', { timeOut: TOAST_IMPORTANT_TIME_OUT_MS });
      } else {
        toastr.error('Error', 'Failed to fetch craftsman images', { timeOut: TOAST_IMPORTANT_TIME_OUT_MS });
      }
      abortController.abort();
    }
  };

  return (
    <Col className="mh-100 overflow-auto px-3">
      <Row>
        <Col>
          <h3 className="text-secondary mb-3">Craftsman Details</h3>
        </Col>
        <Col className="d-flex justify-content-end">
          <BackDetailsLink path={Pages.CRAFTSMEN} />
        </Col>
      </Row>
      <Formik enableReinitialize initialValues={values} validationSchema={CraftsmanSchema} onSubmit={onSubmit}>
        {(formik) => {
          const { fullName } = formik.values.addressSecondary.contact;

          const onPublishClick = () => {
            formik.setFieldValue('active', true)
            publishCraftsman(id ? id : '');
          };
        
          const onUnpublishClick = () => {
            formik.setFieldValue('active', false)
            unpublishCraftsman(id ? id : '');
          };

          const onProfilePictureSelect = (url: string) => {
            formik.setFieldValue('profilePicUrl', url);
          };
          const setAdditionalPicUrls = (urls: string[]) => {
            formik.setFieldValue('additionalPicUrls', urls);
          };
          const setType = (type: CraftsmanType) => {
            formik.setFieldValue('type', type);
          };
          const onCityListChange = (city: string) => {
            if (formik.values.cityList.some((val) => val === city)) {
              formik.setFieldValue(
                'cityList',
                formik.values.cityList.filter((val) => val !== city)
              );
            } else {
              formik.setFieldValue('cityList', [...formik.values.cityList, Object.values(SupportedCities).find((val) => val === city)]);
            }
          };

          return (
            <Form id="create-craftsman" className="d-flex flex-column gap-4">
              <Row>
                <h6>Contact Information</h6>
              </Row>
              <ContactForm formik={formik} onNameChange={onCraftsmanNameChange} onNameBlur={onCraftsmanNameBlur} />
              <Row>
                <Col className="d-flex flex-column gap-2" md={6}>
                  <Row>
                    <p>Craftsman Type</p>
                  </Row>
                  <Row>
                    {Object.values(CraftsmanType).map((type) => (
                      <Col key={type}>
                        <Button color="dark" className={`btn-md w-100 ${formik.values.type === type ? 'checked' : ''}`} onClick={() => setType(type)}>
                          {type}
                        </Button>
                      </Col>
                    ))}
                  </Row>
                </Col>
                <Col md={3}>
                  <CityDropdown
                    selectedCities={formik.values.cityList as SupportedCities[]}
                    onCitySelect={onCityListChange}
                    touched={formik.touched.cityList}
                    errors={formik.errors.cityList as string}
                  />
                </Col>
                <Col md={3}>
                  <CustomInput
                    label="Craftsman Number"
                    inputProps={{
                      id: 'number',
                      name: 'number',
                      type: 'text',
                      value: formik.values.number,
                      onChange: formik.handleChange,
                      placeholder: 'This field will be created automatically',
                    }}
                    error={formik.touched.number && formik.errors.number ? formik.errors.number : ''}
                  />
                </Col>
              </Row>
              <Row className="align-items-center">
                <Col md={6}>
                  <CustomInput
                    label="Comment"
                    inputProps={{
                      id: 'comment',
                      name: 'comment',
                      type: 'textarea',
                      value: formik.values.comment,
                      onChange: formik.handleChange,
                    }}
                    error={formik.touched.comment && formik.errors.comment ? formik.errors.comment : ''}
                  />
                </Col>
                <Col className="d-flex align-items-center gap-3">
                  <label className="d-flex align-items-center gap-2">
                    <input type="checkbox" className="checkbox" name="publish" checked={formik.values.active === true} onChange={onPublishClick} />
                    Public
                  </label>
                  <label className="d-flex align-items-center gap-2">
                    <input type="checkbox" className="checkbox" name="unpublish" checked={formik.values.active === false} onChange={onUnpublishClick} />
                    Not Public
                  </label>
                </Col>
              </Row>
              <Divider />
              <Row>
                <h6>Address</h6>
              </Row>
              <CreateAddressForm formik={formik} field="addressSecondary" hasWorkingHours />
              <Divider />
              <Row>
                <Col md={3}>
                  <h6>Ranking</h6>
                  <CustomInput
                    label="Display Rank"
                    inputProps={{
                      id: 'displayRank',
                      name: 'displayRank',
                      type: 'text',
                      value: formik.values.displayRank,
                      onChange: formik.handleChange,
                    }}
                    addon={
                      <Tooltip placement="bottom" target="display-example">
                        <Col>
                          <p>Display order on this page:</p>
                          <img src="/images/craftsman/craftsman-display-rank.png" alt="Example" width={400} height={200} />
                        </Col>
                      </Tooltip>
                    }
                    error={formik.touched.displayRank && formik.errors.displayRank ? formik.errors.displayRank : ''}
                  />
                </Col>
              </Row>
              <Divider />
              <Row>
                <h6>Email Translations</h6>
              </Row>
              <TranslationPairForm
                formik={formik}
                field="emailTexts"
                addonHeaderEn={
                  <Tooltip placement="bottom" target="email-header-example-en">
                    <img src="/images/craftsman/craftsman-email-header-example-en.png" alt="Example" width={400} height={330} />
                  </Tooltip>
                }
                addonContentEn={
                  <Tooltip placement="bottom" target="email-content-example-en">
                    <img src="/images/craftsman/craftsman-email-content-example-en.png" alt="Example" width={400} height={330} />
                  </Tooltip>
                }
                addonHeaderDe={
                  <Tooltip placement="bottom" target="email-header-example-de">
                    <img src="/images/craftsman/craftsman-email-header-example-de.png" alt="Example" width={400} height={330} />
                  </Tooltip>
                }
                addonContentDe={
                  <Tooltip placement="bottom" target="email-content-example-de">
                    <img src="/images/craftsman/craftsman-email-content-example-de.png" alt="Example" width={400} height={330} />
                  </Tooltip>
                }
              />
              <Divider />
              <Row>
                <h6>Slogan</h6>
              </Row>
              <SimpleTranslationPairForm
                formik={formik}
                field="slogan"
                addonEn={
                  <Tooltip placement="bottom" target="slogan-example-en">
                    <img src="/images/craftsman/craftsman-slogan-example-en.png" alt="Example" width={400} height={140} />
                  </Tooltip>
                }
                addonDe={
                  <Tooltip placement="bottom" target="slogan-example-de">
                    <img src="/images/craftsman/craftsman-slogan-example-de.png" alt="Example" width={400} height={140} />
                  </Tooltip>
                }
              />
              <Divider />
              <Row>
                <h6>Quote</h6>
              </Row>
              <SimpleTranslationPairForm
                formik={formik}
                field="quote"
                addonEn={
                  <Tooltip placement="bottom" target="quote-example-en">
                    <img src="/images/craftsman/craftsman-quote-example-en.png" alt="Example" width={400} height={60} />
                  </Tooltip>
                }
                addonDe={
                  <Tooltip placement="bottom" target="quote-example-de">
                    <img src="/images/craftsman/craftsman-quote-example-de.png" alt="Example" width={400} height={60} />
                  </Tooltip>
                }
              />
              <Divider />
              <Row>
                <Col>
                  <h6>Craftsman's Description (DE)</h6>
                  <FieldArray
                    name="sections.de"
                    render={(arrayHelpers) => (
                      <>
                        {formik.values.sections.de &&
                          !!formik.values.sections.de.length &&
                          formik.values.sections.de.map((section, index) => (
                            <div key={`section-de-${index}`}>
                              <Row>
                                <Col>
                                  <p className="mt-1 mb-2 fw-normal">Section #{index + 1}</p>
                                </Col>
                                {/*@ts-ignore*/}
                                {formik.values.sections.de.length > 1 && (
                                  <Col md={2} className="d-flex justify-content-end">
                                    <Button type="button" color="danger" onClick={() => arrayHelpers.remove(index)}>
                                      <DeleteIcon className="icon danger" />
                                    </Button>
                                  </Col>
                                )}
                              </Row>
                              <SectionTranslationPairForm
                                formik={formik}
                                field={`sections.de.[${index}]`}
                                index={index}
                                touched={formik.touched?.sections?.de}
                                values={formik.values?.sections?.de?.[index]}
                                errors={formik.errors?.sections?.de?.[index]}
                                addonTitle={
                                  <Tooltip placement="bottom" target="craftsman-description-title-de">
                                    <img src="/images/craftsman/craftsman-description-title-example-de.png" alt="Example" width={400} height={90} />
                                  </Tooltip>
                                }
                                addonContent={
                                  <Tooltip placement="bottom" target="craftsman-content-title-de">
                                    <img src="/images/craftsman/craftsman-description-content-example-de.png" alt="Example" width={400} height={90} />
                                  </Tooltip>
                                }
                              />
                            </div>
                          ))}
                        <Row className="justify-content-center">
                          <Col md={6} xxl={3} className="d-flex justify-content-center">
                            <Button type="button" color="dark" className="w-100 btn-md" onClick={() => arrayHelpers.push(initialSectionItem)}>
                              Add a section
                            </Button>
                          </Col>
                        </Row>
                      </>
                    )}
                  />
                </Col>
                <Col>
                  <Row>
                    <h6>Craftsman's Description (EN)</h6>
                  </Row>
                  <FieldArray
                    name="sections.en"
                    render={(arrayHelpers) => (
                      <>
                        {formik.values.sections.en &&
                          !!formik.values.sections.en.length &&
                          formik.values.sections.en.map((section, index) => (
                            <div key={`section-en-${index}`}>
                              <Row>
                                <Col>
                                  <p className="mt-1 mb-2 fw-normal">Section #{index + 1}</p>
                                </Col>
                                {/*@ts-ignore*/}
                                {formik.values.sections.en.length > 1 && (
                                  <Col md={2} className="d-flex justify-content-end">
                                    <Button type="button" color="danger" onClick={() => arrayHelpers.remove(index)}>
                                      <DeleteIcon className="icon danger" />
                                    </Button>
                                  </Col>
                                )}
                              </Row>
                              <SectionTranslationPairForm
                                formik={formik}
                                index={index}
                                field={`sections.en[${index}]`}
                                touched={formik.touched?.sections?.en}
                                values={formik.values?.sections?.en?.[index]}
                                errors={formik.errors?.sections?.en?.[index]}
                                addonTitle={
                                  <Tooltip placement="bottom" target="craftsman-description-title-en">
                                    <img src="/images/craftsman/craftsman-description-title-example-en.png" alt="Example" width={400} height={90} />
                                  </Tooltip>
                                }
                                addonContent={
                                  <Tooltip placement="bottom" target="craftsman-content-title-en">
                                    <img src="/images/craftsman/craftsman-description-content-example-en.png" alt="Example" width={400} height={90} />
                                  </Tooltip>
                                }
                              />
                            </div>
                          ))}
                        <Row className="justify-content-center">
                          <Col md={6} xxl={3} className="d-flex justify-content-center">
                            <Button type="button" color="dark" className="w-100 btn-md" onClick={() => arrayHelpers.push(initialSectionItem)}>
                              Add a section
                            </Button>
                          </Col>
                        </Row>
                      </>
                    )}
                  />
                </Col>
              </Row>
              <Divider />
              <Row>
                <h6>SEO Data</h6>
              </Row>
              <SEOTranslationPairForm formik={formik} field="seo" />
              <Divider />
              <Upload
                formik={formik}
                accept={ACCEPTED_IMAGES}
                disabled={!fullName || !fullNameEntered}
                craftsmanFullName={fullName ? fullName : ''}
                profilePictures={{
                  maxSize: MAX_IMAGE_SIZE,
                  maxFiles: MAX_CRAFTSMAN_PROFILE_IMAGES,
                  profilePicture: formik.values.profilePicUrl,
                  loadImages: () => loadCraftsmanImages(fullName ? fullName : '', 'profile-image'),
                  onUpload: (image, setLoading) => onImageUpload(image, fullName ? fullName : '', setLoading, 'profile-image'),
                  onDelete: (filename, setDeleting) => onImageDelete(filename, setDeleting, 'profile-image'),
                  error: formik.touched.profilePicUrl && formik.errors.profilePicUrl ? formik.errors.profilePicUrl : '',
                  onProfilePictureSelect,
                }}
                otherPictures={{
                  maxSize: MAX_IMAGE_SIZE,
                  maxFiles: MAX_CRAFTSMAN_IMAGES,
                  setAdditionalPicUrls,
                  loadImages: async () => {
                    const images = await loadCraftsmanImages(fullName ? fullName : '', 'image');
                    formik.setFieldValue(
                      'additionalPicUrls',
                      images.map(({ url }: any) => url)
                    );
                    return images;
                  },
                  onUpload: (image, setLoading) => onImageUpload(image, fullName ? fullName : '', setLoading, 'image'),
                  error: formik.touched.additionalPicUrls && formik.errors.additionalPicUrls ? formik.errors.additionalPicUrls : '',
                  onDelete: (filename, setDeleting) => onImageDelete(filename, setDeleting, 'image'),
                }}
              />
              <Row className="mt-5">
                <Col xs={12} md={{ offset: 4, size: 4 }} xxl={{ offset: 5, size: 2 }}>
                  <Button type="button" className="w-100 btn-md" onClick={() => onSubmit(formik)}>
                    {type === 'new' ? 'Create' : 'Update'} Craftsman {loading && <Spinner size="sm" color="secondary" />}
                  </Button>
                </Col>
              </Row>
            </Form>
          );
        }}
      </Formik>
    </Col>
  );
};

export { CraftsmanDetails };
