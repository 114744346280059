import React, { useState } from 'react';
import { Button, Col, Collapse } from 'reactstrap';
import { ArrowDropDown } from '@styled-icons/material/ArrowDropDown';
import { renderRepairMeasure } from '../../../helpers';
import { Repair } from '../../../../../types';
import './RepairDetails.scss';

type ExpandDropdownProps = {
  repair: Repair;
};

const formatStatus = (status: string) => {
  const statusLowercase = status.toLowerCase();
  const firstLetter = statusLowercase[0].toUpperCase();
  return `${firstLetter}${statusLowercase.slice(1)}`;
};

const RepairDetails = ({ repair }: ExpandDropdownProps) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [isOpenImages, setIsOpenImages] = useState<boolean>(false);

  const toggle = () => {
    setIsOpen(!isOpen);
  };

  const toggleImages = () => {
    setIsOpenImages(!isOpenImages);
  };

  return (
    <Col key={repair._id} className="rounded repair" md={4}>
      <div className="p-2 d-flex align-content-center justify-content-between gap-2">
        <div className="d-flex align-items-center px-2 py-0 repair-status text-white rounded-pill">{formatStatus(repair.status)}</div>
        <div className="d-flex gap-3">
          <div className="d-flex flex-column justify-content-center">
            <span>{repair.product.name?.de}</span>
          </div>
        </div>
        <Button color="secondary" onClick={toggle}>
          <span>Details</span>
          <ArrowDropDown className="icon" />
        </Button>
      </div>
      <Collapse isOpen={isOpen}>
        <div className="d-flex py-3 px-4 justify-content-between align-items-center repair-details">
          <Col className="d-flex flex-column gap-2">
            <p>
              {repair.product.productCategory.title?.de} - {repair.product.productType.title?.de} - {repair.product.name?.de}
            </p>
            {repair.product.input?.type !== 'NO_INPUT' && <p>{renderRepairMeasure(repair)}</p>}
            <p>
              <span>Price: </span>
              <span>
                {!!repair.product.priceCent && `${repair.product.priceCent / 100} €`}
                {!repair.product.priceCent && ''}
              </span>
            </p>
            <p>
              <span>Comment: </span>
              <span>{repair.comment}</span>
            </p>
            <p>
              <span>Craftsman Type:</span>
              <span>{repair?.tailorType}</span>
            </p>
            <button className="expand-button" onClick={toggleImages}>
              Attached Files: ({repair?.images?.length ? repair?.images?.length : 0} / 10)
            </button>
            <Collapse isOpen={isOpenImages}>
              <div key={repair._id} className="image-container">
                {repair?.images?.map((image, index) => (
                  <div key={index}>
                    <img className="image-details" src={image} alt={`Repair ${repair._id} - Image ${index + 1}`} />
                  </div>
                ))}
              </div>
            </Collapse>
          </Col>
        </div>
      </Collapse>
    </Col>
  );
};

export default RepairDetails;
