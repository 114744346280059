import { Button, Col, Row } from 'reactstrap';
import { Delete as DeleteIcon } from '@styled-icons/fluentui-system-filled/Delete';
import './VideoPreview.scss';

const VideoPreview = ({ src, alt, onDelete, disabled, colWidth, onVideoClick }: VideoPreviewProps) => (
  <Col {...colWidth} className="mb-5 d-flex flex-column gap-3">
    <Button className="border-0" onClick={onVideoClick}>
      <video src={src} className="thumb-video" height="100%" controls/>
    </Button>
    {!disabled && (
      <Row>
        <Col>
          <Button color="danger" className="w-100" onClick={onDelete} disabled={disabled}>
            Delete video <DeleteIcon className="icon danger" />
          </Button>
        </Col>
      </Row>
    )}
  </Col>
);

type VideoPreviewProps = {
  colWidth: { xs?: number; sm?: number; md?: number; lg?: number; xl?: number; xxl?: number };
  src: string;
  alt: string;
  disabled: boolean;
  onDelete: () => void;
  onVideoClick?: (e: any) => void;
};

export { VideoPreview };