import React from 'react';
import { Link, NavLink } from 'react-router-dom';
import { useNavigate } from 'react-router';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { Button, Col, Row } from 'reactstrap';
import { LogoutCircleR } from '@styled-icons/remix-line';
import { Discount as DiscountIcon, User as UserIcon, Dashboard as DashboardIcon, Gift as GiftIcon } from '@styled-icons/boxicons-solid';
import { CameraFill as CameraIcon, CartFill as CartIcon } from '@styled-icons/bootstrap';
import { VehicleCarProfileRtl as DriverIcon } from '@styled-icons/fluentui-system-filled/VehicleCarProfileRtl';
import { LocalShipping as LocalShippingIcon } from '@styled-icons/material/LocalShipping';
import { Shirt as ShirtIcon } from '@styled-icons/ionicons-solid';
import { Users as UsersIcon } from '@styled-icons/fa-solid';
import { MenuFold as MenuFoldIcon, MenuUnfold as MenuUnfoldIcon } from '@styled-icons/remix-fill';
import { DirectionsBike as DirectionsBike } from '@styled-icons/material/DirectionsBike'
import { PackageIcon as PackageIcon } from '@styled-icons/octicons/PackageIcon';
import { ReactComponent as Logo } from './logo.svg';
import { ReactComponent as LogoSmall } from './logo-small.svg';
import { UserSlice } from '../../store';
import { AppState } from '../../store/redux/interfaces';
import { getDashboardPath } from '../../utils';
import { AdminAuthRole, Pages } from '../../enums';
import './Menu.scss';

const Menu = ({ collapsed, onCollapseClick }: MenuProps) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { user } = useSelector(
    (state: AppState) => ({
      user: state.user,
    }),
    shallowEqual
  );
  const { logoutReducer } = UserSlice.actions;
  const dashboardPath = getDashboardPath(user);
  const isAdmin = !!user && user?.role && user.role === AdminAuthRole.ADMIN;
  const isDriver = !!user && user?.role && user.role === AdminAuthRole.DRIVER;

  const onLogOut = async () => {
    dispatch(logoutReducer());
    navigate(Pages.LOGIN);
  };

  return (
    <>
      {!collapsed && (
        <div className="d-flex flex-column menu justify-content-between">
          <div className="menu-block">
            <Row className="flex-nowrap mb-5">
              <Col>
                <Link to={dashboardPath}>
                  <Logo title="Dashboard" width={150} height={30} />
                </Link>
              </Col>
              <Col>
                <Button onClick={onCollapseClick} className="btn-icon" color="secondary">
                  <div>
                    <MenuFoldIcon className="icon" width={20} height={20} title="Fold Menu" />
                  </div>
                </Button>
              </Col>
            </Row>
            <Row>
              <NavLink to={dashboardPath} className="menu-link">
                <div>
                  <DashboardIcon className="icon" width={20} height={20} title="Dashboard" />
                </div>
                <p className="menu-link-text">Dashboard</p>
              </NavLink>
            </Row>
            {!isDriver && (
              <Row>
                <NavLink to={Pages.ORDERS} className="menu-link">
                  <div>
                    <DirectionsBike className="icon" width={20} height={20} title="Orders" />
                  </div>
                  <p className="menu-link-text">Orders</p>
                </NavLink>
              </Row>
            )}
            {!isDriver && (
              <Row>
                <NavLink to={Pages.SHIPPING_ORDERS} className="menu-link">
                  <div>
                    <PackageIcon className="icon" width={20} height={20} title="Shipping Orders" />
                  </div>
                  <p className="menu-link-text">Shipping Orders</p>
                </NavLink>
              </Row>
            )}
            {!isDriver && (
              <Row>
                <NavLink to={Pages.ESTIMATIONS} className="menu-link">
                  <div>
                    <CameraIcon className="icon" width={20} height={20} title="Estimations" />
                  </div>
                  <p className="menu-link-text">Estimations</p>
                </NavLink>
              </Row>
            )}
            {!isDriver && (
              <Row>
                <NavLink to={Pages.CUSTOMERS} className="menu-link">
                  <div>
                    <UserIcon className="icon" width={20} height={20} title="Customers" />
                  </div>
                  <p className="menu-link-text">Customers</p>
                </NavLink>
              </Row>
            )}
            {isAdmin && (
              <>
                <Row>
                  <NavLink to={Pages.PRODUCTS} className="menu-link">
                    <div>
                      <ShirtIcon className="icon" width={20} height={20} title="Products" />
                    </div>
                    <p className="menu-link-text">Products</p>
                  </NavLink>
                </Row>
                <Row>
                  <NavLink to={Pages.CRAFTSMEN} className="menu-link">
                    <div>
                      <UsersIcon className="icon" width={20} height={20} title="Craftsmen" />
                    </div>
                    <p className="menu-link-text">Craftsmen</p>
                  </NavLink>
                </Row>
                <Row>
                  <NavLink to={Pages.DISCOUNTS} className="menu-link">
                    <div>
                      <DiscountIcon className="icon" width={20} height={20} title="Discounts" />
                    </div>
                    <p className="menu-link-text">Discounts</p>
                  </NavLink>
                </Row>
                <Row>
                  <NavLink to={Pages.VOUCHERS} className="menu-link">
                    <div>
                      <GiftIcon className="icon" width={20} height={20} title="Vouchers" />
                    </div>
                    <p className="menu-link-text">Gift Vouchers</p>
                  </NavLink>
                </Row>
                <Row>
                  <NavLink to={Pages.DRIVERS} className="menu-link">
                    <div>
                      <DriverIcon className="icon" width={20} height={20} title="Drivers" />
                    </div>
                    <p className="menu-link-text">Drivers</p>
                  </NavLink>
                </Row>
              </>
            )}
          </div>
          <div className="menu-block">
            <Button onClick={onLogOut} className="btn-md">
              <p>Log out</p>
              <div>
                <LogoutCircleR className="icon" width={20} height={20} title="Log out" />
              </div>
            </Button>
          </div>
        </div>
      )}
      {collapsed && (
        <div className="d-flex flex-column menu collapsed justify-content-between">
          <div className="menu-block">
            <Row className="align-items-center justify-content-center mb-4">
              <Button onClick={onCollapseClick} className="btn-icon" color="secondary" aria-label="Unfold Menu">
                <MenuUnfoldIcon className="icon" title="Unfold Menu" />
              </Button>
            </Row>
            <Row className="align-items-center justify-content-center">
              <Link to={dashboardPath} className="menu-link icon-link" aria-label="Dashboard">
                <LogoSmall title="Dashboard" width={20} height={20} />
              </Link>
            </Row>
            <Row className="align-items-center justify-content-center">
              <NavLink to={dashboardPath} className="menu-link icon-link" aria-label="Dashboard">
                <div>
                  <DashboardIcon className="icon" title="Dashboard" />
                </div>
              </NavLink>
            </Row>
            {!isDriver && (
              <Row className="align-items-center justify-content-center">
                <NavLink to={Pages.ORDERS} className="menu-link icon-link" aria-label="Orders">
                  <div>
                    <CartIcon className="icon" title="Orders" />
                  </div>
                </NavLink>
              </Row>
            )}
            {!isDriver && (
              <Row className="align-items-center justify-content-center">
                <NavLink to={Pages.SHIPPING_ORDERS} className="menu-link icon-link" aria-label="Shipping Orders">
                  <div>
                    <LocalShippingIcon className="icon" title="Shipping Orders" />
                  </div>
                </NavLink>
              </Row>
            )}
            {!isDriver && (
              <Row className="align-items-center justify-content-center">
                <NavLink to={Pages.ESTIMATIONS} className="menu-link icon-link" aria-label="Estimations">
                  <div>
                    <CameraIcon className="icon" title="Estimations" />
                  </div>
                </NavLink>
              </Row>
            )}
            {!isDriver && (
              <Row className="align-items-center justify-content-center">
                <NavLink to={Pages.CUSTOMERS} className="menu-link icon-link" aria-label="Customers">
                  <div>
                    <UserIcon className="icon" title="Customers" />
                  </div>
                </NavLink>
              </Row>
            )}
            {isAdmin && (
              <>
                <Row className="align-items-center justify-content-center">
                  <NavLink to={Pages.PRODUCTS} className="menu-link icon-link" aria-label="Products">
                    <div>
                      <ShirtIcon className="icon" title="Products" />
                    </div>
                  </NavLink>
                </Row>
                <Row className="align-items-center justify-content-center">
                  <NavLink to={Pages.CRAFTSMEN} className="menu-link icon-link" aria-label="Craftsmen">
                    <div>
                      <UsersIcon className="icon" title="Craftsmen" />
                    </div>
                  </NavLink>
                </Row>
                <Row className="align-items-center justify-content-center">
                  <NavLink to={Pages.DISCOUNTS} className="menu-link icon-link" aria-label="Discounts">
                    <div>
                      <DiscountIcon className="icon" title="Discounts" />
                    </div>
                  </NavLink>
                </Row>
                <Row className="align-items-center justify-content-center">
                  <NavLink to={Pages.DRIVERS} className="menu-link icon-link" aria-label="Drivers">
                    <div>
                      <DriverIcon className="icon" title="Drivers" />
                    </div>
                  </NavLink>
                </Row>
              </>
            )}
          </div>
          <div className="menu-block align-items-center justify-content-center">
            <Button onClick={onLogOut} className="btn-icon" aria-label="Log Out">
              <LogoutCircleR className="icon" title="Log Out" />
            </Button>
          </div>
        </div>
      )}
    </>
  );
};

type MenuProps = { collapsed: boolean; onCollapseClick: () => void };

export default Menu;
