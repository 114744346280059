import { UserState } from './type';
import { AdminAuthRole, SupportedLanguages } from '../../../../enums';

const initialState: UserState = {
  _id: '',
  email: '',
  role: AdminAuthRole.NONE,
  token: '',
  refreshToken: '',
  preferredLanguage: SupportedLanguages.de,
  cityList: [],
  active: false,
};

export { initialState };
