import { lazy, Suspense, useEffect, useState } from 'react';
import { Navigate, Route, Routes } from 'react-router';
import { BrowserRouter } from 'react-router-dom';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { Row } from 'reactstrap';
import Cookies from 'universal-cookie';
import Menu from './components/Menu';
import { GuardedRoute } from './components/Routes/GuardedRoute';
import { NotFoundRoute } from './components/Routes/NotFoundRoute';
import ErrorBoundary from './components/ErrorBoundary';
import Loader from './components/Loader';
import { AppState } from './store/redux/interfaces';
import { OrderSlice, UserSlice, ShippingOrderSlice } from './store';
import { Pages, AdminAuthRole, Tabs } from './enums';
import { CraftsmanDetails } from './pages/Craftsmen/CraftsmanDetails';
import { ProductDetails } from './pages/Products/ProductDetails';
import { CustomerDetails } from './pages/Customers/CustomerDetails';
import { OrderDetails } from './pages/Orders/OrderDetails/OrderDetails';
import { DiscountDetails } from './pages/Discounts/DiscountDetails';
import { DriverDetails } from './pages/Drivers/DriverDetails';
import { EstimationDetails } from './pages/Estimations/EstimationDetails';
import { ShippingOrderDetails } from './pages/ShippingOrders/ShippingOrderDetails';
import { OrderDetailsForm } from './pages/Orders/OrderDetailsForm';
import './App.scss';
import { ShippingDetails } from './pages/ShippingOrders/ShippingDetails/ShippingDetails';
import { VoucherOrderSlice } from './store/redux/reducer/voucherOrder';
import { VoucherMainDetails } from './pages/Vouchers/VoucherDetails/VoucherDetails';
import { VoucherDetails } from './pages/Vouchers/VouchersDetails';
import { FilteredPlaceListProvider } from './pages/AdminDashboard/DashboardContext';

// const DriverDashboard = lazy(() => import('./pages/DriverDashboard'));
// const CraftsmanDashboard = lazy(() => import('./pages/CraftsmanDashboard'));
// const ShopkeeperDashboard = lazy(() => import('./pages/ShopkeeperDashboard'));
const AdminDashboard = lazy(() => import('./pages/AdminDashboard'));
const Customers = lazy(() => import('./pages/Customers'));
const Estimations = lazy(() => import('./pages/Estimations'));
const Orders = lazy(() => import('./pages/Orders'));
const ShippingOrders = lazy(() => import('./pages/ShippingOrders'));
const Discounts = lazy(() => import('./pages/Discounts'));
const Vouchers = lazy(() => import('./pages/Vouchers'));
const Products = lazy(() => import('./pages/Products'));
const Craftsmen = lazy(() => import('./pages/Craftsmen'));
const Login = lazy(() => import('./pages/Login'));
const Drivers = lazy(() => import('./pages/Drivers'));

const cookies = new Cookies();

export default function App() {
  const dispatch = useDispatch();
  const { user, selectedOrderId, selectedShippingOrderId, selectedVoucherOrderId } = useSelector(
    (state: AppState) => ({
      user: state.user,
      selectedOrderId: state.order.selectedOrderId,
      selectedShippingOrderId: state.shippingOrder.selectedShippingOrderId,
      selectedVoucherOrderId: state.voucherOrder.selectedVoucherOrderId,
    }),
    shallowEqual
  );
  const { reloadUser } = UserSlice.actions;
  const { setOrderData } = OrderSlice.actions;
  const { setShippingOrderData } = ShippingOrderSlice.actions;
  const { setVoucherOrderData } = VoucherOrderSlice.actions;
  const [collapsed, setCollapsed] = useState(false);
  const userHasRole = !!user && user?.role;
  const isAdmin = userHasRole && user.role === AdminAuthRole.ADMIN;
  const isDriver = userHasRole && user.role === AdminAuthRole.DRIVER;
  const isCraftsman = userHasRole && user.role === AdminAuthRole.CRAFTSMEN;
  const isShopkeeper = userHasRole && user.role === AdminAuthRole.SHOPKEEPER;
  const isLoading = cookies.get('user') && !(isAdmin || isDriver || isShopkeeper || isCraftsman);

  useEffect(() => {
    window.scrollTo(0, 0);
    try {
      const user = JSON.parse(window.atob(cookies.get('user')));
      dispatch(reloadUser(user));
    } catch (err) {
      console.error("Couldn't parse cookie");
      cookies.remove('user');
    }
  }, [cookies]);

  const onCollapseClick = () => {
    setCollapsed(!collapsed);
  };

  const toggleOrderModal = () => {
    dispatch(setOrderData({ selectedOrderId: '' }));
  };
  const toggleShippingOrderModal = () => {
    dispatch(setShippingOrderData({ selectedShippingOrderId: '' }));
  };

  const toggleVoucherOrderModal = () => {
    dispatch(setVoucherOrderData({ selectedVoucherOrderId: '' }));
  };

  return (
    <BrowserRouter>
      <div className="root d-flex flex-nowrap">
        {user.role !== AdminAuthRole.NONE && <Menu onCollapseClick={onCollapseClick} collapsed={collapsed} />}
        <div className={`content w-100 ${collapsed ? 'collapsed' : ''}`}>
          {isLoading && (
            <Row className="h-100 align-items-center justify-content-center w-100">
              <Loader size="xl" />
            </Row>
          )}
          {!isLoading && (
            <Routes>
              {/*<Route*/}
              {/*  path={Pages.DRIVER}*/}
              {/*  element={*/}
              {/*    <GuardedRoute auth={isDriver}>*/}
              {/*      <DriverDashboard />*/}
              {/*    </GuardedRoute>*/}
              {/*  }*/}
              {/*/>*/}
              {/*<Route*/}
              {/*  path={Pages.CRAFTSMAN}*/}
              {/*  element={*/}
              {/*    <GuardedRoute auth={isCraftsman}>*/}
              {/*      <CraftsmanDashboard />*/}
              {/*    </GuardedRoute>*/}
              {/*  }*/}
              {/*/>*/}
              {/*<Route*/}
              {/*  path={Pages.SHOPKEEPER}*/}
              {/*  element={*/}
              {/*    <GuardedRoute auth={isShopkeeper}>*/}
              {/*      <ShopkeeperDashboard />*/}
              {/*    </GuardedRoute>*/}
              {/*  }*/}
              {/*/>*/}
              <Route
                path={Pages.CUSTOMERS}
                element={
                  <GuardedRoute auth={isCraftsman || isShopkeeper || isAdmin}>
                    <Customers />
                  </GuardedRoute>
                }
              />
              <Route
                path={Pages.CREATE_CUSTOMER}
                element={
                  <GuardedRoute auth={isShopkeeper || isAdmin || isCraftsman}>
                    <CustomerDetails type="new" />
                  </GuardedRoute>
                }
              />
              <Route
                path={Pages.CUSTOMER_DETAILS}
                element={
                  <GuardedRoute auth={isShopkeeper || isAdmin || isCraftsman}>
                    <CustomerDetails type="existing" />
                  </GuardedRoute>
                }
              />
              <Route
                path={Pages.ESTIMATIONS}
                element={
                  <GuardedRoute auth={isShopkeeper || isAdmin || isCraftsman}>
                    <Estimations />
                  </GuardedRoute>
                }
              />
              <Route
                path={Pages.CREATE_ESTIMATION}
                element={
                  <GuardedRoute auth={isShopkeeper || isAdmin || isCraftsman}>
                    <EstimationDetails type="new" />
                  </GuardedRoute>
                }
              />
              <Route
                path={Pages.ESTIMATION_DETAILS}
                element={
                  <GuardedRoute auth={isShopkeeper || isAdmin || isCraftsman}>
                    <EstimationDetails type="existing" />
                  </GuardedRoute>
                }
              />
              <Route
                path={Pages.ORDERS}
                element={
                  <GuardedRoute auth={isShopkeeper || isAdmin || isCraftsman}>
                    <Orders />
                  </GuardedRoute>
                }
              />
              <Route
                path={Pages.CREATE_ORDER}
                element={
                  <GuardedRoute auth={isShopkeeper || isAdmin || isCraftsman}>
                    <OrderDetailsForm type="new" />
                  </GuardedRoute>
                }
              />
              <Route
                path={Pages.ORDER_DETAILS}
                element={
                  <GuardedRoute auth={isShopkeeper || isAdmin || isCraftsman}>
                    <OrderDetailsForm type="existing" />
                  </GuardedRoute>
                }
              />
              <Route
                path={Pages.CREATE_CUSTOMER}
                element={
                  <GuardedRoute auth={isShopkeeper || isAdmin || isCraftsman}>
                    <CustomerDetails type="new" />
                  </GuardedRoute>
                }
              />
              <Route
                path={Pages.CUSTOMER_DETAILS}
                element={
                  <GuardedRoute auth={isShopkeeper || isAdmin || isCraftsman}>
                    <CustomerDetails type="existing" />
                  </GuardedRoute>
                }
              />
              <Route
                path={Pages.SHIPPING_ORDERS}
                element={
                  <GuardedRoute auth={isShopkeeper || isAdmin || isCraftsman}>
                    <ShippingOrders />
                  </GuardedRoute>
                }
              />
              <Route
                path={Pages.CREATE_SHIPPING_ORDER}
                element={
                  <GuardedRoute auth={isShopkeeper || isAdmin || isCraftsman}>
                    <ShippingOrderDetails type="new" />
                  </GuardedRoute>
                }
              />
              <Route
                path={Pages.SHIPPING_ORDER_DETAILS}
                element={
                  <GuardedRoute auth={isShopkeeper || isAdmin || isCraftsman}>
                    <ShippingOrderDetails type="existing" />
                  </GuardedRoute>
                }
              />
              <Route
                path={`${Pages.DASHBOARD}${Pages.TRANSPORT}`}
                element={
                  <GuardedRoute auth={isAdmin}>
                    <FilteredPlaceListProvider>
                      <AdminDashboard tab={Tabs.TRANSPORT} />
                    </FilteredPlaceListProvider>
                  </GuardedRoute>
                }
              />
              <Route
                path={`${Pages.DASHBOARD}${Pages.ASSIGNMENTS}`}
                element={
                  <GuardedRoute auth={isAdmin}>
                    <FilteredPlaceListProvider>
                      <AdminDashboard tab={Tabs.ASSIGNMENTS} />
                    </FilteredPlaceListProvider>
                  </GuardedRoute>
                }
              />
              <Route
                path={Pages.DASHBOARD}
                element={
                  <GuardedRoute auth={isAdmin}>
                    <Navigate to={`${Pages.DASHBOARD}${Pages.ASSIGNMENTS}`} />
                  </GuardedRoute>
                }
              />
              <Route
                path={Pages.DISCOUNTS}
                element={
                  <GuardedRoute auth={isAdmin}>
                    <Discounts />
                  </GuardedRoute>
                }
              />
              <Route
                path={Pages.CREATE_DISCOUNT}
                element={
                  <GuardedRoute auth={isAdmin}>
                    <DiscountDetails type="new" />
                  </GuardedRoute>
                }
              />
              <Route
                path={Pages.DISCOUNT_DETAILS}
                element={
                  <GuardedRoute auth={isAdmin}>
                    <DiscountDetails type="existing" />
                  </GuardedRoute>
                }
              />
              <Route
                path={Pages.VOUCHERS}
                element={
                  <GuardedRoute auth={isAdmin}>
                    <Vouchers />
                  </GuardedRoute>
                }
              />
              <Route
                path={Pages.CREATE_VOUCHERS}
                element={
                  <GuardedRoute auth={isAdmin}>
                    <VoucherDetails type="new" />
                  </GuardedRoute>
                }
              />
              <Route
                path={Pages.VOUCHERS_DETAILS}
                element={
                  <GuardedRoute auth={isAdmin}>
                    <VoucherDetails type="existing" />
                  </GuardedRoute>
                }
              />
              <Route
                path={Pages.PRODUCTS}
                element={
                  <GuardedRoute auth={isAdmin}>
                    <Products />
                  </GuardedRoute>
                }
              />
              <Route
                path={Pages.CREATE_PRODUCT}
                element={
                  <GuardedRoute auth={isAdmin}>
                    <ProductDetails type="new" />
                  </GuardedRoute>
                }
              />
              <Route
                path={Pages.PRODUCT_DETAILS}
                element={
                  <GuardedRoute auth={isAdmin}>
                    <ProductDetails type="existing" />
                  </GuardedRoute>
                }
              />
              <Route
                path={Pages.CRAFTSMEN}
                element={
                  <GuardedRoute auth={isAdmin}>
                    <Craftsmen />
                  </GuardedRoute>
                }
              />
              <Route
                path={Pages.CREATE_CRAFTSMAN}
                element={
                  <GuardedRoute auth={isAdmin}>
                    <CraftsmanDetails type="new" />
                  </GuardedRoute>
                }
              />
              <Route
                path={Pages.CRAFTSMAN_DETAILS}
                element={
                  <GuardedRoute auth={isAdmin}>
                    <CraftsmanDetails type="existing" />
                  </GuardedRoute>
                }
              />
              <Route
                path={Pages.LOGIN}
                element={
                  <ErrorBoundary>
                    <Suspense
                      fallback={
                        <Row className="align-items-center justify-content-center w-100">
                          <Loader size="xl" />
                        </Row>
                      }
                    >
                      <Login />
                    </Suspense>
                  </ErrorBoundary>
                }
              />
              <Route
                path={Pages.DRIVERS}
                element={
                  <GuardedRoute auth={isAdmin}>
                    <Drivers />
                  </GuardedRoute>
                }
              />
              <Route
                path={Pages.CREATE_DRIVER}
                element={
                  <GuardedRoute auth={isAdmin}>
                    <DriverDetails type="new" />
                  </GuardedRoute>
                }
              />
              <Route
                path={Pages.DRIVER_DETAILS}
                element={
                  <GuardedRoute auth={isAdmin}>
                    <DriverDetails type="existing" />
                  </GuardedRoute>
                }
              />
              <Route path="*" element={<NotFoundRoute auth={isAdmin || isShopkeeper || isCraftsman || isDriver} />} />
            </Routes>
          )}
          {selectedOrderId && <OrderDetails _id={selectedOrderId} toggle={toggleOrderModal} />}
          {selectedShippingOrderId && <ShippingDetails _id={selectedShippingOrderId} toggle={toggleShippingOrderModal} />}
          {selectedVoucherOrderId && <VoucherMainDetails _id={selectedVoucherOrderId} toggle={toggleVoucherOrderModal} />}
        </div>
      </div>
    </BrowserRouter>
  );
}
