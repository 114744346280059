import * as yup from 'yup';

type TranslationPair = {
  _id: string;
  de: any;
  en: any;
};

type EmailTranslationPair = {
  _id: string;
  de: Text;
  en: Text;
};

type Text = {
  header: string;
  content: string;
};

const SimpleTranslationSchema = yup.object({
  en: yup.string().min(1, "This field shouldn't be empty").required('This field is required.'),
  de: yup.string().min(1, "This field shouldn't be empty").required('This field is required.'),
});

const SimpleTranslationSchemaWorksPics = yup.object({
  en: yup.string().min(1, "This field shouldn't be empty"),
  de: yup.string().min(1, "This field shouldn't be empty"),
});

const initialSimpleTranslation = { en: '', de: '' };

const TranslationSchema = yup.object({
  header: yup.string().min(1, "This field shouldn't be empty").required('This field is required.'),
  content: yup.string().min(1, "This field shouldn't be empty").required('This field is required.'),
});

const initialTranslation = { en: { header: '', content: '' }, de: { header: '', content: '' } };

const SectionItemSchema = yup.object({
  title: yup.string().min(1, "This field shouldn't be empty").required('This field is required.'),
  texts: yup.array().of(yup.string().min(1, "This field shouldn't be empty").required('This field is required.')),
});

type SectionsItemSchemaType = yup.InferType<typeof SectionItemSchema>;

const initialSectionItem: SectionsItemSchemaType = { title: '', texts: [''] };

const SectionsSchema = yup.object({
  de: yup.array().of(SectionItemSchema),
  en: yup.array().of(SectionItemSchema),
});

type SectionsSchemaType = yup.InferType<typeof SectionsSchema>;
const initialSection: SectionsSchemaType = { en: [{ title: '', texts: [''] }], de: [{ title: '', texts: [''] }] };

const SEOItemSchema = yup.object({
  title: yup.string().min(1, "This field shouldn't be empty").required('This field is required.'),
  description: yup.string().min(1, "This field shouldn't be empty").required('This field is required.'),
});
const SEOSchema = yup.object({
  url: yup.string().required('This field is required.'),
  de: SEOItemSchema,
  en: SEOItemSchema,
});
type SEOSchemaType = yup.InferType<typeof SEOSchema>;

const initialSEO = { url: '', en: { title: '', description: '' }, de: { title: '', description: '' } };

export type { TranslationPair, EmailTranslationPair, SectionsItemSchemaType, SectionsSchemaType, SEOSchemaType };
export {
  initialTranslation,
  TranslationSchema,
  SectionsSchema,
  SEOSchema,
  SEOItemSchema,
  initialSection,
  initialSectionItem,
  SimpleTranslationSchema,
  initialSimpleTranslation,
  initialSEO,
  SimpleTranslationSchemaWorksPics
};
