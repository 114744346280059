import { Contact, ContactSchema, initialContactData } from './contact';
import * as yup from 'yup';

enum LocationType {
  /**
   * indicates that the returned result is a precise geocode for which we have location information
   * accurate down to street address precision
   */
  ROOFTOP = 'ROOFTOP',
  /**
   * indicates that the returned result reflects an approximation (usually on a road) interpolated between two precise points
   * (such as intersections). Interpolated results are generally returned when rooftop geocodes are unavailable for a street address.
   */
  RANGE_INTERPOLATED = 'RANGE_INTERPOLATED',
  /**
   * indicates that the returned result is the geometric center of a result such as a polyline
   * (for example, a street) or polygon (region).
   */
  GEOMETRIC_CENTER = 'GEOMETRIC_CENTER',
  /** indicates that the returned result is approximate. */
  APPROXIMATE = 'APPROXIMATE',
}

type LatLngLiteral = {
  lat: number;
  lng: number;
};

type LatLngBounds = {
  northeast: LatLngLiteral;
  southwest: LatLngLiteral;
};

type Place = {
  formatted_address: string;
  placeName: string;
  place_id: string;
  geometry: AddressGeometry;
};

type AddressGeometry = {
  bounds: LatLngBounds;
  location: LatLngLiteral;
  location_type: LocationType;
  viewport: LatLngBounds;
};

type Address = {
  _id: string;
  id: string;
  street: string;
  city: string;
  country: string;
  zipCode: string;
  workingHours?: string;
  state?: string;
  place?: Place;
  contact?: Contact;
};

const CreateAddressSchema = yup
  .object({
    _id: yup.string().optional().nullable(),
    id: yup.string().optional().nullable(),
    street: yup.string().required('This field is required.'),
    city: yup.string().required('This field is required.'),
    country: yup.string().required('This field is required.'),
    zipCode: yup.string().required('This field is required.'),
    workingHours: yup.string().optional(),
    // state: yup.string().required('This field is required.'),
    place: yup.mixed().nullable(),
    contact: ContactSchema,
  })
  .defined();
type CreateAddress = yup.InferType<typeof CreateAddressSchema>;
const initialAddress: CreateAddress = {
  _id: '',
  id: '',
  street: '',
  city: '',
  country: '',
  zipCode: '',
  workingHours: '',
  // state: '',
  place: null,
  contact: initialContactData,
};
const initialCustomerAddress = {
  street: '',
  city: '',
  country: '',
  zipCode: '',
};

export type { CreateAddress, LatLngLiteral, LatLngBounds, Place, AddressGeometry, Address };
export { CreateAddressSchema, initialAddress, initialCustomerAddress };
