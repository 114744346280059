import { combineReducers } from 'redux';
import { reducer as ToastrReducer } from 'react-redux-toastr';
import { UserReducer, OrderReducer, CraftsmanReducer, ShippingOrderReducer } from './reducer';
import { VoucherOrderReducer } from './reducer/voucherOrder';

export const rootReducer = combineReducers({
  user: UserReducer,
  order: OrderReducer,
  toastr: ToastrReducer,
  craftsman: CraftsmanReducer,
  shippingOrder: ShippingOrderReducer,
  voucherOrder: VoucherOrderReducer
});
