import { ReactNode, useCallback } from 'react';
import ReactDatePicker, { ReactDatePickerCustomHeaderProps } from 'react-datepicker';
import { Button, Col, FormFeedback, InputGroup, Label } from 'reactstrap';
import { de } from 'date-fns/locale';
import { Times as TimesIcon } from '@styled-icons/typicons/Times';
import { DatePickerHeader } from './DatePickerHeader';
import '../DatePicker.scss';

const DatePicker = ({
  date,
  minDate,
  maxDate,
  onChange,
  notification,
  dateFilter,
  placeholderText,
  highlightDates,
  error,
  label,
  disabled,
}: DateRangeProps) => {
  const handleClear = () => {
    onChange(null);
  };

  const renderHeader = useCallback((headerProps: ReactDatePickerCustomHeaderProps) => <DatePickerHeader {...headerProps} />, []);

  return (
    <Col className="datepicker">
      <Label for={label}>{label}</Label>
      <InputGroup className="flex-nowrap">
        <ReactDatePicker
          id={label}
          className={`${error ? 'is-invalid' : ''} form-control`}
          wrapperClassName={`input-group ${date ? 'has-addon' : ''}`}
          placeholderText={placeholderText}
          dateFormat="dd-MM-yyyy"
          locale={de}
          renderCustomHeader={renderHeader}
          filterDate={dateFilter}
          selected={date}
          minDate={minDate}
          maxDate={maxDate}
          onChange={(date) => onChange(date)}
          highlightDates={highlightDates}
          disabledKeyboardNavigation
          disabled={disabled}
        >
          <div className="datepicker-notification text-center">{notification}</div>
        </ReactDatePicker>
        {date && (
          <Button aria-label="Clear date" onClick={handleClear} className="btn-addon" disabled={disabled}>
            <TimesIcon className="icon" />
          </Button>
        )}
      </InputGroup>
      {!!error && (
        <FormFeedback className="d-block" invalid={error}>
          {error}
        </FormFeedback>
      )}
    </Col>
  );
};

type DateRangeProps = {
  disabled?: boolean;
  error: string;
  label: string;
  highlightDates?: Date[];
  placeholderText: string;
  notification?: ReactNode;
  dateFilter?: (date: Date) => boolean;
  date: Date | null;
  minDate?: Date | null;
  maxDate?: Date | null;
  onChange: (date: Date | null) => void;
};

export { DatePicker };
