import { Col, Row } from 'reactstrap';
import { CustomInput } from '../../../components/Input/CustomInput';
import { ReactNode } from 'react';

const SimpleTranslationPairForm = ({ formik, field, label, addonEn, addonDe }: CreateSectionTranslationPairFormProps) => (
  <Row>
    <Col>
      <CustomInput
        label={`${label ? label + ' ' : ''}DE`}
        inputProps={{
          id: 'de',
          name: `${[field]}.de`,
          type: 'text',
          value: formik?.values[field]?.de,
          onChange: formik.handleChange,
        }}
        addon={addonDe}
        error={formik?.touched[field]?.de && formik?.errors[field]?.de ? formik?.errors[field]?.de : ''}
      />
    </Col>
    <Col>
      <CustomInput
        label={`${label ? label + ' ' : ''}EN`}
        inputProps={{
          id: 'en',
          name: `${[field]}.en`,
          type: 'text',
          value: formik?.values[field]?.en,
          onChange: formik.handleChange,
        }}
        addon={addonEn}
        error={formik?.touched[field]?.en && formik?.errors[field]?.en ? formik?.errors[field]?.en : ''}
      />
    </Col>
  </Row>
);

type CreateSectionTranslationPairFormProps = {
  formik: any;
  field: string;
  label?: string;
  addonEn?: ReactNode;
  addonDe?: ReactNode;
};

export { SimpleTranslationPairForm };
