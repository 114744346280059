import { Button, Col, Row } from 'reactstrap';
import { Delete as DeleteIcon } from '@styled-icons/fluentui-system-filled/Delete';
import './ImagePreview.scss';

const ImagePreview = ({ src, alt, onDelete, disabled, colWidth, onImageClick }: ImagePreviewProps) => (
  <Col {...colWidth} className="mb-5 d-flex flex-column gap-3">
    <Button className="border-0" onClick={onImageClick}>
      <img src={src} alt={alt} className="thumb-img" height="100%" />
    </Button>
    {!disabled && (
      <Row>
        <Col>
          <Button color="danger" className="w-100" onClick={onDelete} disabled={disabled}>
            Delete photo <DeleteIcon className="icon danger" />
          </Button>
        </Col>
      </Row>
    )}
  </Col>
);

type ImagePreviewProps = {
  colWidth: { xs?: number; sm?: number; md?: number; lg?: number; xl?: number; xxl?: number };
  src: string;
  alt: string;
  disabled: boolean;
  onDelete: () => void;
  onImageClick?: (e: any) => void;
};

export { ImagePreview };
