enum SupportedCities {
  DUSSELDORF = 'Dusseldorf',
  COLOGNE = 'Cologne',
  OTHER = 'Other'
}

export enum OtherCities {
  ESSEN = 'Essen',
  DORTMUND = 'Dortmund',
  MUNICH = 'Munich',
  BERLIN = 'Berlin',
  HAMBURG = 'Hamburg',
  FRANKFURT = 'Frankfurt',
  STUTTGART = 'Stuttgart',
  WOLFSBURG = 'Wolfsburg',
  HANNOVER = 'Hannover',
  BONN = 'Bonn',
  DUISBURG = 'Duisburg',
  BOCHUM = 'Bochum',
  MONCHENGLADBACH = 'Monchengladbach',
}

export type AllCities = SupportedCities | OtherCities;

export type SupportedCitiesAndOther = AllCities;

type SupportedCitiesAndAll = AllCities | 'All';

export { SupportedCities };
export type { SupportedCitiesAndAll };
