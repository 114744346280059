import { ChangeEvent, useState } from 'react';
import { Button, Card, Col, Collapse, FormFeedback, Input, Label, List, ListGroupItem } from 'reactstrap';
import { SupportedCities } from '../../enums';
import './CityDropdown.scss';

const CityDropdown = ({ selectedCities, onCitySelect, touched, errors, disabled = false }: CityDropdownProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const [cities, setCities] = useState<SupportedCities[]>(Object.values(SupportedCities));

  const toggle = () => setIsOpen(!isOpen);

  const searchCities = async (event?: ChangeEvent<HTMLInputElement>) => {
    let value = '';
    const supportedCities = Object.values(SupportedCities);

    if (event) {
      value = event.target.value;
    }

    setCities(supportedCities.filter((city) => city.includes(value)));
  };

  const handleCityClick = (city: SupportedCities) => {
    onCitySelect(city);
    toggle();
  };

  return (
    <div className="d-flex flex-column">
      <Label>City List</Label>
      <div>
        <Button
          color="secondary"
          onClick={toggle}
          className={`form-control d-flex dropdown-toggle justify-content-between align-items-center w-100 ${errors ? 'border-danger is-invalid' : ''}`}
          disabled={disabled}
        >
          <span className="text-start w-75 city-details">{selectedCities.join(', ') || 'Select City from List'}</span>
        </Button>
      </div>
      <div>
        <Collapse isOpen={isOpen}>
          <Col className="d-flex flex-column gap-2">
            <Input type="search" name="search" onChange={searchCities} placeholder="Select City from List" className="mt-2" />
            <Card>
              <Col>
                <List className="py-2 px-0 m-0">
                  {cities.map((city) => (
                    <ListGroupItem
                      key={city}
                      onClick={() => handleCityClick(city)}
                      className="city-search-item city-details"
                      role="button"
                    >
                      {city}
                    </ListGroupItem>
                  ))}
                </List>
              </Col>
            </Card>
          </Col>
        </Collapse>
        {touched && errors && (
          <FormFeedback className="d-block" invalid>
            {errors}
          </FormFeedback>
        )}
      </div>
    </div>
  );
};

type CityDropdownProps = {
  selectedCities: SupportedCities[];
  onCitySelect: (city: SupportedCities) => void;
  disabled?: boolean;
  touched?: boolean;
  errors?: string;
};

export { CityDropdown };
