import { useEffect, useState } from 'react';
import { Button, Col, Dropdown, DropdownItem, DropdownMenu, DropdownToggle, Form, FormFeedback, Label, List, Row, Spinner } from 'reactstrap';
import { toastr } from 'react-redux-toastr';
import { FieldArray, Formik, FormikTouched, setNestedObjectValues } from 'formik';
import { useNavigate, useParams } from 'react-router';
import * as yup from 'yup';
import { apiRequest, DAYS_GAP_BETWEEN_PICK_UP_ADN_DELIVERY, handleError, isDriverWorkingDay, TOAST_IMPORTANT_TIME_OUT_MS } from '../../utils';
import {
  CraftsmanType,
  DiscountType,
  getTimeframeRange,
  OrderStatus,
  OrderTimeframe,
  Pages,
  PaymentMethod,
  PaymentStatus,
  RepairStatus,
  SupportedCities,
} from '../../enums';
import { BoxPriceCent, CourierPriceCent, Customer, Discount, Product, Repair, ShippingExemptionThreshold } from '../../types';
import DatePicker from '../../components/Date/DatePicker';
import moment from 'moment';
import Divider from '../../components/Divider';
import { CustomerSearchForm } from './components/CustomerSearchForm/CustomerSearchForm';
import { CreateAddressForm } from '../../components/Forms/CreateAddressForm';
import { CustomInput } from '../../components/Input/CustomInput';
import ProductForm from './components/ProductForm';
import { RepairDetailsForm } from './components/RepairDetailsForm';
import { PaymentParamsForm } from './components/PaymentParamsForm';
import BackDetailsLink from '../../components/BackDetailsLink/BackDetailsLink';

type OrderDetailsProps = {
  type?: 'new' | 'existing';
};

const addressSchema = yup
  .object({
    _id: yup.string().optional().nullable(),
    id: yup.string().optional().nullable(),
    street: yup.string().required('This field is required.'),
    city: yup.string().required('This field is required.'),
    country: yup.string().required('This field is required.'),
    zipCode: yup.string().required('This field is required.'),
    // state: yup.string().optional(),
    place: yup.mixed().optional().nullable(),
  })
  .defined();

const timeSchema = yup
  .object({
    _id: yup.string().optional().nullable(),
    date: yup.string().nullable().required('This field is required.'),
    timeframe: yup
      .mixed()
      .oneOf([...Object.values(OrderTimeframe), null])
      .required('This field is required.'),
    day: yup.number().required('This field is required.'),
    month: yup.number().required('This field is required.'),
    year: yup.number().required('This field is required.'),
    unix: yup.number().nullable(),
  })
  .defined();

const OrderSchema = yup.object({
  _id: yup.string().optional().nullable(),
  number: yup.string().optional().nullable(),
  customerId: yup.string().required('This field is required.'),
  repairList: yup
    .array()
    .of(
      yup.object({
        _id: yup.string().optional(),
        inputValue: yup.number().when(['product'], {
          is: (product: Product) => product?.input?.type !== 'NO_INPUT',
          then: yup.number().required('This field is required.'),
        }),
        status: yup.mixed<RepairStatus>().oneOf(Object.values(RepairStatus)).required('This field is required.'),
        comment: yup.string().optional(),
        product: yup.mixed().defined(),
        tailorType: yup.mixed<CraftsmanType>().oneOf(Object.values(CraftsmanType)).nullable(),
        images: yup.array().of(yup.string().required('Each image must be a string URL or identifier')).optional(),
      })
    )
    .test({
      message: 'At least one repair must be added.',
      test: (arr) => Boolean(arr && arr.length && arr.length >= 1),
    })
    .required('This field is required.'),
  createdAt: yup.date().nullable().default(new Date()).required('This field is required.'),
  pickupAddress: addressSchema,
  deliveryAddress: addressSchema,
  status: yup.mixed<OrderStatus>().oneOf(Object.values(OrderStatus)).required('This field is required.'),
  pickupTime: timeSchema,
  deliveryTime: timeSchema,
  city: yup.mixed<SupportedCities>().oneOf(Object.values(SupportedCities)).required('This field is required.'),
  boxCount: yup.number().min(0, 'This field is required. Enter 0 if no boxes needed.').required('This field is required. Enter 0 if no boxes needed.'),
  boxPriceCent: yup.number().min(0, 'Non-negative value must be entered.').required('This field is required.'),
  courierPriceCent: yup.number().min(0, 'Non-negative value must be entered.').required('This field is required.'),
  shippingExemptionThreshold: yup.number().min(0, 'Non-negative value must be entered.').required('This field is required.'),
  discount: yup
    .object({
      _id: yup.string(),
      code: yup.string(),
      number: yup.string(),
      percent: yup.number(),
      type: yup.mixed<DiscountType>().oneOf(Object.values(DiscountType)),
      value: yup.number(),
    })
    .nullable()
    .defined(),
  paymentParams: yup
    .object()
    .shape(
      {
        referenceNumber: yup.string().when(['method', 'status'], {
          is: (method: PaymentMethod, status: PaymentStatus) => method || status,
          then: yup.string().required('This field is required.'),
        }),
        method: yup
          .mixed<PaymentMethod>()
          .oneOf(Object.values(PaymentMethod))
          .when(['referenceNumber', 'status'], {
            is: (referenceNumber: string, status: PaymentStatus) => referenceNumber || status,
            then: yup.mixed<PaymentMethod>().oneOf(Object.values(PaymentMethod)).required('This field is required.'),
          }),
        status: yup
          .mixed<PaymentStatus>()
          .oneOf(Object.values(PaymentStatus))
          .when(['method', 'referenceNumber'], {
            is: (method: PaymentMethod, referenceNumber: string) => method || referenceNumber,
            then: yup.mixed<PaymentStatus>().oneOf(Object.values(PaymentStatus)).required('This field is required.'),
          }),
      },
      [
        ['method', 'status'],
        ['referenceNumber', 'status'],
        ['method', 'referenceNumber'],
      ]
    )
    .nullable(),
});
type OrderSchemaType = yup.InferType<typeof OrderSchema>;

const OrderDetailsForm = ({ type = 'new' }: OrderDetailsProps) => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [values, setValues] = useState<OrderSchemaType>({
    _id: '',
    number: '',
    customerId: '',
    repairList: [],
    createdAt: new Date(),
    pickupAddress: {
      _id: '',
      id: '',
      street: '',
      city: '',
      country: '',
      zipCode: '',
      // state: '',
      place: null,
    },
    deliveryAddress: {
      _id: '',
      id: '',
      street: '',
      city: '',
      country: '',
      zipCode: '',
      // state: '',
      place: null,
    },
    status: OrderStatus.OPEN,
    pickupTime: {
      _id: '',
      date: '',
      timeframe: null,
      day: 0,
      month: 0,
      year: 0,
      unix: 0,
    },
    deliveryTime: {
      _id: '',
      date: '',
      timeframe: null,
      day: 0,
      month: 0,
      year: 0,
      unix: 0,
    },
    city: SupportedCities.DUSSELDORF,
    boxCount: 0,
    boxPriceCent: BoxPriceCent,
    courierPriceCent: CourierPriceCent,
    shippingExemptionThreshold: ShippingExemptionThreshold,
    discount: null,
    paymentParams: null,
  });
  const [loading, setLoading] = useState(false);
  const [discounts, setDiscounts] = useState<Discount[]>([]);
  const [cityOpen, setCityOpen] = useState(false);
  const [orderStatusOpen, setOrderStatusOpen] = useState(false);
  const [pickUpTimeframeOpen, setPickUpTimeframeOpen] = useState(false);
  const [deliveryTimeframeOpen, setDeliveryTimeframeOpen] = useState(false);
  const [discountsOpen, setDiscountsOpen] = useState(false);
  const [customer, setCustomer] = useState({ name: '', lastName: '' });
  const [creationTime] = useState(Date.now());

  useEffect(() => {
    if (type === 'new') {
      return;
    }
    onLoad();
  }, [id]);

  useEffect(() => {
    (async () => {
      const { data } = await apiRequest('/discount');
      const { items: discounts } = data;
      setDiscounts(discounts as Discount[]);
    })();
  }, []);

  const toggleCity = () => setCityOpen(!cityOpen);
  const toggleOrderStatus = () => setOrderStatusOpen(!orderStatusOpen);
  const togglePickUpTimeframe = () => setPickUpTimeframeOpen(!pickUpTimeframeOpen);
  const toggleDeliveryTimeframe = () => setDeliveryTimeframeOpen(!deliveryTimeframeOpen);
  const toggleDiscounts = () => setDiscountsOpen(!discountsOpen);
  const enableLoading = () => {
    setLoading(true);
  };
  const disableLoading = () => {
    setLoading(false);
  };

  const onLoad = async () => {
    const abortController = new AbortController();
    try {
      enableLoading();
      const { data } = await apiRequest(`/order/by-id?orderId=${id}`, { signal: abortController.signal });
      const { message } = data;

      if (message) {
        if (Array.isArray(message)) {
          message.forEach((msg) => toastr.error('Error', msg, { timeOut: TOAST_IMPORTANT_TIME_OUT_MS }));
        } else {
          toastr.error('Error', message, { timeOut: TOAST_IMPORTANT_TIME_OUT_MS });
        }
        abortController.abort();
        return disableLoading();
      }
      setValues({ ...data, createdAt: new Date(data.createdAt) });
      disableLoading();
    } catch (err) {
      handleError('FETCH_ORDER', err);
      if (err instanceof DOMException && err.name === 'AbortError') {
        toastr.error('Error', 'Your browser aborted the request', { timeOut: TOAST_IMPORTANT_TIME_OUT_MS });
      } else {
        toastr.error('Error', 'Failed to fetch customer by ID', { timeOut: TOAST_IMPORTANT_TIME_OUT_MS });
      }
      abortController.abort();
    }
  };

  const onCreate = async (values: OrderSchemaType, action: any) => {
    const abortController = new AbortController();
    try {
      enableLoading();
      values.repairList = values.repairList.map((val) => ({
        ...val,
        inputValue: val.inputValue ?? 1,
      }));
      const { data } = await apiRequest('/order', { method: 'POST', data: JSON.stringify(values) });
      const { message } = data;

      if (message) {
        if (Array.isArray(message)) {
          message.forEach((msg) => toastr.error('Error', msg, { timeOut: TOAST_IMPORTANT_TIME_OUT_MS }));
        } else {
          toastr.error('Error', message, { timeOut: TOAST_IMPORTANT_TIME_OUT_MS });
        }
        action.setSubmitting(false);
        abortController.abort();
        return disableLoading();
      }
      disableLoading();
      toastr.success('Success', 'Order created');
      navigate(Pages.ORDERS);
    } catch (err) {
      handleError('CREATE_ORDER', err);
      if (err instanceof DOMException && err.name === 'AbortError') {
        toastr.error('Error', 'Your browser aborted the request', { timeOut: TOAST_IMPORTANT_TIME_OUT_MS });
      } else {
        toastr.error('Error', 'Failed to create order', { timeOut: TOAST_IMPORTANT_TIME_OUT_MS });
      }
      abortController.abort();
    }
  };

  const onUpdate = async (values: OrderSchemaType, action: any) => {
    const abortController = new AbortController();
    try {
      enableLoading();
      if (values.repairList) {
        values.repairList = values.repairList.map((val) => ({
          ...val,
          inputValue: val.inputValue ?? 1,
        }));
      }
      const { data } = await apiRequest(`/order/${values._id}`, { method: 'PUT', data: JSON.stringify(values) });
      const { message } = data;

      if (message) {
        if (Array.isArray(message)) {
          message.forEach((msg) => toastr.error('Error', msg, { timeOut: TOAST_IMPORTANT_TIME_OUT_MS }));
        } else {
          toastr.error('Error', message, { timeOut: TOAST_IMPORTANT_TIME_OUT_MS });
        }
        action.setSubmitting(false);
        return disableLoading();
      }
      disableLoading();
      toastr.success('Success', 'Order updated');
      navigate(`/orders/${id}`);
    } catch (err) {
      handleError('UPDATE_ORDER', err);
      if (err instanceof DOMException && err.name === 'AbortError') {
        toastr.error('Error', 'Your browser aborted the request', { timeOut: TOAST_IMPORTANT_TIME_OUT_MS });
      } else {
        toastr.error('Error', 'Failed to update order', { timeOut: TOAST_IMPORTANT_TIME_OUT_MS });
      }
      abortController.abort();
    }
  };

  const onSubmit = async (formik: any) => {
    const formikErrors = await formik.validateForm();
    if (Object.keys(formikErrors).length) {
      formik.setTouched(setNestedObjectValues<FormikTouched<OrderSchemaType>>(formikErrors, true));
      return null;
    }

    if (type === 'new') {
      await onCreate(formik.values, {
        setSubmitting: formik.setSubmitting,
      });
    } else if (type === 'existing') {
      await onUpdate(formik.values, {
        setSubmitting: formik.setSubmitting,
      });
    }
  };

  return (
    <Col className="mh-100 overflow-auto px-3">
      <Row>
        <Col>
          <h3 className="text-secondary mb-3">Order Details</h3>
        </Col>
        <Col className="d-flex justify-content-end">
          <BackDetailsLink path={Pages.ORDERS} />
        </Col>
      </Row>
      <Formik enableReinitialize initialValues={values} validationSchema={OrderSchema} onSubmit={onSubmit}>
        {(formik) => {
          const selectedRepairs: Repair[] = formik.values?.repairList;

          const onRepairSelect = (product: Product) => {
            if (selectedRepairs && selectedRepairs.length && selectedRepairs.some(({ product: repair }) => repair._id === product._id.toLowerCase())) {
              formik.setFieldValue(
                `repairList`,
                formik.values.repairList.filter(({ product: repair }) => repair._id !== product._id.toLowerCase())
              );
            } else {
              formik.setFieldValue(`repairList`, [...formik.values.repairList, { product }]);
            }
          };

          const removeRepair = (_id: Product['_id']) => {
            if (selectedRepairs && selectedRepairs.length && selectedRepairs.some(({ product }) => product._id === _id)) {
              formik.setFieldValue(
                `repairList`,
                formik.values.repairList.filter(({ product }) => product._id !== _id)
              );
            }
          };

          const onCustomerSelect = (customer: Customer) => {
            formik.setFieldValue(`customerId`, customer._id);
          };

          const onDateChange = (date: Date | null, fieldName: 'deliveryTime' | 'pickupTime') => {
            let day = null;
            let month = null;
            let year = null;

            if (date) {
              day = date.getDate();
              month = date.getMonth() + 1;
              year = date.getFullYear();
            }

            formik.setFieldValue(`${fieldName}.day`, day);
            formik.setFieldValue(`${fieldName}.month`, month);
            formik.setFieldValue(`${fieldName}.year`, year);
            formik.setFieldValue(`${fieldName}.date`, date ? date : null);
          };

          const formatDiscount = (discount: Discount | undefined) => {
            if (discount) {
              const { code, type, value, percent } = discount;
              return `${code}, ${type}, ${type === DiscountType.MONETARY ? `${value}€` : `${percent}%`}`;
            }
            return null;
          };

          return (
            <Form id="create-order" className="d-flex flex-column gap-4">
              <CustomerSearchForm
                setCustomer={setCustomer}
                formik={formik}
                selectedCustomerId={formik.values.customerId}
                onCustomerSelect={onCustomerSelect}
                disabled={false}
              />
              <Row>
                <DatePicker
                  label="Created At*"
                  placeholderText="Select creation date"
                  date={formik.values.createdAt ? moment(formik.values.createdAt, 'DD-MM-YYYY').toDate() : null}
                  onChange={(date) => formik.setFieldValue('createdAt', date)}
                  error={formik.touched.createdAt && formik.errors.createdAt ? (formik.errors.createdAt as string) : ''}
                />
              </Row>
              <Row>
                <Col>
                  <Label>City*</Label>
                  <Dropdown isOpen={cityOpen} toggle={toggleCity}>
                    <DropdownToggle
                      caret
                      className={`btn-md w-100 justify-content-between form-control ${
                        formik.touched.city && formik.errors.city ? 'border-danger is-invalid' : ''
                      }`}
                    >
                      {formik.values.city}
                    </DropdownToggle>
                    <DropdownMenu className="w-100">
                      {Object.values(SupportedCities).map((val) => (
                        <DropdownItem
                          key={val}
                          className={val.toLowerCase() === formik.values.city.toLowerCase() ? 'checked' : ''}
                          onClick={() => {
                            formik.setFieldValue('city', val);
                          }}
                        >
                          {val}
                        </DropdownItem>
                      ))}
                    </DropdownMenu>
                  </Dropdown>
                  {!!formik.touched.city && formik.errors.city && (
                    <FormFeedback className="d-block" invalid>
                      {formik.errors.city?.toString()}
                    </FormFeedback>
                  )}
                </Col>
                <Col>
                  <Label>Order Status*</Label>
                  <Dropdown isOpen={orderStatusOpen} toggle={toggleOrderStatus}>
                    <DropdownToggle
                      caret
                      className={`btn-md w-100 justify-content-between form-control ${
                        formik.touched.status && formik.errors.status ? 'border-danger is-invalid' : ''
                      }`}
                    >
                      {formik.values?.status || 'Select status'}
                    </DropdownToggle>
                    <DropdownMenu className="w-100">
                      {Object.values(OrderStatus)
                        .slice(1)
                        .map((val) => (
                          <DropdownItem
                            key={val}
                            className={val.toLowerCase() === formik.values?.status?.toLowerCase() ? 'checked' : ''}
                            onClick={() => {
                              formik.setFieldValue('status', val);
                            }}
                          >
                            {val}
                          </DropdownItem>
                        ))}
                    </DropdownMenu>
                  </Dropdown>
                  {!!formik.touched.status && formik.errors.status && (
                    <FormFeedback className="d-block" invalid>
                      {formik.errors.status?.toString()}
                    </FormFeedback>
                  )}
                </Col>
              </Row>
              <Divider />
              <Row>
                <h6 className="mt-4 mb-0">Pick-up Information</h6>
              </Row>
              <CreateAddressForm formik={formik} field="pickupAddress" />
              <Row>
                <Col>
                  <DatePicker
                    label="Date*"
                    highlightDates={formik.values.deliveryTime?.date ? [moment(formik.values.deliveryTime?.date).toDate()] : []}
                    placeholderText="Select pick-up date"
                    notification="Only Tuesdays and Fridays are available for pick-up and delivery"
                    dateFilter={isDriverWorkingDay}
                    date={formik.values.pickupTime?.date ? new Date(formik.values.pickupTime?.date) : null}
                    maxDate={
                      formik.values.deliveryTime?.date
                        ? moment(formik.values.deliveryTime?.date).subtract(DAYS_GAP_BETWEEN_PICK_UP_ADN_DELIVERY, 'days').toDate()
                        : null
                    }
                    onChange={(date) => onDateChange(date, 'pickupTime')}
                    error={formik.touched.pickupTime?.date && formik.errors.pickupTime?.date ? formik.errors.pickupTime?.date : ''}
                  />
                </Col>
                <Col>
                  <Label>Timeframe*</Label>
                  <Dropdown isOpen={pickUpTimeframeOpen} toggle={togglePickUpTimeframe}>
                    <DropdownToggle
                      caret
                      className={`btn-md w-100 justify-content-between form-control ${
                        !!formik.touched.pickupTime?.timeframe && formik.errors.pickupTime?.timeframe ? 'border-danger is-invalid' : ''
                      }`}
                    >
                      {formik.values?.pickupTime?.timeframe || 'Select timeframe'}
                    </DropdownToggle>
                    <DropdownMenu className="w-100">
                      {Object.values(OrderTimeframe).map((val) => (
                        <DropdownItem
                          key={val}
                          className={val.toLowerCase() === formik.values?.pickupTime?.timeframe?.toLowerCase() ? 'checked' : ''}
                          onClick={() => {
                            formik.setFieldValue('pickupTime.timeframe', val);
                          }}
                          title={`${val} ${getTimeframeRange(val)}`}
                        >
                          {val} {getTimeframeRange(val)}
                        </DropdownItem>
                      ))}
                    </DropdownMenu>
                  </Dropdown>
                  {!!formik.touched.pickupTime?.timeframe && formik.errors.pickupTime?.timeframe && (
                    <FormFeedback className="d-block" invalid>
                      {formik.errors.pickupTime?.timeframe?.toString()}
                    </FormFeedback>
                  )}
                </Col>
              </Row>
              <Divider />
              <Row>
                <h6 className="mt-4 mb-0">Delivery Information</h6>
              </Row>
              <CreateAddressForm formik={formik} field="deliveryAddress" />
              <Row>
                <Col>
                  <DatePicker
                    label="Date*"
                    highlightDates={formik.values.pickupTime?.date ? [moment(formik.values.pickupTime?.date).toDate()] : []}
                    placeholderText="Select delivery date"
                    notification="Only Tuesdays and Fridays are available for pick-up and delivery"
                    dateFilter={isDriverWorkingDay}
                    date={formik.values.deliveryTime?.date ? new Date(formik.values.deliveryTime?.date) : null}
                    minDate={
                      formik.values.pickupTime?.date ? moment(formik.values.pickupTime?.date).add(DAYS_GAP_BETWEEN_PICK_UP_ADN_DELIVERY, 'days').toDate() : null
                    }
                    onChange={(date) => onDateChange(date, 'deliveryTime')}
                    error={formik.touched.deliveryTime?.date && formik.errors.deliveryTime?.date ? formik.errors.deliveryTime?.date : ''}
                  />
                </Col>
                <Col>
                  <Label>Timeframe*</Label>
                  <Dropdown isOpen={deliveryTimeframeOpen} toggle={toggleDeliveryTimeframe}>
                    <DropdownToggle
                      caret
                      className={`btn-md w-100 justify-content-between form-control ${
                        !!formik.touched.deliveryTime?.timeframe && formik.errors.deliveryTime?.timeframe ? 'border-danger is-invalid' : ''
                      }`}
                    >
                      {formik.values?.deliveryTime?.timeframe || 'Select timeframe'}
                    </DropdownToggle>
                    <DropdownMenu className="w-100">
                      {Object.values(OrderTimeframe).map((val) => (
                        <DropdownItem
                          key={val}
                          className={val.toLowerCase() === formik.values?.deliveryTime?.timeframe?.toLowerCase() ? 'checked' : ''}
                          onClick={() => {
                            formik.setFieldValue('deliveryTime.timeframe', val);
                          }}
                          title={`${val} ${getTimeframeRange(val)}`}
                        >
                          {val} {getTimeframeRange(val)}
                        </DropdownItem>
                      ))}
                    </DropdownMenu>
                  </Dropdown>
                  {!!formik.touched.deliveryTime?.timeframe && formik.errors.deliveryTime?.timeframe && (
                    <FormFeedback className="d-block" invalid>
                      {formik.errors.deliveryTime?.timeframe?.toString()}
                    </FormFeedback>
                  )}
                </Col>
              </Row>
              <Divider />
              <Row>
                <h6>Price-related Data</h6>
              </Row>
              <Row>
                <Col>
                  <CustomInput
                    label="Box Count*"
                    inputProps={{
                      id: 'boxCount',
                      name: 'boxCount',
                      type: 'number',
                      min: 0,
                      value: formik.values.boxCount,
                      onChange: formik.handleChange,
                    }}
                    error={formik.touched.boxCount && formik.errors.boxCount ? formik.errors.boxCount : ''}
                  />
                </Col>
                <Col>
                  <CustomInput
                    label="Box Price (cent)*"
                    inputProps={{
                      id: 'boxPriceCent',
                      name: 'boxPriceCent',
                      type: 'number',
                      min: 0,
                      value: formik.values.boxPriceCent,
                      onChange: formik.handleChange,
                    }}
                    error={formik.touched.boxPriceCent && formik.errors.boxPriceCent ? formik.errors.boxPriceCent : ''}
                  />
                </Col>
              </Row>
              <Row>
                <Col>
                  <CustomInput
                    label="Courtier Price (cent)*"
                    inputProps={{
                      id: 'courierPriceCent',
                      name: 'courierPriceCent',
                      type: 'number',
                      min: 0,
                      value: formik.values.courierPriceCent,
                      onChange: formik.handleChange,
                    }}
                    error={formik.touched.courierPriceCent && formik.errors.courierPriceCent ? formik.errors.courierPriceCent : ''}
                  />
                </Col>
                <Col>
                  <CustomInput
                    label="Shipping Threshold (cent)*"
                    inputProps={{
                      id: 'shippingExemptionThreshold',
                      name: 'shippingExemptionThreshold',
                      type: 'number',
                      min: 0,
                      value: formik.values.shippingExemptionThreshold,
                      onChange: formik.handleChange,
                    }}
                    error={
                      formik.touched.shippingExemptionThreshold && formik.errors.shippingExemptionThreshold ? formik.errors.shippingExemptionThreshold : ''
                    }
                  />
                </Col>
              </Row>
              <Row>
                <Col>
                  <Label>Discount</Label>
                  <Dropdown isOpen={discountsOpen} toggle={toggleDiscounts}>
                    <DropdownToggle caret className="btn-md w-100 justify-content-between">
                      {formatDiscount(discounts.find(({ _id }) => _id === formik.values?.discount?._id)) || 'Select discount'}
                    </DropdownToggle>
                    <DropdownMenu className="w-100">
                      {!!discounts &&
                        !!discounts.length &&
                        discounts.map((discount) => {
                          const { _id } = discount;
                          return (
                            <DropdownItem
                              key={_id}
                              className={_id.toLowerCase() === formik.values?.discount?._id?.toLowerCase() ? 'checked' : ''}
                              onClick={() => {
                                if (_id === formik.values?.discount?._id) {
                                  formik.setFieldValue('discount', { _id: '' });
                                } else {
                                  formik.setFieldValue('discount', { _id });
                                }
                              }}
                            >
                              {formatDiscount(discount)}
                            </DropdownItem>
                          );
                        })}
                    </DropdownMenu>
                  </Dropdown>
                </Col>
              </Row>
              <Divider />
              <Row>
                <h6>Products*</h6>
              </Row>
              <Row>
                <ProductForm selectedRepairs={selectedRepairs} onRepairSelect={onRepairSelect} formik={formik} />
                <FieldArray
                  name="repairList"
                  render={(arrayHelpers) =>
                    !!formik.values?.repairList.length && (
                      <List>
                        {formik.values?.repairList
                          .sort(({ product: productFirst }, { product: productSecond }) => {
                            const { name: nameFirst } = productFirst;
                            const { name: nameSecond } = productSecond;

                            if (nameFirst > nameSecond) {
                              return 1;
                            }
                            if (nameFirst < nameSecond) {
                              return -1;
                            }
                            return 0;
                          })
                          .map(({ product }, index) => (
                            <RepairDetailsForm
                              key={`repair-${index}`}
                              product={product}
                              index={index}
                              arrayHelpers={arrayHelpers}
                              selectedRepairs={formik.values?.repairList}
                              removeRepair={removeRepair}
                              formik={formik}
                              isViewOnly={false}
                              repairList={values.repairList}
                              clientName={customer}
                              creationTime={creationTime}
                            />
                          ))}
                      </List>
                    )
                  }
                />
              </Row>
              <Divider />
              <Row>
                <h6>Payment Data</h6>
              </Row>
              <PaymentParamsForm formik={formik} isViewOnly={false} />
              <Row className="mt-5">
                <Col xs={12} md={{ offset: 4, size: 4 }} xxl={{ offset: 5, size: 2 }}>
                  <Button color="primary" type="button" className="w-100 btn-md" disabled={loading} onClick={() => onSubmit(formik)}>
                    {type === 'new' ? 'Create' : 'Update'} Order {loading && <Spinner size="sm" color="secondary" />}
                  </Button>
                </Col>
              </Row>
            </Form>
          );
        }}
      </Formik>
    </Col>
  );
};

export { OrderDetailsForm };
