const sizeValidator = (file: File, maxSize: number) => {
  if (file.size > maxSize * 1024 * 1024) {
    return {
      code: 'Size too big',
      message: `File size is larger than ${maxSize}MB`,
    };
  }
  return null;
};

export { sizeValidator };
