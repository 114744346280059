import moment from 'moment';
import { DRIVER_WORKING_DAYS } from './dateConstants';
import { DateUnixFilter } from '../types';
import { DATE_FORMAT } from './constants';

const isDriverWorkingDay = (date: Date | null) => {
  if (!date) {
    return false;
  }

  const day = new Date(date).getDay();
  return DRIVER_WORKING_DAYS.includes(day);
};

const dateFilterToDate = ({ unix }: DateUnixFilter) => {
  if (unix === 0) {
    return null;
  }

  return moment(unix).toDate();
};

const onCreatedAtChangeChange = (date: Date | null, type: 'start' | 'end', setDateFilter: (date: number | null) => void) => {
  if (!date) {
    return setDateFilter(null);
  }

  const momentDate = moment(date, DATE_FORMAT);
  const month = momentDate.month() + 1;
  const dateNumber = momentDate.date();
  const monthFormatted = month < 10 ? `0${month}` : month;
  const dateFormatted = dateNumber < 10 ? `0${dateNumber}` : dateNumber;

  setDateFilter(moment(`${momentDate.year()}-${monthFormatted}-${dateFormatted}T${type === 'start' ? '00:00:00' : '23:59:59'}Z`).unix() * 1000);
};

export { isDriverWorkingDay, dateFilterToDate, onCreatedAtChangeChange };
