import { useMemo, useState } from 'react';
import { Formik } from 'formik';
import { toastr } from 'react-redux-toastr';
import moment from 'moment';
import { Button, Container, Form, Modal, ModalBody, ModalHeader, Row, Spinner } from 'reactstrap';
import { Times as TimesIcon } from '@styled-icons/typicons/Times';
import { CreateOrderDateForm } from '../../components/CreateOrderDateForm';
import { CreateAddressForm } from '../../../../components/Forms/CreateAddressForm';
import { apiRequest, TOAST_IMPORTANT_TIME_OUT_MS, handleError } from '../../../../utils';
import { initialOrderDateAddress, AssignedOrderWithCustomer, CreateAddress, RescheduleForm, RescheduleDateSchema } from '../../../../types';

type RescheduleModalProps = {
  visibility: boolean;
  order: AssignedOrderWithCustomer;
  toggle: any;
};

const RescheduleModal = ({ visibility, order, toggle }: RescheduleModalProps) => {
  const values = useMemo<RescheduleForm>(() => {
    return {
      pickup: initialOrderDateAddress(order.pickupTime, order.pickupAddress as CreateAddress),
      delivery: initialOrderDateAddress(order.deliveryTime, order.deliveryAddress as CreateAddress),
    };
  }, [order]);
  const [loading, setLoading] = useState<boolean>(false);

  const handleReschedule = async (value: RescheduleForm) => {
    const abortController = new AbortController();
    const scheduleUpdate = {
      pickup: {
        time: {
          date: moment(value.pickup.date.date).format(),
          timeframe: value.pickup.date.timeframe,
        },
        address: value.pickup.address,
      },
      delivery: {
        time: {
          date: moment(value.delivery.date.date).format(),
          timeframe: value.delivery.date.timeframe,
        },
        address: value.delivery.address,
      },
    };

    try {
      setLoading(true);
      const { data } = await apiRequest(`order/reschedule-pickup-and-delivery?orderId=${order._id}&customerId=${order.customer._id}`, {
        method: 'PATCH',
        data: JSON.stringify(scheduleUpdate),
        signal: abortController.signal,
      });

      const { message } = data;

      if (message) {
        if (Array.isArray(message)) {
          message.forEach((msg) => toastr.error('Error', msg, { timeOut: TOAST_IMPORTANT_TIME_OUT_MS }));
        } else {
          toastr.error('Error', message, { timeOut: TOAST_IMPORTANT_TIME_OUT_MS });
        }
        return () => abortController.abort();
      } else {
        toastr.success('Success', 'Order was rescheduled!');
        toggle();
        window.location.reload();
      }
    } catch (err) {
      handleError('ORDER_RESCHEDULE', err);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Modal isOpen={visibility} centered backdrop>
      <ModalHeader
        close={
          <Button className="btn-icon" onClick={toggle}>
            <TimesIcon className="icon" />
          </Button>
        }
      >
        Reschedule Order
      </ModalHeader>
      <ModalBody>
        <Container>
          <Row>
            <Formik enableReinitialize initialValues={values as any} validationSchema={RescheduleDateSchema} onSubmit={handleReschedule}>
              {(formik) => {
                return (
                  <Form id="reschedule-order" className="d-flex flex-column gap-4">
                    <Row>
                      <h6>Choose new pickup date and time</h6>
                    </Row>
                    <CreateOrderDateForm formik={formik} field="pickup.date" isPickupDate />
                    <CreateAddressForm formik={formik} field="pickup.address" isOrderForm />
                    <Row>
                      <h6>Choose new delivery date and time</h6>
                    </Row>
                    <CreateOrderDateForm formik={formik} field="delivery.date" isPickupDate={false} />
                    <CreateAddressForm formik={formik} field="delivery.address" isOrderForm />
                    <Button className="btn-md" color="secondary" disabled={loading} onClick={() => handleReschedule(formik.values)}>
                      Reschedule {loading && <Spinner size="sm" color="secondary" />}
                    </Button>
                  </Form>
                );
              }}
            </Formik>
          </Row>
        </Container>
      </ModalBody>
    </Modal>
  );
};

export { RescheduleModal };
