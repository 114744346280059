import { Address } from './address';
import { OrderStatus, SupportedCities } from '../enums';
import { OrderDiscount } from './discount';
import { OrderDate } from './order-date';
import { AssignmentPopulated } from './assignment';
import { Repair } from './repair';
import { Craftsman } from './craftsman';
import { Driver } from './driver';
import { Customer } from './customer';
import { PaymentParams } from './payment-params';
import { ShippingOrder } from './shipping-order';
import { Voucher } from './vouchers';

const BoxPriceCent = 150;
const CourierPriceCent = 950;
const ShippingExemptionThreshold = 12500;

type ServiceRating = {
  serviceRating: number;
  serviceComment: string;
}

type Order = {
  _id: string;
  number: string;
  customerId: string;
  repairList: Repair[];
  createdAt: Date;
  pickupAddress: Address;
  deliveryAddress: Address;
  status: OrderStatus;
  pickupTime: OrderDate;
  deliveryTime: OrderDate;
  city: SupportedCities;
  boxCount: number;
  boxPriceCent: number;
  courierPriceCent: number;
  shippingExemptionThreshold: number;
  discount: OrderDiscount;
  voucher: Voucher;
  paymentParams: PaymentParams;
  rating: ServiceRating[];
};

type OrderWithAssignment = Order & { assignment: AssignmentPopulated };

type UnscheduledOrders = {
  delivery: OrderWithAssignment[];
  pickup: OrderWithAssignment[];
};

type AssignedOrder = Order & {
  craftsmen: Craftsman[];
  order: Order;
  shippingOrder: ShippingOrder;
  voucherOrder: Voucher;
  pickupDriver: Driver;
  deliveryDriver: Driver;
};

type AssignedOrderWithCustomer = AssignedOrder & { customer: Customer };

export { BoxPriceCent, CourierPriceCent, ShippingExemptionThreshold };
export type { Order, OrderWithAssignment, UnscheduledOrders, AssignedOrder, AssignedOrderWithCustomer };
