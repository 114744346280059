import { Col, Row } from 'reactstrap';
import { CustomInput } from '../../../components/Input/CustomInput';

const ContactForm = ({ formik, onNameChange, onNameBlur }: CreateContactFormProps) => (
  <Row>
    <Col>
      <CustomInput
        label="Full Name"
        inputProps={{
          id: 'fullName',
          name: `addressSecondary.contact.fullName`,
          type: 'text',
          value: formik.values.addressSecondary?.contact?.fullName,
          onChange: (event) => {
            formik.handleChange(event);
            onNameChange();
          },
          onBlur: onNameBlur,
        }}
        error={
          formik.touched.addressSecondary?.contact?.fullName && formik.errors.addressSecondary?.contact?.fullName
            ? formik.errors.addressSecondary?.contact?.fullName
            : ''
        }
      />
    </Col>
    <Col>
      <CustomInput
        label="Email"
        inputProps={{
          id: 'email',
          name: `addressSecondary.contact.email`,
          type: 'email',
          value: formik.values.addressSecondary?.contact?.email,
          onChange: formik.handleChange,
        }}
        error={
          formik.touched.addressSecondary?.contact?.email && formik.errors.addressSecondary?.contact?.email
            ? formik.errors.addressSecondary?.contact?.email
            : ''
        }
      />
    </Col>
    <Col>
      <CustomInput
        label="Phone Number"
        inputProps={{
          id: 'phoneNumber',
          name: `addressSecondary.contact.phoneNumber`,
          type: 'tel',
          value: formik.values.addressSecondary?.contact?.phoneNumber,
          onChange: formik.handleChange,
        }}
        error={
          formik.touched.addressSecondary?.contact?.phoneNumber && formik.errors.addressSecondary?.contact?.phoneNumber
            ? formik.errors.addressSecondary?.contact?.phoneNumber
            : ''
        }
      />
    </Col>
    <Col>
      <CustomInput
        label="Phone Number #2"
        inputProps={{
          id: 'phoneNumber2',
          name: `addressSecondary.contact.phoneNumber2`,
          type: 'tel',
          value: formik.values.addressSecondary?.contact?.phoneNumber2,
          onChange: formik.handleChange,
        }}
        error={
          formik.touched.addressSecondary?.contact?.phoneNumber2 && formik.errors.addressSecondary?.contact?.phoneNumber2
            ? formik.errors.addressSecondary?.contact?.phoneNumber2
            : ''
        }
      />
    </Col>
  </Row>
);

type CreateContactFormProps = {
  formik: any;
  onNameChange: () => void;
  onNameBlur: () => void;
};

export { ContactForm };
