import { useEffect, useState } from 'react';
import { Button, Col, Modal, ModalBody, ModalHeader, Row } from 'reactstrap';
import { toastr } from 'react-redux-toastr';
import Table from './components/Table';
import { Divider } from './components/Divider/Divider';
import { RescheduleModal } from './components/RescheduleModal';
import { EmailTriggersOrder } from './components/EmailTriggersOrder';
import RepairDetails from './components/RepairDetails/RepairDetails';
import {
  deliveryAddressColumns,
  orderColumns,
  pickupAddressColumns,
  ratingColumns,
  ratingServiceColumns,
  orderVoucherDiscountColumns,
  orderDiscountColumns,
} from './components/columns';
import { Times as TimesIcon } from '@styled-icons/typicons/Times';
import { Download } from '@styled-icons/boxicons-regular/Download';
import { Close } from '@styled-icons/material/Close';
import { LocationPin } from '@styled-icons/entypo/LocationPin';
import { apiRequest, handleError, parsePdf, TOAST_IMPORTANT_TIME_OUT_MS } from '../../../utils';
import { AssignedOrderWithCustomer, LatLngLiteral } from '../../../types';
import './OrderDetails.scss';
import { OrderStatus } from '../../../enums';

const extractLocation = ({ lat, lng }: LatLngLiteral) => `${lat},${lng}`;

const OrderDetails = ({ _id, toggle }: OrderDetailsProps) => {
  const [loading, setLoading] = useState(false);
  const [order, setOrder] = useState<AssignedOrderWithCustomer>();
  const [rescheduleModalVisibility, setRescheduleModalVisibility] = useState<boolean>(false);

  const orderTableColumns = orderColumns();
  const pickupAddressTableColumns = pickupAddressColumns();
  const deliveryAddressTableColumns = deliveryAddressColumns();
  const ratingCraftsmanTableColumns = ratingColumns();
  const ratingServiceTableColumns = ratingServiceColumns();
  const orderVoucherDiscountTableColumns = orderVoucherDiscountColumns();
  const orderDiscountTableColumns = orderDiscountColumns();

  useEffect(() => {
    onLoad();
  }, [_id]);

  const onLoad = async () => {
    enableLoading();
    const abortController = new AbortController();
    try {
      const { data } = await apiRequest(`order/by-id?orderId=${_id}`, { signal: abortController.signal });
      const { data: customerResponse } = await apiRequest('customer', { signal: abortController.signal });
      const { items } = customerResponse;
      const foundCustomer = items.find((item: any) => item._id === data.customerId);
      setOrder({ ...data, customer: foundCustomer });
    } catch (err) {
      handleError('ORDER_AND_CUSTOMER_FETCH', err);
      if (err instanceof DOMException && err.name === 'AbortError') {
        toastr.error('Error', 'Your browser aborted the request', { timeOut: TOAST_IMPORTANT_TIME_OUT_MS });
      } else {
        toastr.error('Error', 'Failed to fetch order details or customer data', { timeOut: TOAST_IMPORTANT_TIME_OUT_MS });
      }
      abortController.abort();
    } finally {
      disableLoading();
    }
  };

  const handleCancel = async (customerId: AssignedOrderWithCustomer['customerId'], orderId: AssignedOrderWithCustomer['_id']) => {
    enableLoading();
    const abortController = new AbortController();

    try {
      const { data } = await apiRequest(`order/cancel?orderId=${orderId}&customerId=${customerId}`, {
        method: 'PATCH',
        signal: abortController.signal,
      });

      const { message } = data;

      if (message) {
        if (Array.isArray(message)) {
          message.forEach((msg) => toastr.error('Error', msg, { timeOut: TOAST_IMPORTANT_TIME_OUT_MS }));
        } else {
          toastr.error('Error', message, { timeOut: TOAST_IMPORTANT_TIME_OUT_MS });
        }
        abortController.abort();
      }
      await onLoad();
    } catch (err) {
      handleError('ORDER_CANCEL', err);
      if (err instanceof DOMException && err.name === 'AbortError') {
        toastr.error('Error', 'Your browser aborted the request', { timeOut: TOAST_IMPORTANT_TIME_OUT_MS });
      } else {
        toastr.error('Error', 'Failed to cancel the order', { timeOut: TOAST_IMPORTANT_TIME_OUT_MS });
      }
      abortController.abort();
    } finally {
      disableLoading();
    }
  };

  const handleClose = async (customerId: AssignedOrderWithCustomer['customerId'], orderId: AssignedOrderWithCustomer['_id']) => {
    enableLoading();
    const abortController = new AbortController();

    try {
      const { data } = await apiRequest(`order/close?orderId=${orderId}&customerId=${customerId}`, {
        method: 'PATCH',
        signal: abortController.signal,
      });

      const { message } = data;

      if (message) {
        if (Array.isArray(message)) {
          message.forEach((msg) => toastr.error('Error', msg, { timeOut: TOAST_IMPORTANT_TIME_OUT_MS }));
        } else {
          toastr.error('Error', message, { timeOut: TOAST_IMPORTANT_TIME_OUT_MS });
        }
        abortController.abort();
      }
      await onLoad();
    } catch (err) {
      handleError('ORDER_CLOSE', err);
      if (err instanceof DOMException && err.name === 'AbortError') {
        toastr.error('Error', 'Your browser aborted the request', { timeOut: TOAST_IMPORTANT_TIME_OUT_MS });
      } else {
        toastr.error('Error', 'Failed to close the order', { timeOut: TOAST_IMPORTANT_TIME_OUT_MS });
      }
      abortController.abort();
    } finally {
      disableLoading();
    }
  };

  const downloadInvoice = async (customerId: AssignedOrderWithCustomer['customerId'], orderId: AssignedOrderWithCustomer['_id']) => {
    enableLoading();
    const abortController = new AbortController();
    try {
      const { data: invoice } = await apiRequest(`order/invoice?customerId=${customerId}&orderId=${orderId}`, {
        headers: { 'Content-Type': 'application/pdf' },
        signal: abortController.signal,
      });

      parsePdf(invoice, orderId);
    } catch (err) {
      handleError('INVOICE_DOWNLOAD__ORDERS', err);
      if (err instanceof DOMException && err.name === 'AbortError') {
        toastr.error('Error', 'Your browser aborted the request', { timeOut: TOAST_IMPORTANT_TIME_OUT_MS });
      } else {
        toastr.error('Error', 'Failed to download an invoice', { timeOut: TOAST_IMPORTANT_TIME_OUT_MS });
      }
      abortController.abort();
    } finally {
      disableLoading();
    }
  };

  const handleLocation = (coords: string) => {
    window.open(`https://www.google.com/maps/place/${coords}`);
  };
  const toggleRescheduleModal = () => {
    setRescheduleModalVisibility(!rescheduleModalVisibility);
  };
  const enableLoading = () => {
    setLoading(true);
  };
  const disableLoading = () => {
    setLoading(false);
  };

  return (
    <Modal isOpen={!!_id} toggle={toggle} centered backdrop size="xl" fullscreen scrollable>
      <ModalHeader
        toggle={toggle}
        close={
          <Button className="btn-icon" onClick={toggle}>
            <TimesIcon className="icon" />
          </Button>
        }
        tag="div"
      >
        <h5 className="list-header">Order Details</h5>
      </ModalHeader>
      <ModalBody>
        {order && (
          <>
            <Row className="justify-content-between gx-0 gap-3 flex-nowrap pr-3">
              <Col md={4}>
                <h5 className="mb-3">General Information</h5>
                <Table columns={orderTableColumns} data={[order]} />
                <Col>
                  <h5 className="mb-3">Discounts & Vouchers</h5>
                  <Table columns={orderVoucherDiscountTableColumns} data={[order]} className="h-auto" valueColumn />
                  <Table columns={orderDiscountTableColumns} data={[order]} className="h-auto" valueColumn />
                </Col>
              </Col>
              <Col md={4}>
                <h5 className="mb-3">Pickup Address</h5>
                <Table columns={pickupAddressTableColumns} data={[order]} className="h-auto" />
                <Button
                  className="w-100"
                  onClick={() => handleLocation(extractLocation(order.pickupAddress.place!.geometry.location))}
                  disabled={!order.pickupAddress.place}
                >
                  {!order.pickupAddress.place && 'No Location provided'}
                  {!!order.pickupAddress.place && 'Check Location'}
                  <LocationPin className="icon" width={30} height={30} title="" />
                </Button>
                <Row className="pt-3">
                  <Table columns={ratingCraftsmanTableColumns} data={[order]} shortColumn className="h-auto" />
                </Row>
              </Col>
              <Col md={4} className="pr-3">
                <h5 className="mb-3">Delivery Address</h5>
                <Table columns={deliveryAddressTableColumns} data={[order]} className="h-auto" />
                <Button
                  className="w-100"
                  onClick={() => handleLocation(extractLocation(order.deliveryAddress.place!.geometry.location))}
                  disabled={!order.deliveryAddress.place}
                >
                  {!order.deliveryAddress.place && 'No Location provided'}
                  {!!order.deliveryAddress.place && 'Check Location'}
                  <LocationPin className="icon" width={30} height={30} title="" />
                </Button>
                <Row className="pt-3">
                  <Table columns={ratingServiceTableColumns} data={[order]} className="h-auto" shortColumn />
                </Row>
              </Col>
            </Row>
            <div>
              <h5 className="mt-5 mb-3">Actions</h5>
              <Row className="gx-0 gap-4 mb-4">
                <Col>
                  <Button
                    className="bg-secondary text-white w-100 h-100 font-18"
                    onClick={() => downloadInvoice(order.customerId, order._id)}
                    disabled={loading}
                  >
                    Download Invoice
                    <Download width={30} height={30} title="" />
                  </Button>
                </Col>
                <Col>
                  <EmailTriggersOrder
                    orderId={order?._id}
                    customerId={order.customerId}
                    disabled={[OrderStatus.CANCELED, OrderStatus.CLOSED].includes(order.status) || loading}
                  />
                </Col>
                <Col>
                  <Button
                    color="success"
                    className="bg-success text-white w-100 h-100 font-18"
                    onClick={toggleRescheduleModal}
                    disabled={[OrderStatus.CANCELED, OrderStatus.CLOSED].includes(order.status) || loading}
                  >
                    Reschedule
                  </Button>
                </Col>
                <Col>
                  <Button
                    color="danger"
                    className="text-danger border-danger w-100 h-100 font-18"
                    onClick={() => handleCancel(order.customerId, order._id)}
                    disabled={[OrderStatus.CANCELED, OrderStatus.CLOSED].includes(order.status) || loading}
                  >
                    Cancel Order
                  </Button>
                </Col>
                <Col>
                  <Button
                    color="danger"
                    className="bg-danger text-white w-100 h-100 font-18 d-flex align-items-center gap-2"
                    onClick={() => handleClose(order.customerId, order._id)}
                    disabled={[OrderStatus.CANCELED, OrderStatus.CLOSED].includes(order.status) || loading}
                  >
                    Close Order
                    <Close width={25} height={25} title="" />
                  </Button>
                </Col>
              </Row>
            </div>
            <Divider />
            <div>
              <h5 className="mt-5 mb-3">Repairs</h5>
              <div className="d-flex flex-column gap-3">
                {!order.repairList || (!order.repairList.length && "No repairs or can't load repair details")}
                {order.repairList && !!order.repairList.length && order.repairList.map((repair) => <RepairDetails key={repair._id} repair={repair} />)}
              </div>
            </div>
            <RescheduleModal visibility={rescheduleModalVisibility} order={order} toggle={toggleRescheduleModal} />
          </>
        )}
        {!order && <h1 className="mb-5 text-danger text-wrap">Order or customer details are loading or not found.</h1>}
      </ModalBody>
    </Modal>
  );
};

type OrderDetailsProps = {
  _id: AssignedOrderWithCustomer['_id'];
  toggle: () => void;
};

export { OrderDetails };
