import { useEffect, useState } from 'react';
import { Button, Col, Form, List, ListInlineItem, Row, Spinner } from 'reactstrap';
import { toastr } from 'react-redux-toastr';
import { Formik, FormikTouched, setNestedObjectValues } from 'formik';
import { useNavigate, useParams } from 'react-router';
import * as yup from 'yup';
import { apiRequest, handleError, TOAST_IMPORTANT_TIME_OUT_MS } from '../../utils';
import { CustomInput } from '../../components/Input/CustomInput';
import { DriverStatus, Pages } from '../../enums';
import BackDetailsLink from '../../components/BackDetailsLink/BackDetailsLink';

type DriverDetailsProps = {
  type?: 'new' | 'existing';
};

const DriverSchema = yup.object({
  _id: yup.string().optional().nullable(),
  number: yup.string().optional().nullable(),
  contact: yup
    .object({
      email: yup.string().required('This field is required.'),
      phoneNumber: yup.string().required('This field is required.'),
      phoneNumber2: yup.string().required('This field is required.'),
      firstName: yup.string().required('This field is required.'),
      lastName: yup.string().required('This field is required.'),
    })
    .defined()
    .required(),
  active: yup.mixed<DriverStatus>().oneOf(Object.values(DriverStatus)).required('This field is required.'),
  transports: yup.array().optional(),
});
type DriverSchemaType = yup.InferType<typeof DriverSchema>;

const DriverDetails = ({ type = 'new' }: DriverDetailsProps) => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [values, setValues] = useState<DriverSchemaType>({
    _id: '',
    number: '',
    contact: { email: '', phoneNumber: '', phoneNumber2: '', firstName: '', lastName: '' },
    active: DriverStatus.ACTIVE,
    transports: [],
  });
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (type === 'new') {
      return;
    }
    onLoad();
  }, [id]);

  const enableLoading = () => {
    setLoading(true);
  };
  const disableLoading = () => {
    setLoading(false);
  };

  const onLoad = async () => {
    const abortController = new AbortController();
    try {
      enableLoading();
      const { data } = await apiRequest(`/driver/by-id/${id}`, { signal: abortController.signal });
      const { message } = data;

      if (message) {
        if (Array.isArray(message)) {
          message.forEach((msg) => toastr.error('Error', msg, { timeOut: TOAST_IMPORTANT_TIME_OUT_MS }));
        } else {
          toastr.error('Error', message, { timeOut: TOAST_IMPORTANT_TIME_OUT_MS });
        }
        abortController.abort();
        return disableLoading();
      }
      setValues({ ...data, active: data.active ? DriverStatus.ACTIVE : DriverStatus.INACTIVE });
      disableLoading();
    } catch (err) {
      handleError('FETCH_CUSTOMER', err);
      if (err instanceof DOMException && err.name === 'AbortError') {
        toastr.error('Error', 'Your browser aborted the request', { timeOut: TOAST_IMPORTANT_TIME_OUT_MS });
      } else {
        toastr.error('Error', 'Failed to fetch driver by ID', { timeOut: TOAST_IMPORTANT_TIME_OUT_MS });
      }
      abortController.abort();
    }
  };

  const onCreate = async (values: DriverSchemaType, action: any) => {
    const abortController = new AbortController();
    try {
      enableLoading();
      const driver = {
        ...values,
        active: values.active === DriverStatus.ACTIVE,
        transports: values.transports ? values.transports : [],
      };
      delete driver._id;
      delete driver.number;

      const { data } = await apiRequest('/driver', {
        method: 'POST',
        data: JSON.stringify({ ...driver }),
      });
      const { message } = data;

      if (message) {
        if (Array.isArray(message)) {
          message.forEach((msg) => toastr.error('Error', msg, { timeOut: TOAST_IMPORTANT_TIME_OUT_MS }));
        } else {
          toastr.error('Error', message, { timeOut: TOAST_IMPORTANT_TIME_OUT_MS });
        }
        action.setSubmitting(false);
        abortController.abort();
        return disableLoading();
      }
      disableLoading();
      toastr.success('Success', 'Driver created');
      navigate(Pages.CUSTOMERS);
    } catch (err) {
      handleError('CREATE_CUSTOMER', err);
      if (err instanceof DOMException && err.name === 'AbortError') {
        toastr.error('Error', 'Your browser aborted the request', { timeOut: TOAST_IMPORTANT_TIME_OUT_MS });
      } else {
        toastr.error('Error', 'Failed to create driver', { timeOut: TOAST_IMPORTANT_TIME_OUT_MS });
      }
      abortController.abort();
    }
  };

  const onUpdate = async (values: DriverSchemaType, action: any) => {
    const abortController = new AbortController();
    try {
      enableLoading();
      const valuesCopy = { ...values };
      delete valuesCopy._id;
      delete valuesCopy.number;

      const updatedDriver = {
        ...valuesCopy,
        active: values.active === DriverStatus.ACTIVE,
        transports: values.transports ? values.transports : [],
      };

      const { data } = await apiRequest(`/driver/${values._id}`, {
        method: 'PUT',
        data: JSON.stringify({ ...updatedDriver }),
      });
      const { message } = data;

      if (message) {
        if (Array.isArray(message)) {
          message.forEach((msg) => toastr.error('Error', msg, { timeOut: TOAST_IMPORTANT_TIME_OUT_MS }));
        } else {
          toastr.error('Error', message, { timeOut: TOAST_IMPORTANT_TIME_OUT_MS });
        }
        action.setSubmitting(false);
        return disableLoading();
      }
      disableLoading();
      toastr.success('Success', 'Driver updated');
      navigate(Pages.DRIVER_DETAILS);
    } catch (err) {
      handleError('UPDATE_CUSTOMER', err);
      if (err instanceof DOMException && err.name === 'AbortError') {
        toastr.error('Error', 'Your browser aborted the request', { timeOut: TOAST_IMPORTANT_TIME_OUT_MS });
      } else {
        toastr.error('Error', 'Failed to update driver', { timeOut: TOAST_IMPORTANT_TIME_OUT_MS });
      }
      abortController.abort();
    }
  };

  const onSubmit = async (formik: any) => {
    const formikErrors = await formik.validateForm();
    if (Object.keys(formikErrors).length) {
      formik.setTouched(setNestedObjectValues<FormikTouched<DriverSchemaType>>(formikErrors, true));
      return null;
    }

    if (type === 'new') {
      await onCreate(formik.values, {
        setSubmitting: formik.setSubmitting,
      });
    } else if (type === 'existing') {
      await onUpdate(formik.values, {
        setSubmitting: formik.setSubmitting,
      });
    }
  };

  return (
    <Col className="mh-100 overflow-auto px-3">
      <Row>
        <Col>
          <h3 className="text-secondary mb-3">Driver Details</h3>
        </Col>
        <Col className="d-flex justify-content-end">
          <BackDetailsLink path={Pages.DRIVERS} />
        </Col>
      </Row>
      <Formik enableReinitialize initialValues={values} validationSchema={DriverSchema} onSubmit={onSubmit}>
        {(formik) => (
          <Form id="create-discount" className="d-flex flex-column gap-4">
            <Row>
              <Col>
                <CustomInput
                  label="First Name"
                  inputProps={{
                    id: 'contact.firstName',
                    name: 'contact.firstName',
                    type: 'text',
                    value: formik.values.contact.firstName,
                    onChange: formik.handleChange,
                  }}
                  error={formik.touched.contact?.firstName && formik.errors.contact?.firstName ? formik.errors.contact.firstName : ''}
                />
              </Col>
              <Col>
                <CustomInput
                  label="Last Name"
                  inputProps={{
                    id: 'contact.lastName',
                    name: 'contact.lastName',
                    type: 'text',
                    value: formik.values.contact.lastName,
                    onChange: formik.handleChange,
                  }}
                  error={formik.touched.contact?.lastName && formik.errors.contact?.lastName ? formik.errors.contact.lastName : ''}
                />
              </Col>
            </Row>
            <Row>
              <Col>
                <CustomInput
                  label="Email"
                  inputProps={{
                    id: 'contact.email',
                    name: 'contact.email',
                    type: 'email',
                    value: formik.values.contact.email,
                    onChange: formik.handleChange,
                  }}
                  error={formik.touched.contact?.email && formik.errors.contact?.email ? formik.errors.contact.email : ''}
                />
              </Col>
              <Col>
                <CustomInput
                  label="Phone"
                  inputProps={{
                    id: 'contact.phoneNumber',
                    name: 'contact.phoneNumber',
                    type: 'tel',
                    value: formik.values.contact.phoneNumber,
                    onChange: formik.handleChange,
                  }}
                  error={formik.touched.contact?.phoneNumber && formik.errors.contact?.phoneNumber ? formik.errors.contact.phoneNumber : ''}
                />
              </Col>
              <Col>
                <CustomInput
                  label="Phone #2"
                  inputProps={{
                    id: 'contact.phoneNumber2',
                    name: 'contact.phoneNumber2',
                    type: 'tel',
                    value: formik.values.contact.phoneNumber2,
                    onChange: formik.handleChange,
                  }}
                  error={formik.touched.contact?.phoneNumber2 && formik.errors.contact?.phoneNumber2 ? formik.errors.contact.phoneNumber2 : ''}
                />
              </Col>
            </Row>
            <Row>
              <Col>
                <p className="mb-2">Driver Status</p>
                <List className="p-0 m-0">
                  {Object.values(DriverStatus).map((value: string) => (
                    <ListInlineItem key={value}>
                      <Button
                        color="secondary"
                        className={`btn-md ${value.toLowerCase() === formik.values.active.toLowerCase() ? 'checked' : ''}`}
                        onClick={() => formik.setFieldValue('active', value)}
                      >
                        {value}
                      </Button>
                    </ListInlineItem>
                  ))}
                </List>
              </Col>
            </Row>
            <Row className="mt-5">
              <Col xs={12} md={{ offset: 4, size: 4 }} xxl={{ offset: 5, size: 2 }}>
                <Button color="primary" type="button" className="w-100 btn-md" disabled={loading} onClick={() => onSubmit(formik)}>
                  {type === 'new' ? 'Create' : 'Update'} Driver {loading && <Spinner size="sm" color="secondary" />}
                </Button>
              </Col>
            </Row>
          </Form>
        )}
      </Formik>
    </Col>
  );
};

export { DriverDetails };
