import React, { FC, useRef, useState } from 'react';
import '../styles/ImagesList.scss';
import { getBase64 } from '../../../utils/getBase64';
import { toastr } from 'react-redux-toastr';
import { apiRequest } from '../../../utils';

interface IconsProductListProps {
  icons: any[] | undefined;
  setValues: (values: any) => void;
  imageUrl: string;
}

const IconsProductList: FC<IconsProductListProps> = ({ icons, setValues, imageUrl }) => {
  const [uploading, setUploading] = useState<boolean>(false);
  const fileInputRef = useRef<HTMLInputElement | null>(null);

  const onClickSetImageLink = (link: string) => {
    setValues((prevValues: any) => ({
      ...prevValues,
      imageUrl: link,
    }));
  };

  const onImageUpload = async (file: File, setUploading: (state: boolean) => void) => {
    const abortController = new AbortController();
    try {
      setUploading(true);
      const base64File = (await getBase64(file)) as string;

      if (!base64File) {
        toastr.error('Error', `Error while uploading ${file.name}`);
        setUploading(false);
        return;
      }

      const { data } = await apiRequest(`/product/upload-image`, {
        method: 'POST',
        data: JSON.stringify({ file: base64File, filename: file.name, filetype: file.type }),
        signal: abortController.signal,
      });

      const { message, imageUrl } = data;

      if (message) {
        if (Array.isArray(message)) {
          message.forEach((msg) => toastr.error('Error', msg));
        } else {
          toastr.error('Error', message);
        }
        setUploading(false);
        return;
      }

      toastr.success('Success', 'Image uploaded successfully');
      const updatedIcons = icons ? [...icons, { url: imageUrl }] : [{ url: imageUrl }];
      setValues((prevValues: any) => ({
        ...prevValues,
        icons: updatedIcons,
      }));

      setUploading(false);
    } catch (err) {
      console.error('UPLOAD_CRAFTSMAN_IMAGE error', err);
      if (err instanceof DOMException && err.name === 'AbortError') {
        toastr.error('Error', 'Your browser aborted the request');
      } else {
        toastr.error('Error', 'Failed to upload image');
      }
      abortController.abort();
      setUploading(false);
    }
  };

  const handleFileSelection = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];

    if (file) {
      await onImageUpload(file, setUploading);
    } else {
      toastr.error('Error', 'No file selected');
    }
  };

  const triggerFileInput = () => {
    fileInputRef.current?.click();
  };

  return (
    <div>
      <h6>Icons List</h6>
      <ul className="icon-list">
        {icons?.map((icon, index) => (
          <li key={index} onClick={() => onClickSetImageLink(icon.url)} className={icon.url === imageUrl ? 'active-icon' : ''}>
            <img src={icon.url} alt="product-icon" className="icon-image" />
          </li>
        ))}
      </ul>
      <input ref={fileInputRef} type="file" style={{ display: 'none' }} onChange={handleFileSelection} />
      <button type="button" className="add-icon-button" onClick={triggerFileInput} disabled={uploading}>
        {uploading ? 'Uploading...' : 'Add Icon'}
      </button>
    </div>
  );
};

export default IconsProductList;
