import React, { createContext, useContext, useState } from 'react';
import { Craftsman, Place, LatLngLiteral } from '../../types';

const FilteredPlaceListContext = createContext<{
  filteredPlaceList: { place: Place; craftsman: Craftsman }[];
  setFilteredPlaceList: React.Dispatch<React.SetStateAction<{ place: Place; craftsman: Craftsman }[]>>;
  map: any;
  setMap: React.Dispatch<any>;
} | null>(null);

export const useFilteredPlaceList = () => {
  const context = useContext(FilteredPlaceListContext);
  if (!context) {
    throw new Error('useFilteredPlaceList must be used within a FilteredPlaceListProvider');
  }
  return context;
};

export const FilteredPlaceListProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [filteredPlaceList, setFilteredPlaceList] = useState<{ place: Place; craftsman: Craftsman }[]>([]);
  const [map, setMap] = useState<any | null>(null);

  return <FilteredPlaceListContext.Provider value={{ filteredPlaceList, setFilteredPlaceList, map, setMap }}>{children}</FilteredPlaceListContext.Provider>;
};
