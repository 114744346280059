import React, { useState } from 'react';
import { Button, Col, Collapse, Row } from 'reactstrap';
import { Order } from '../../../../types';
import { ArrowDropDown } from '@styled-icons/material/ArrowDropDown';
import StarRating from '../../components/StarRating';

const ServiceCell = ({ order }: ServiceCellProps) => {
  const [open, setOpen] = useState(false);

  return (
    <Row className="gx-0 w-100">
      <div>
        {order.rating[0] && (
          <>
            <Row className="gx-0">
              <Button color="primary" onClick={() => setOpen(!open)}>
                {!!order.rating[0].serviceRating && <p>Repair Rebels Rank</p>}
                <ArrowDropDown className="icon" />
              </Button>
            </Row>
            <Row className="gx-0 my-2">
              <Collapse isOpen={open}>
                <Col className="d-flex flex-column gap-2">
                  <Col className="d-flex align-items-center gap-2">
                    {!!order.rating[0].serviceRating && (
                      <>
                        <p>Rank: </p>
                        <StarRating rating={order.rating[0].serviceRating} />
                      </>
                    )}
                  </Col>
                  {!!order.rating[0].serviceComment && (
                    <p>
                      <span>Comment: </span>
                      <span>{order.rating[0].serviceComment}</span>
                    </p>
                  )}
                </Col>
              </Collapse>
            </Row>
          </>
        )}
      </div>
    </Row>
  );
};
type ServiceCellProps = {
  order: Order;
};

export { ServiceCell };
