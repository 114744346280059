enum AdminAuthRole {
  NONE = 'NONE',
  ADMIN = 'ADMIN',
  SHOPKEEPER = 'SHOPKEEPER',
  DRIVER = 'DRIVER',
  CRAFTSMEN = 'CRAFTSMEN',
}

enum CustomerAuthRole {
  CUSTOMER = 'CUSTOMER',
  GUEST = 'GUEST',
}

export { AdminAuthRole, CustomerAuthRole };
