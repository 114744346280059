import { Col, Row } from 'reactstrap';
import { CustomInput } from '../Input/CustomInput';
import { resolveArrayItemKey } from '../../utils';

const CreateCustomerAddressForm = ({ formik, isViewOnly = false, field }: CreateCustomerAddressFormProps) => {
  return (
    <div>
      <Row>
        <Col md={6} className="mb-2">
          <CustomInput
            label="City*"
            inputProps={{
              id: `${[field]}.city`,
              name: `${[field]}.city`,
              type: 'text',
              value: resolveArrayItemKey(`${field}.city`, formik.values),
              onChange: formik.handleChange,
              disabled: isViewOnly,
            }}
            error={
              resolveArrayItemKey(`${field}.city`, formik.touched) && resolveArrayItemKey(`${field}.city`, formik.errors)
                ? resolveArrayItemKey(`${field}.city`, formik.errors)
                : ''
            }
          />
        </Col>
        <Col md={6} className="mb-2">
          <CustomInput
            label="Country*"
            inputProps={{
              id: `${[field]}.country`,
              name: `${[field]}.country`,
              type: 'text',
              value: resolveArrayItemKey(`${field}.country`, formik.values),
              onChange: formik.handleChange,
              disabled: isViewOnly,
            }}
            error={
              resolveArrayItemKey(`${field}.country`, formik.touched) && resolveArrayItemKey(`${field}.country`, formik.errors)
                ? resolveArrayItemKey(`${field}.country`, formik.errors)
                : ''
            }
          />
        </Col>
      </Row>
      <Row className="mb-2">
        <Col md={6}>
          <CustomInput
            label="Street*"
            inputProps={{
              id: `${[field]}.street`,
              name: `${[field]}.street`,
              type: 'text',
              value: resolveArrayItemKey(`${field}.street`, formik.values),
              onChange: formik.handleChange,
              disabled: isViewOnly,
            }}
            error={
              resolveArrayItemKey(`${field}.street`, formik.touched) && resolveArrayItemKey(`${field}.street`, formik.errors)
                ? resolveArrayItemKey(`${field}.street`, formik.errors)
                : ''
            }
          />
        </Col>
        <Col md={6} className="mb-2">
          <CustomInput
            label="Zip Code*"
            inputProps={{
              id: `${[field]}.zipCode`,
              name: `${[field]}.zipCode`,
              type: 'text',
              value: resolveArrayItemKey(`${field}.zipCode`, formik.values),
              onChange: formik.handleChange,
              disabled: isViewOnly,
            }}
            error={
              resolveArrayItemKey(`${field}.zipCode`, formik.touched) && resolveArrayItemKey(`${field}.zipCode`, formik.errors)
                ? resolveArrayItemKey(`${field}.zipCode`, formik.errors)
                : ''
            }
          />
        </Col>
      </Row>
    </div>
  );
};

type CreateCustomerAddressFormProps = {
  formik: any;
  isViewOnly?: boolean;
  field: string;
};

export { CreateCustomerAddressForm };
