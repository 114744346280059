import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { initialState } from './initial-state';
import { extractStrictly } from '../../../../utils';
import { CraftsmanState } from './type';

const extractCraftsmanStateSpecific = extractStrictly<CraftsmanState>({
  selectedCraftsmanId: true,
});
export const CraftsmanSlice = createSlice({
    name: 'Craftsman Object',
    initialState,
    reducers: {
      setCraftsmanData: (state: CraftsmanState, action:PayloadAction<CraftsmanState>) => {
        return extractCraftsmanStateSpecific(action.payload);
      },
    },
});
