import { useEffect, useState } from 'react';
import { Button, Col, Modal, ModalBody, ModalHeader, Row } from 'reactstrap';
import { toastr } from 'react-redux-toastr';
import { Times as TimesIcon } from '@styled-icons/typicons/Times';
import { apiRequest, handleError, TOAST_IMPORTANT_TIME_OUT_MS } from '../../../utils';
import { AssignedOrderWithCustomer } from '../../../types';
import './ShippingDetails.scss';
import Table from '../../Orders/OrderDetails/components/Table';
import { orderColumns, contactInformation, orderVoucherDiscountColumns, orderDiscountColumns } from './components/columns.tsx';
import { Divider } from '../../Orders/OrderDetails/components/Divider/Divider';
import RepairDetails from '../../Orders/OrderDetails/components/RepairDetails/RepairDetails';

const ShippingDetails = ({ _id, toggle }: ShippingDetailsProps) => {
  const [loading, setLoading] = useState(false);
  const [order, setOrder] = useState<AssignedOrderWithCustomer>();

  const orderTableColumns = orderColumns();
  const orderContactColumns = contactInformation();
  const orderVoucherDiscountTableColumns = orderVoucherDiscountColumns();
  const orderDiscountTableColumns = orderDiscountColumns();

  useEffect(() => {
    onLoad();
  }, [_id]);

  const onLoad = async () => {
    const abortController = new AbortController();
    try {
      enableLoading();
      const { data } = await apiRequest(`/shipping-order/by-id?orderId=${_id}`, { signal: abortController.signal });
      const { data: customerResponse } = await apiRequest('customer', { signal: abortController.signal });
      const { items } = customerResponse;
      const foundCustomer = items.find((item: any) => item._id === data.customerId);
      setOrder({ ...data, customer: foundCustomer });
      const { message } = data;

      if (message) {
        if (Array.isArray(message)) {
          message.forEach((msg) => toastr.error('Error', msg, { timeOut: TOAST_IMPORTANT_TIME_OUT_MS }));
        } else {
          toastr.error('Error', message, { timeOut: TOAST_IMPORTANT_TIME_OUT_MS });
        }
        abortController.abort();
        return disableLoading();
      }
      disableLoading();
    } catch (err) {
      handleError('FETCH_SHIPPING_ORDER', err);
      if (err instanceof DOMException && err.name === 'AbortError') {
        toastr.error('Error', 'Your browser aborted the request', { timeOut: TOAST_IMPORTANT_TIME_OUT_MS });
      } else {
        toastr.error('Error', 'Failed to fetch customer by ID', { timeOut: TOAST_IMPORTANT_TIME_OUT_MS });
      }
      abortController.abort();
    }
  };

  const enableLoading = () => {
    setLoading(true);
  };
  const disableLoading = () => {
    setLoading(false);
  };

  return (
    <Modal isOpen={!!_id} toggle={toggle} centered backdrop size="xl" fullscreen scrollable>
      <ModalHeader
        toggle={toggle}
        close={
          <Button className="btn-icon" onClick={toggle}>
            <TimesIcon className="icon" />
          </Button>
        }
        tag="div"
      >
        <h5 className="list-header">Shipping Order Details</h5>
      </ModalHeader>
      <ModalBody>
        {order && (
          <>
            <Row className="justify-content-between gx-0 gap-3 flex-nowrap pr-3">
              <Col md={6}>
                <h5 className="mb-3">General Information</h5>
                <Table columns={orderTableColumns} data={[order]} />
                <Col>
                  <h5 className="mb-3">Discounts & Vouchers</h5>
                  <Table columns={orderVoucherDiscountTableColumns} data={[order]} className="h-auto" valueColumn />
                  <Table columns={orderDiscountTableColumns} data={[order]} className="h-auto" valueColumn />
                </Col>
              </Col>
              <Col md={6}>
                <h5 className="mb-3">Contact Information</h5>
                {order.customer.email && <Table columns={orderContactColumns} data={[order]} />}
              </Col>
            </Row>
          </>
        )}
        {!order && <h1 className="mb-5 text-danger text-wrap">Order or customer details are loading or not found.</h1>}
        <Divider />
        <div>
          <h5 className="mt-5 mb-3">Repairs</h5>
          <div className="d-flex flex-column gap-3">
            {!order?.repairList || (!order?.repairList.length && "No repairs or can't load repair details")}
            {order?.repairList && !!order?.repairList.length && order?.repairList.map((repair) => <RepairDetails key={repair._id} repair={repair} />)}
          </div>
        </div>
      </ModalBody>
    </Modal>
  );
};

type ShippingDetailsProps = {
  _id: AssignedOrderWithCustomer['_id'];
  toggle: () => void;
};

export { ShippingDetails };
