import { Order, OrderDiscount, Product, Repair, ShippingOrder } from '../../types';
import { DiscountType } from '../../enums';

// const TAX_PERCENTAGE = 0.19;
// number of fraction digits
const PRECISION = 2;
const FREE_DELIVERY_MIN_PRICE = 125;

const precisionRound = (number: number, precision: number) => {
  const factor = Math.pow(10, precision);
  return Math.round(number * factor) / factor;
};

const toPriceString = (price: number) => {
  return price.toLocaleString('de-DE', {
    minimumIntegerDigits: 1,
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
    useGrouping: false,
  });
};

const getDiscount = (discount?: OrderDiscount, totalPrice?: number) => {
  if (!discount || !totalPrice) {
    return 0;
  }

  if (discount.type === DiscountType.PERCENTAGE && discount.percent) {
    return totalPrice * (discount.percent / 100);
  }

  if (discount.type === DiscountType.MONETARY && discount.value) {
    return discount.value;
  }
  return 0;
};

const getDiscountText = (discount?: OrderDiscount, totalPrice?: number) => {
  if (!discount || !totalPrice) {
    return '-';
  }

  if (discount.type === DiscountType.PERCENTAGE && discount.percent) {
    return `-${toPriceString(precisionRound(totalPrice * (discount.percent / 100), PRECISION))}`;
  }

  if (discount.type === DiscountType.MONETARY && discount.value) {
    return `-${toPriceString(totalPrice - discount.value)}`;
  }
  return '-';
};

const getDeliveryPrice = (totalPrice: number, deliveryPrice: number) => {
  return totalPrice >= FREE_DELIVERY_MIN_PRICE ? 0 : deliveryPrice / 100;
};

const calculatePriceForOrderGarmentItem = (product: Product, value: number) => {
  if (product?.input && product?.input.type === 'QUANTITY') {
    return (product?.priceCent * value) / 100;
  }
  return product?.priceCent / 100;
};

const totalListPrice = (repairList: Repair[]) => {
  let total = 0;
  repairList.map(async ({ product, inputValue }) => {
    if (!inputValue) {
      total += calculatePriceForOrderGarmentItem(product, 1);
    } else {
      total += calculatePriceForOrderGarmentItem(product, inputValue);
    }
  });

  return total;
};

const getOrderPrices = (order: Order) => {
  if (!order.repairList.length) {
    return { priceWithTax: null };
  }

  const totalPrice = precisionRound(totalListPrice(order.repairList), PRECISION);
  const discountPriceText = getDiscountText(order.discount, totalPrice);
  const discountPrice = getDiscount(order.discount, totalPrice);
  const deliveryPrice = getDeliveryPrice(totalPrice, order.courierPriceCent);
  const boxPrice = (order.boxCount * order.boxPriceCent) / 100;
  const priceWithTax = precisionRound(totalPrice + boxPrice + deliveryPrice - discountPrice, PRECISION);

  return { totalPrice, discountPrice, discountPriceText, deliveryPrice, boxPrice, priceWithTax };
};

const getShippingOrderPrices = (order: ShippingOrder) => {
  if (!order.repairList.length) {
    return { priceWithTax: null };
  }

  const totalPrice = precisionRound(totalListPrice(order.repairList), PRECISION);
  const discountPriceText = getDiscountText(order.discount, totalPrice);
  const discountPrice = getDiscount(order.discount, totalPrice);
  const deliveryPrice = getDeliveryPrice(totalPrice, order.shippingExemptionThreshold);
  const priceWithTax = precisionRound(totalPrice + deliveryPrice - discountPrice, PRECISION);

  return { totalPrice, discountPrice, discountPriceText, deliveryPrice, priceWithTax };
};

const renderRepairMeasure = (repair: Repair) => {
  let result = '';

  if (repair.product.input && repair.product.input.type) {
    result += `${toSentenceCase(repair.product.input.type)}: `;
  } else {
    result += 'Quantity: ';
  }

  result += repair.inputValue ? repair.inputValue : '-';

  if (repair.product.input && repair.product.input.unit) {
    result += ` ${repair.product.input.unit}`;
  }

  return result;
};

const toSentenceCase = (word: string) => {
  const firstLetter = word[0];

  return `${firstLetter.toUpperCase()}${word.slice(1).toLowerCase()}`;
};

export { getShippingOrderPrices, getOrderPrices, renderRepairMeasure, toSentenceCase };
