import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { initialState } from './initial-state';
import { OrderState } from './type';
import { extractStrictly } from '../../../../utils';

const extractOrderStateSpecific = extractStrictly<OrderState>({
  selectedOrderId: true,
});
export const OrderSlice = createSlice({
  name: 'Order Object',
  initialState,
  reducers: {
    setOrderData: (state: OrderState, action: PayloadAction<OrderState>) => {
      return extractOrderStateSpecific(action.payload);
    },
  },
});
