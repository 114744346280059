import { ChangeEvent, useEffect, useState } from 'react';
import { Button, Card, Col, Collapse, FormFeedback, Input, Label, List, ListGroupItem } from 'reactstrap';
import { toastr } from 'react-redux-toastr';
import { Customer } from '../../../../types';
import { apiRequest, handleError, TOAST_IMPORTANT_TIME_OUT_MS } from '../../../../utils';
import './CustomerSearchForm.scss';

const CustomerSearchForm = ({ formik, selectedCustomerId, onCustomerSelect, disabled, setCustomer }: CustomerSearchFormProps) => {
  const [customers, setCustomers] = useState<Customer[]>([]);
  const [isOpen, setIsOpen] = useState(false);

  const toggle = () => setIsOpen(!isOpen);
  const formatCustomerData = ({ firstName, lastName, email, phoneNumber, active }: Customer) => {
    return `${firstName || 'Not provided'} ${lastName || 'Not provided'}, ${email || 'Not provided'}, ${phoneNumber || 'Not provided'}, ${
      active ? 'is active' : 'deactivated'
    }`;
  };
  const formatSelectedCustomer = () => {
    const customer = customers.find(({ _id }) => _id === selectedCustomerId);

    if (customer) {
      return formatCustomerData(customer);
    }

    return 'Select a customer*';
  };

  const searchCustomers = async (event?: ChangeEvent<HTMLInputElement>) => {
    const abortController = new AbortController();
    let value = '';

    if (event) {
      value = event.target.value;
    }

    const { data: responseData } = await apiRequest(`customer/search?search=${value}`, {
      signal: abortController.signal,
    });
    const { message, data } = responseData;

    if (message) {
      if (Array.isArray(message)) {
        message.forEach((msg) => toastr.error('Error', msg, { timeOut: TOAST_IMPORTANT_TIME_OUT_MS }));
      } else {
        toastr.error('Error', message, { timeOut: TOAST_IMPORTANT_TIME_OUT_MS });
      }
      abortController.abort();
    }

    setCustomers(data);
  };

  useEffect(() => {
    const customer = customers.find(({ _id }) => _id === selectedCustomerId);
    if (customer && setCustomer) {
      setCustomer({ name: customer.firstName, lastName: customer.lastName });
    }
  }, [selectedCustomerId, customers, setCustomer]);

  useEffect(() => {
    try {
      searchCustomers();
    } catch (err) {
      handleError('CUSTOMER_SEARCH', err);
    }
  }, []);

  return (
    <div className="d-flex flex-column">
      <Label>Select a customer*</Label>
      <div>
        <Button
          color="secondary"
          onClick={toggle}
          className={`d-flex dropdown-toggle justify-content-between align-items-center w-100 form-control ${
            formik.touched.customerId && formik.errors.customerId ? 'border-danger is-invalid' : ''
          }`}
          disabled={disabled}
        >
          <span className="text-start w-75 customer-details">{formatSelectedCustomer()}</span>
        </Button>
      </div>
      <div>
        <Collapse isOpen={isOpen}>
          <Col className="d-flex flex-column gap-2">
            <Input type="search" name="search" onChange={searchCustomers} placeholder="Search a customer..." className="mt-2" />
            {customers && !!customers.length && (
              <Card>
                <Col>
                  <List className="py-2 px-0 m-0">
                    {customers.map((customer) => (
                      <ListGroupItem
                        key={customer._id}
                        onClick={() => {
                          onCustomerSelect(customer);
                          toggle();
                        }}
                        className="customer-search-item customer-details"
                        role="button"
                      >
                        {formatCustomerData(customer)}
                      </ListGroupItem>
                    ))}
                  </List>
                </Col>
              </Card>
            )}
          </Col>
        </Collapse>
      </div>
      {!!formik.touched.customerId && formik.errors.customerId && (
        <FormFeedback className="d-block" invalid>
          {formik.errors.customerId.toString()}
        </FormFeedback>
      )}
    </div>
  );
};

type CustomerSearchFormProps = {
  selectedCustomerId: Customer['_id'];
  onCustomerSelect: (customer: Customer) => void;
  disabled: boolean;
  formik: any;
  setCustomer?: (customer: { name: string; lastName: string }) => void;
};

export { CustomerSearchForm };
