import { useEffect, useState } from 'react';
import { Button, Col, Form, List, ListInlineItem, Row, Spinner } from 'reactstrap';
import { toastr } from 'react-redux-toastr';
import { FieldArray, Formik, FormikTouched, setNestedObjectValues } from 'formik';
import { useNavigate, useParams } from 'react-router';
import * as yup from 'yup';
import { apiRequest, handleError, TOAST_IMPORTANT_TIME_OUT_MS } from '../../utils';
import { CustomInput } from '../../components/Input/CustomInput';
import { CustomerAuthRole, Pages, SupportedLanguages } from '../../enums';
import Divider from '../../components/Divider';
import { Delete as DeleteIcon } from '@styled-icons/fluentui-system-filled/Delete';
import { initialCustomerAddress } from '../../types';
import { CreateCustomerAddressForm } from '../../components/Forms/CreateCustomerAddressForm';
import BackDetailsLink from '../../components/BackDetailsLink/BackDetailsLink';

type CustomerDetailsProps = {
  type?: 'new' | 'existing';
};

const CustomerSchema = yup.object({
  _id: yup.string().optional().nullable(),
  number: yup.string().optional().nullable(),
  email: yup.string().required('This field is required.'),
  phoneNumber: yup.string().required('This field is required.').matches(/^\+49/, 'Only German phone numbers - +49'),
  firstName: yup.string().required('This field is required.'),
  lastName: yup.string().required('This field is required.'),
  preferredLanguage: yup.mixed<SupportedLanguages>().oneOf(Object.values(SupportedLanguages)).required('This field is required.'),
  anonymous: yup.boolean().default(false),
  addressList: yup.array().of(
    yup.object({
      city: yup.string().required('This field is required.'),
      country: yup.string().required('This field is required.'),
      street: yup.string().required('This field is required.'),
      zipCode: yup.string().required('This field is required.').matches(/^\d+$/, 'This field must be numerical'),
    })
  ),
  active: yup.boolean().default(false),
  emailVerified: yup.boolean().default(false),
  emailVerificationDate: yup.date().nullable(),
  phoneVerified: yup.boolean().default(false),
  phoneVerificationDate: yup.date().nullable(),
});
type CustomerSchemaType = yup.InferType<typeof CustomerSchema>;

const CustomerDetails = ({ type = 'new' }: CustomerDetailsProps) => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [values, setValues] = useState<CustomerSchemaType>({
    _id: '',
    number: '',
    email: '',
    phoneNumber: '',
    firstName: '',
    lastName: '',
    preferredLanguage: SupportedLanguages.de,
    anonymous: false,
    addressList: [],
    active: true,
    emailVerified: false,
    emailVerificationDate: new Date(),
    phoneVerified: false,
    phoneVerificationDate: new Date(),
  });
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (type === 'new') {
      return;
    }
    onLoad();
  }, [id]);

  const enableLoading = () => {
    setLoading(true);
  };
  const disableLoading = () => {
    setLoading(false);
  };

  const onLoad = async () => {
    const abortController = new AbortController();
    try {
      enableLoading();
      const { data } = await apiRequest(`/customer/get/${id}`, { signal: abortController.signal });
      const { message } = data;

      if (message) {
        if (Array.isArray(message)) {
          message.forEach((msg) => toastr.error('Error', msg, { timeOut: TOAST_IMPORTANT_TIME_OUT_MS }));
        } else {
          toastr.error('Error', message, { timeOut: TOAST_IMPORTANT_TIME_OUT_MS });
        }
        abortController.abort();
        return disableLoading();
      }
      setValues(data);
      disableLoading();
    } catch (err) {
      handleError('FETCH_CUSTOMER', err);
      if (err instanceof DOMException && err.name === 'AbortError') {
        toastr.error('Error', 'Your browser aborted the request', { timeOut: TOAST_IMPORTANT_TIME_OUT_MS });
      } else {
        toastr.error('Error', 'Failed to fetch customer by ID', { timeOut: TOAST_IMPORTANT_TIME_OUT_MS });
      }
      abortController.abort();
    }
  };

  const onCreate = async (values: CustomerSchemaType, action: any) => {
    const abortController = new AbortController();
    try {
      enableLoading();
      const valuesCopy = { ...values };
      delete valuesCopy._id;
      delete valuesCopy.number;
      const { data } = await apiRequest('/customer/admin', {
        method: 'POST',
        data: JSON.stringify({ ...valuesCopy, role: valuesCopy.anonymous ? CustomerAuthRole.GUEST : CustomerAuthRole.CUSTOMER }),
      });
      const { message } = data;

      if (message) {
        if (Array.isArray(message)) {
          message.forEach((msg) => toastr.error('Error', msg, { timeOut: TOAST_IMPORTANT_TIME_OUT_MS }));
        } else {
          toastr.error('Error', message, { timeOut: TOAST_IMPORTANT_TIME_OUT_MS });
        }
        action.setSubmitting(false);
        abortController.abort();
        return disableLoading();
      }
      disableLoading();
      toastr.success('Success', 'Customer created');
      navigate(Pages.CUSTOMERS);
    } catch (err) {
      handleError('CREATE_CUSTOMER', err);
      if (err instanceof DOMException && err.name === 'AbortError') {
        toastr.error('Error', 'Your browser aborted the request', { timeOut: TOAST_IMPORTANT_TIME_OUT_MS });
      } else {
        toastr.error('Error', 'Failed to create customer', { timeOut: TOAST_IMPORTANT_TIME_OUT_MS });
      }
      abortController.abort();
    }
  };

  const onUpdate = async (values: CustomerSchemaType, action: any) => {
    const abortController = new AbortController();
    try {
      enableLoading();
      const valuesCopy = { ...values };
      delete valuesCopy._id;
      delete valuesCopy.number;
      const { data } = await apiRequest(`/customer/admin/${values._id}`, {
        method: 'PATCH',
        data: JSON.stringify({ ...valuesCopy, role: valuesCopy.anonymous ? CustomerAuthRole.GUEST : CustomerAuthRole.CUSTOMER }),
      });
      const { message } = data;

      if (message) {
        if (Array.isArray(message)) {
          message.forEach((msg) => toastr.error('Error', msg, { timeOut: TOAST_IMPORTANT_TIME_OUT_MS }));
        } else {
          toastr.error('Error', message, { timeOut: TOAST_IMPORTANT_TIME_OUT_MS });
        }
        action.setSubmitting(false);
        return disableLoading();
      }
      disableLoading();
      toastr.success('Success', 'Customer updated');
      navigate(`/customers/${id}`);
    } catch (err) {
      handleError('UPDATE_CUSTOMER', err);
      if (err instanceof DOMException && err.name === 'AbortError') {
        toastr.error('Error', 'Your browser aborted the request', { timeOut: TOAST_IMPORTANT_TIME_OUT_MS });
      } else {
        toastr.error('Error', 'Failed to update customer', { timeOut: TOAST_IMPORTANT_TIME_OUT_MS });
      }
      abortController.abort();
    }
  };

  const onSubmit = async (formik: any) => {
    const formikErrors = await formik.validateForm();
    if (Object.keys(formikErrors).length) {
      formik.setTouched(setNestedObjectValues<FormikTouched<CustomerSchemaType>>(formikErrors, true));
      return null;
    }

    if (type === 'new') {
      await onCreate(formik.values, {
        setSubmitting: formik.setSubmitting,
      });
    } else if (type === 'existing') {
      await onUpdate(formik.values, {
        setSubmitting: formik.setSubmitting,
      });
    }
  };

  return (
    <Col className="mh-100 overflow-auto px-3">
      <Row>
        <Col>
          <h3 className="text-secondary mb-3">Customer Details</h3>
        </Col>
        <Col className="d-flex justify-content-end">
          <BackDetailsLink path={Pages.CUSTOMERS} />
        </Col>
      </Row>
      <Formik enableReinitialize initialValues={values} validationSchema={CustomerSchema} onSubmit={onSubmit}>
        {(formik) => (
          <Form id="create-product" className="d-flex flex-column gap-4">
            <Row>
              <h6>General Information</h6>
            </Row>
            <Row>
              <Col>
                <CustomInput
                  label="Email"
                  inputProps={{
                    id: 'email',
                    name: 'email',
                    type: 'email',
                    value: formik.values.email,
                    onChange: formik.handleChange,
                  }}
                  error={formik.touched.email && formik.errors.email ? formik.errors.email : ''}
                />
              </Col>
              <Col>
                <CustomInput
                  label="Phone Number"
                  inputProps={{
                    id: 'phoneNumber',
                    name: 'phoneNumber',
                    type: 'tel',
                    value: formik.values.phoneNumber,
                    onChange: formik.handleChange,
                  }}
                  error={formik.touched.phoneNumber && formik.errors.phoneNumber ? formik.errors.phoneNumber : ''}
                />
              </Col>
            </Row>
            <Row>
              <Col>
                <CustomInput
                  label="First Name"
                  inputProps={{
                    id: 'firstName',
                    name: 'firstName',
                    type: 'text',
                    value: formik.values.firstName,
                    onChange: formik.handleChange,
                  }}
                  error={formik.touched.firstName && formik.errors.firstName ? formik.errors.firstName : ''}
                />
              </Col>
              <Col>
                <CustomInput
                  label="Last Name"
                  inputProps={{
                    id: 'lastName',
                    name: 'lastName',
                    type: 'text',
                    value: formik.values.lastName,
                    onChange: formik.handleChange,
                  }}
                  error={formik.touched.lastName && formik.errors.lastName ? formik.errors.lastName : ''}
                />
              </Col>
            </Row>
            <Divider />

            <Row>
              <Col>
                <p className="mb-2">Preferred Language</p>
                <List className="p-0">
                  {Object.values(SupportedLanguages).map((val) => (
                    <ListInlineItem key={val}>
                      <Button
                        className={`btn-md ${val.toLowerCase() === formik.values.preferredLanguage.toLowerCase() ? 'checked' : ''}`}
                        onClick={() => formik.setFieldValue('preferredLanguage', val)}
                      >
                        {val.toUpperCase()}
                      </Button>
                    </ListInlineItem>
                  ))}
                </List>
              </Col>
              <Col>
                <p className="mb-2">Is Anonymous?</p>
                <List className="p-0">
                  <ListInlineItem>
                    <Button className={`btn-md ${!formik.values.anonymous ? 'checked' : ''}`} onClick={() => formik.setFieldValue('anonymous', false)}>
                      Authorized
                    </Button>
                  </ListInlineItem>
                  <ListInlineItem>
                    <Button className={`btn-md ${formik.values.anonymous ? 'checked' : ''}`} onClick={() => formik.setFieldValue('anonymous', true)}>
                      Anonymous
                    </Button>
                  </ListInlineItem>
                </List>
              </Col>
              <Col>
                <p className="mb-2">Is Blocked?</p>
                <List className="p-0">
                  <ListInlineItem>
                    <Button className={`btn-md ${formik.values.active ? 'checked' : ''}`} onClick={() => formik.setFieldValue('active', true)}>
                      Active
                    </Button>
                  </ListInlineItem>
                  <ListInlineItem>
                    <Button className={`btn-md ${!formik.values.active ? 'checked' : ''}`} onClick={() => formik.setFieldValue('active', false)}>
                      Blocked
                    </Button>
                  </ListInlineItem>
                </List>
              </Col>
            </Row>
            <Divider />
            <Row>
              <h6>Address List</h6>
            </Row>
            <FieldArray
              name="addressList"
              render={(arrayHelpers) => (
                <>
                  {formik.values.addressList &&
                    !!formik.values.addressList.length &&
                    formik.values.addressList.map((address, index) => (
                      <div key={`address-${index}`}>
                        <Row>
                          <Col>
                            <p className="mt-1 mb-2 fw-normal">Address #{index + 1}</p>
                          </Col>
                          {/*@ts-ignore*/}
                          {formik.values.addressList.length > 1 && (
                            <Col md={2} className="d-flex justify-content-end">
                              <Button type="button" color="danger" onClick={() => arrayHelpers.remove(index)}>
                                <DeleteIcon className="icon danger" />
                              </Button>
                            </Col>
                          )}
                        </Row>
                        <CreateCustomerAddressForm formik={formik} field={`addressList[${index}]`} />
                      </div>
                    ))}
                  <Row className="justify-content-center">
                    <Col md={6} xxl={3} className="d-flex justify-content-center">
                      <Button type="button" className="w-100 btn-md" onClick={() => arrayHelpers.push(initialCustomerAddress)}>
                        Add an address
                      </Button>
                    </Col>
                  </Row>
                </>
              )}
            />
            <Row className="mt-5">
              <Col xs={12} md={{ offset: 4, size: 4 }} xxl={{ offset: 5, size: 2 }}>
                <Button color="primary" type="button" className="w-100 btn-md" disabled={loading} onClick={() => onSubmit(formik)}>
                  {type === 'new' ? 'Create' : 'Update'} Customer {loading && <Spinner size="sm" color="secondary" />}
                </Button>
              </Col>
            </Row>
          </Form>
        )}
      </Formik>
    </Col>
  );
};

export { CustomerDetails };
