import { createColumnHelper } from '@tanstack/react-table';
import moment from 'moment';
import { CraftsmanCell } from './CraftsmanCell';
import { DriverCell } from './DriverCell';
import { AssignedOrder, Customer } from '../../../../types';
import { ORDER_DETAILS_DATE_FORMAT } from '../../../../utils';
import { DateType, DeliveryTimeFrameTimeframes, OrderTimeframe, PickUpTimeFrameTimeframes } from '../../../../enums';
import { ServiceCell } from './ServiceCell';

const columnHelper = createColumnHelper<AssignedOrder & { customer: Customer }>();

const formatTime = (timeframe: OrderTimeframe, type: DateType) => {
  const { rangeMin, rangeMax } = type === 'delivery' ? DeliveryTimeFrameTimeframes[timeframe] : PickUpTimeFrameTimeframes[timeframe];
  return `${rangeMin}:00-${rangeMax}:00`;
};

const orderColumns = () => [
  columnHelper.accessor('number', {
    cell: (info) => info.getValue(),
    header: () => <span>Number</span>,
    enableColumnFilter: false,
    enableSorting: false,
  }),
  columnHelper.accessor('createdAt', {
    cell: (info) => moment(info.getValue()).format(ORDER_DETAILS_DATE_FORMAT),
    header: () => <span>Created at</span>,
    enableColumnFilter: false,
    enableSorting: false,
  }),
  columnHelper.accessor('pickupTime.date', {
    cell: (info) => moment(info.getValue()).format(ORDER_DETAILS_DATE_FORMAT),
    header: () => <span>Pickup time - Date</span>,
    enableColumnFilter: false,
    enableSorting: false,
  }),
  columnHelper.accessor('pickupTime.timeframe', {
    cell: (info) => formatTime(info.getValue(), DateType.PICK_UP),
    header: () => <span>Pickup time - Time</span>,
    enableColumnFilter: false,
    enableSorting: false,
  }),
  columnHelper.accessor('deliveryTime.date', {
    cell: (info) => moment(info.getValue()).format(ORDER_DETAILS_DATE_FORMAT),
    header: () => <span>Delivery time - Date</span>,
    enableColumnFilter: false,
    enableSorting: false,
  }),
  columnHelper.accessor('deliveryTime.timeframe', {
    cell: (info) => formatTime(info.getValue(), DateType.DELIVERY),
    header: () => <span>Delivery time - Time</span>,
    enableColumnFilter: false,
    enableSorting: false,
  }),
  columnHelper.accessor('boxCount', {
    cell: (info) => info.getValue(),
    header: () => <span>Box count</span>,
    enableColumnFilter: false,
    enableSorting: false,
  }),
  columnHelper.accessor('city', {
    cell: (info) => info.getValue(),
    header: () => <span>City</span>,
    enableColumnFilter: false,
    enableSorting: false,
  }),
  columnHelper.accessor('status', {
    cell: (info) => info.getValue(),
    header: () => <span>Status</span>,
    enableColumnFilter: false,
    enableSorting: false,
  }),
  columnHelper.accessor('paymentParams.method', {
    cell: (info) => info.getValue(),
    header: () => <span>Payment method</span>,
    enableColumnFilter: false,
    enableSorting: false,
  }),
  columnHelper.accessor('paymentParams.status', {
    cell: (info) => info.getValue(),
    header: () => <span>Payment status</span>,
    enableColumnFilter: false,
    enableSorting: false,
  }),
  columnHelper.accessor('paymentParams.referenceNumber', {
    cell: (info) => info.getValue(),
    header: () => <span>Payment ref. number</span>,
    enableColumnFilter: false,
    enableSorting: false,
  })
];

const orderVoucherDiscountColumns = () => [
  columnHelper.accessor('voucher', {
    cell: (info) => (info.row.original.voucher?.code ? info.row.original.voucher?.code : ''),
    header: () => <span>Voucher Code</span>,
    enableColumnFilter: false,
    enableSorting: false,
  }),
  columnHelper.accessor('voucher', {
    cell: (info) => (info.row.original.voucher?.priceCent ? info.row.original.voucher?.priceCent : ''),
    header: () => <span>Voucher Price Cent</span>,
    enableColumnFilter: false,
    enableSorting: false,
  }),
];

const orderDiscountColumns = () => [
  columnHelper.accessor('discount', {
    cell: (info) => (info.row.original.discount?.type ? info.row.original.discount?.type : ''),
    header: () => <span>Discount type</span>,
    enableColumnFilter: false,
    enableSorting: false,
  }),
  columnHelper.accessor('discount', {
    cell: (info) => (info.row.original?.discount?.percent ? `${info.row.original?.discount?.percent}%` : ''),
    header: () => <span>Discount percent</span>,
    enableColumnFilter: false,
    enableSorting: false,
  }),
  columnHelper.accessor('discount', {
    cell: (info) => (info.row.original?.discount?.value ? `${info.row.original?.discount?.value ?? ''}€` : ''),
    header: () => <span>Discount value</span>,
    enableColumnFilter: false,
    enableSorting: false,
  }),
  columnHelper.accessor('discount', {
    cell: (info) => (info.row.original.discount?.code ? info.row.original.discount?.code : ''),
    header: () => <span>Discount code</span>,
    enableColumnFilter: false,
    enableSorting: false,
  }),
  columnHelper.accessor('discount', {
    cell: (info) => (info.row.original.discount?.number ? info.row.original.discount?.number : ''),
    header: () => <span>Discount number</span>,
    enableColumnFilter: false,
    enableSorting: false,
  }),
];

const pickupAddressColumns = () => [
  columnHelper.accessor('pickupAddress.street', {
    cell: (info) => info.getValue(),
    header: () => <span>Street</span>,
    enableColumnFilter: false,
    enableSorting: false,
  }),
  columnHelper.accessor('pickupAddress.city', {
    cell: (info) => info.getValue(),
    header: () => <span>City</span>,
    enableColumnFilter: false,
    enableSorting: false,
  }),
  columnHelper.accessor('pickupAddress.zipCode', {
    cell: (info) => info.getValue(),
    header: () => <span>Zip code</span>,
    enableColumnFilter: false,
    enableSorting: false,
  }),
  columnHelper.accessor('pickupAddress.state', {
    cell: (info) => info.getValue(),
    header: () => <span>State</span>,
    enableColumnFilter: false,
    enableSorting: false,
  }),
  columnHelper.accessor('pickupDriver', {
    cell: (info) => {
      const driver = info.getValue();

      if (!driver) {
        return '';
      }

      return <DriverCell driver={driver} />;
    },
    header: () => <span>Driver</span>,
    enableColumnFilter: false,
    enableSorting: false,
  }),
  columnHelper.accessor('customer.firstName', {
    cell: (info) => info.getValue(),
    header: () => <span>Name</span>,
    enableColumnFilter: false,
    enableSorting: false,
  }),
  columnHelper.accessor('customer.lastName', {
    cell: (info) => info.getValue(),
    header: () => <span>Surname</span>,
    enableColumnFilter: false,
    enableSorting: false,
  }),
  columnHelper.accessor('customer.email', {
    cell: (info) => info.getValue(),
    header: () => <span>Email</span>,
    enableColumnFilter: false,
    enableSorting: false,
  }),
  columnHelper.accessor('customer.phoneNumber', {
    cell: (info) => info.getValue(),
    header: () => <span>Phone number</span>,
    enableColumnFilter: false,
    enableSorting: false,
  }),
];

const ratingColumns = () => [
  columnHelper.accessor('craftsmen', {
    cell: (info) => {
      const craftsmen = info.getValue();

      if (!craftsmen || !craftsmen.length) {
        return '';
      }

      return <CraftsmanCell craftsmen={craftsmen}/>;
    },
    header: () => <span>Repaired by</span>,
    enableColumnFilter: false,
    enableSorting: false,
  }),
]

const ratingServiceColumns = () => [
  columnHelper.accessor('order', {
    cell: (info) => {
      const order = info.row.original;

      if (!order) {
        return '';
      }

      return <ServiceCell order={order} />;
    },
    header: () => <span>User Ranking</span>,
    enableColumnFilter: false,
    enableSorting: false,
  }),
];

const deliveryAddressColumns = () => [
  columnHelper.accessor('deliveryAddress.street', {
    cell: (info) => info.getValue(),
    header: () => <span>Street</span>,
    enableColumnFilter: false,
    enableSorting: false,
  }),
  columnHelper.accessor('deliveryAddress.city', {
    cell: (info) => info.getValue(),
    header: () => <span>City</span>,
    enableColumnFilter: false,
    enableSorting: false,
  }),
  columnHelper.accessor('deliveryAddress.zipCode', {
    cell: (info) => info.getValue(),
    header: () => <span>Zip code</span>,
    enableColumnFilter: false,
    enableSorting: false,
  }),
  columnHelper.accessor('deliveryAddress.state', {
    cell: (info) => info.getValue(),
    header: () => <span>State</span>,
    enableColumnFilter: false,
    enableSorting: false,
  }),
  columnHelper.accessor('deliveryDriver', {
    cell: (info) => {
      const driver = info.getValue();

      if (!driver) {
        return '';
      }

      return <DriverCell driver={driver} />;
    },
    header: () => <span>Driver</span>,
    enableColumnFilter: false,
    enableSorting: false,
  }),
  columnHelper.accessor('customer.firstName', {
    cell: (info) => info.getValue(),
    header: () => <span>Name</span>,
    enableColumnFilter: false,
    enableSorting: false,
  }),
  columnHelper.accessor('customer.lastName', {
    cell: (info) => info.getValue(),
    header: () => <span>Surname</span>,
    enableColumnFilter: false,
    enableSorting: false,
  }),
  columnHelper.accessor('customer.email', {
    cell: (info) => info.getValue(),
    header: () => <span>Email</span>,
    enableColumnFilter: false,
    enableSorting: false,
  }),
  columnHelper.accessor('customer.phoneNumber', {
    cell: (info) => info.getValue(),
    header: () => <span>Phone number</span>,
    enableColumnFilter: false,
    enableSorting: false,
  }),
];

export { orderColumns, pickupAddressColumns, deliveryAddressColumns, ratingColumns, ratingServiceColumns, orderVoucherDiscountColumns, orderDiscountColumns };
