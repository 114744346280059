import { useEffect, useState } from 'react';
import { Button, Col, Form, List, ListInlineItem, Row, Spinner } from 'reactstrap';
import { toastr } from 'react-redux-toastr';
import { Formik, FormikTouched, setNestedObjectValues } from 'formik';
import { useNavigate, useParams } from 'react-router';
import * as yup from 'yup';
import { apiRequest, handleError, TOAST_IMPORTANT_TIME_OUT_MS } from '../../utils';
import { CustomInput } from '../../components/Input/CustomInput';
import { DiscountStatus, DiscountType, Pages } from '../../enums';
import moment from 'moment';
import BackDetailsLink from '../../components/BackDetailsLink/BackDetailsLink';

type DiscountDetailsProps = {
  type?: 'new' | 'existing';
};

const MAX_PERCENT = 100;
const MIN = 0;

const DiscountSchema = yup.object({
  _id: yup.string().optional().nullable(),
  number: yup.string().optional().nullable(),
  code: yup
    .string()
    .matches(/^[a-zA-Z0-9äöüÄÖÜ_-]*$/, 'Wrong symbol, special characters not allowed. Use alphanumeric characters, hyphens and underscores.')
    .required('This field is required.'),
    deadline: yup
    .date()
    .min(moment().add(1, 'day').startOf('day').toDate())
    .required('This field is required.'),
  initialQuantity: yup.number().integer().moreThan(yup.ref('quantity')).required('This field is required.'),
  percent: yup.number().when('type', {
    is: (type: DiscountType) => type === DiscountType.PERCENTAGE,
    then: yup.number().moreThan(MIN).max(MAX_PERCENT).required('This field is required.'),
    otherwise: yup.number(),
  }),
  value: yup.number().when('type', {
    is: (type: DiscountType) => type === DiscountType.MONETARY,
    then: yup.number().moreThan(MIN).required('This field is required.'),
    otherwise: yup.number(),
  }),
  quantity: yup.number().min(MIN).required('This field is required.'),
  type: yup.mixed<DiscountType>().oneOf(Object.values(DiscountType)).required('This field is required.'),
  status: yup.mixed<DiscountStatus>().oneOf(Object.values(DiscountStatus)).required('This field is required.'),
});
type DiscountSchemaType = yup.InferType<typeof DiscountSchema>;

const DiscountDetails = ({ type = 'new' }: DiscountDetailsProps) => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [values, setValues] = useState<DiscountSchemaType>({
    _id: '',
    number: '',
    code: '',
    deadline: new Date(),
    initialQuantity: 0,
    quantity: 0,
    percent: 0,
    value: 0,
    type: DiscountType.PERCENTAGE,
    status: DiscountStatus.DEACTIVATED,
  });
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (type === 'new') {
      return;
    }
    onLoad();
  }, [id]);

  const enableLoading = () => {
    setLoading(true);
  };
  const disableLoading = () => {
    setLoading(false);
  };

  const onLoad = async () => {
    const abortController = new AbortController();
    try {
      enableLoading();
      const { data } = await apiRequest(`/discount/${id}`, { signal: abortController.signal });
      const { message } = data;

      if (message) {
        if (Array.isArray(message)) {
          message.forEach((msg) => toastr.error('Error', msg, { timeOut: TOAST_IMPORTANT_TIME_OUT_MS }));
        } else {
          toastr.error('Error', message, { timeOut: TOAST_IMPORTANT_TIME_OUT_MS });
        }
        abortController.abort();
        return disableLoading();
      }
      setValues(data);
      disableLoading();
    } catch (err) {
      handleError('FETCH_DISCOUNT', err);
      if (err instanceof DOMException && err.name === 'AbortError') {
        toastr.error('Error', 'Your browser aborted the request', { timeOut: TOAST_IMPORTANT_TIME_OUT_MS });
      } else {
        toastr.error('Error', 'Failed to fetch discount by ID', { timeOut: TOAST_IMPORTANT_TIME_OUT_MS });
      }
      abortController.abort();
    }
  };

  const onCreate = async (values: DiscountSchemaType, action: any) => {
    const abortController = new AbortController();
    try {
      enableLoading();
      const valuesCopy = { ...values };
      delete valuesCopy._id;
      delete valuesCopy.number;
      const { data } = await apiRequest('/discount', { method: 'POST', data: JSON.stringify({ ...valuesCopy, quantity: valuesCopy.initialQuantity }) });
      const { message } = data;

      if (message) {
        if (Array.isArray(message)) {
          message.forEach((msg) => toastr.error('Error', msg, { timeOut: TOAST_IMPORTANT_TIME_OUT_MS }));
        } else {
          toastr.error('Error', message, { timeOut: TOAST_IMPORTANT_TIME_OUT_MS });
        }
        action.setSubmitting(false);
        abortController.abort();
        return disableLoading();
      }
      disableLoading();
      toastr.success('Success', 'Discount created');
      navigate(Pages.DISCOUNTS);
    } catch (err) {
      handleError('CREATE_DISCOUNT', err);
      if (err instanceof DOMException && err.name === 'AbortError') {
        toastr.error('Error', 'Your browser aborted the request', { timeOut: TOAST_IMPORTANT_TIME_OUT_MS });
      } else {
        toastr.error('Error', 'Failed to create discount', { timeOut: TOAST_IMPORTANT_TIME_OUT_MS });
      }
      abortController.abort();
    }
  };

  const onUpdate = async (values: DiscountSchemaType, action: any) => {
    const abortController = new AbortController();
    try {
      enableLoading();
      const valuesCopy = { ...values };
      delete valuesCopy._id;
      delete valuesCopy.number;
      const { data } = await apiRequest(`/discount/${values._id}`, {
        method: 'PUT',
        data: JSON.stringify({ ...valuesCopy, quantity: values.quantity < valuesCopy.initialQuantity ? values.quantity : valuesCopy.initialQuantity }),
      });
      const { message } = data;

      if (message) {
        if (Array.isArray(message)) {
          message.forEach((msg) => toastr.error('Error', msg, { timeOut: TOAST_IMPORTANT_TIME_OUT_MS }));
        } else {
          toastr.error('Error', message, { timeOut: TOAST_IMPORTANT_TIME_OUT_MS });
        }
        action.setSubmitting(false);
        return disableLoading();
      }
      disableLoading();
      toastr.success('Success', 'Discount updated');
      navigate(`/discounts/${id}`);
    } catch (err) {
      handleError('UPDATE_DISCOUNT', err);
      if (err instanceof DOMException && err.name === 'AbortError') {
        toastr.error('Error', 'Your browser aborted the request', { timeOut: TOAST_IMPORTANT_TIME_OUT_MS });
      } else {
        toastr.error('Error', 'Failed to update discount', { timeOut: TOAST_IMPORTANT_TIME_OUT_MS });
      }
      abortController.abort();
    }
  };

  const onSubmit = async (formik: any) => {
    const formikErrors = await formik.validateForm();
    if (Object.keys(formikErrors).length) {
      formik.setTouched(setNestedObjectValues<FormikTouched<DiscountSchemaType>>(formikErrors, true));
      return null;
    }

    if (type === 'new') {
      await onCreate(formik.values, {
        setSubmitting: formik.setSubmitting,
      });
    } else if (type === 'existing') {
      await onUpdate(formik.values, {
        setSubmitting: formik.setSubmitting,
      });
    }
  };

  return (
    <Col className="mh-100 overflow-auto px-3">
      <Row>
        <Col>
          <h3 className="text-secondary mb-3">Discount Details</h3>
        </Col>
        <Col className="d-flex justify-content-end">
          <BackDetailsLink path={Pages.DISCOUNTS} />
        </Col>
      </Row>
      <Formik enableReinitialize initialValues={values} validationSchema={DiscountSchema} onSubmit={onSubmit}>
        {(formik) => (
          <Form id="create-discount" className="d-flex flex-column gap-4">
            <Row>
              <Col>
                <p className="mb-2">Discount Type</p>
                <List className="p-0 m-0">
                  {Object.values(DiscountType).map((val) => (
                    <ListInlineItem key={val}>
                      <Button
                        className={`btn-md ${val.toLowerCase() === formik.values.type.toLowerCase() ? 'checked' : ''}`}
                        onClick={() => {
                          formik.setFieldValue('type', val);
                          if (val === DiscountType.PERCENTAGE) {
                            formik.setFieldValue('value', 0);
                          } else if (val === DiscountType.MONETARY) {
                            formik.setFieldValue('percent', 0);
                          }
                        }}
                      >
                        {val}
                      </Button>
                    </ListInlineItem>
                  ))}
                </List>
              </Col>
              <Col>
                <p className="mb-2">Discount Status</p>
                <List className="p-0 m-0">
                  {Object.values(DiscountStatus).map((val) => (
                    <ListInlineItem key={val}>
                      <Button
                        color="secondary"
                        className={`btn-md ${val.toLowerCase() === formik.values.status.toLowerCase() ? 'checked' : ''}`}
                        onClick={() => formik.setFieldValue('status', val)}
                      >
                        {val}
                      </Button>
                    </ListInlineItem>
                  ))}
                </List>
              </Col>
            </Row>
            <Row>
              <Col>
                <CustomInput
                  label="Code"
                  inputProps={{
                    id: 'code',
                    name: 'code',
                    type: 'text',
                    value: formik.values.code,
                    onChange: formik.handleChange,
                  }}
                  error={formik.touched.code && formik.errors.code ? formik.errors.code : ''}
                />
              </Col>
              <Col>
                {formik.values.type === DiscountType.PERCENTAGE && (
                  <Row>
                    <CustomInput
                      label="Percent"
                      inputProps={{
                        id: 'percent',
                        name: 'percent',
                        type: 'number',
                        max: MAX_PERCENT,
                        min: MIN,
                        value: formik.values.percent,
                        onChange: formik.handleChange,
                      }}
                      error={formik.touched.percent && formik.errors.percent ? formik.errors.percent : ''}
                    />
                  </Row>
                )}
                {formik.values.type === DiscountType.MONETARY && (
                  <Row>
                    <CustomInput
                      label="Value €"
                      inputProps={{
                        id: 'value',
                        name: 'value',
                        type: 'number',
                        min: MIN,
                        value: formik.values.value,
                        onChange: formik.handleChange,
                      }}
                      error={formik.touched.value && formik.errors.value ? formik.errors.value : ''}
                    />
                  </Row>
                )}
              </Col>
            </Row>
            <Row>
              <CustomInput
                label="Deadline"
                inputProps={{
                  id: 'deadline',
                  name: 'deadline',
                  type: 'date',
                  value: formik.values.deadline.toString(),
                  onChange: formik.handleChange,
                }}
                error={formik.touched.deadline && formik.errors.deadline ? `${formik.errors.deadline}` : ''}
              />
            </Row>
            <Row>
              <Col>
                <CustomInput
                  label="Initial Quantity"
                  inputProps={{
                    id: 'initialQuantity',
                    name: 'initialQuantity',
                    type: 'number',
                    min: MIN,
                    value: formik.values.initialQuantity,
                    onChange: formik.handleChange,
                  }}
                  error={formik.touched.initialQuantity && formik.errors.initialQuantity ? formik.errors.initialQuantity : ''}
                />
              </Col>
              <Col>
                <CustomInput
                  label="Quantity"
                  inputProps={{
                    id: 'quantity',
                    name: 'quantity',
                    type: 'number',
                    min: MIN,
                    value: formik.values.initialQuantity,
                    onChange: formik.handleChange,
                    disabled: true,
                  }}
                  error={formik.touched.quantity && formik.errors.quantity ? formik.errors.quantity : ''}
                />
              </Col>
            </Row>
            <Row className="mt-5">
              <Col xs={12} md={{ offset: 4, size: 4 }} xxl={{ offset: 5, size: 2 }}>
                <Button color="primary" type="button" className="w-100 btn-md" disabled={loading} onClick={() => onSubmit(formik)}>
                  {type === 'new' ? 'Create' : 'Update'} Discount {loading && <Spinner size="sm" color="secondary" />}
                </Button>
              </Col>
            </Row>
          </Form>
        )}
      </Formik>
    </Col>
  );
};

export { DiscountDetails };
