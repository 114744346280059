const DATE_FORMAT = 'DD/MM/yyyy';
const ORDER_DETAILS_DATE_FORMAT = 'MMMM DD, YYYY';
const DEFAULT_LIMIT = 20;
const DEFAULT_SKIP = 0;
const MAX_IMAGE_SIZE = 50;
const MAX_CRAFTSMAN_PROFILE_IMAGES = 5;
const MAX_CRAFTSMAN_IMAGES = 50;
const MAX_ESTIMATION_IMAGES = 10;
const ACCEPTED_IMAGES = { 'image/jpeg': [], 'image/png': [], 'image/heic': [], 'image/jpg': [], 'video/mp4': [], 'video/quicktime': [] };

const TOAST_IMPORTANT_TIME_OUT_MS = 4000;

const TOAST_TIME_OUT_MS = 1500;

export {
  DATE_FORMAT,
  ORDER_DETAILS_DATE_FORMAT,
  DEFAULT_LIMIT,
  DEFAULT_SKIP,
  MAX_IMAGE_SIZE,
  MAX_CRAFTSMAN_IMAGES,
  MAX_CRAFTSMAN_PROFILE_IMAGES,
  MAX_ESTIMATION_IMAGES,
  ACCEPTED_IMAGES,
  TOAST_IMPORTANT_TIME_OUT_MS,
  TOAST_TIME_OUT_MS,
};
