import { ReactNode } from 'react';
import { Col, Row } from 'reactstrap';
import { CustomInput } from '../../../components/Input/CustomInput';

const TranslationPairForm = ({ formik, field, addonHeaderDe, addonContentDe, addonHeaderEn, addonContentEn }: TranslationPairFormProps) => (
  <Row>
    <Col className="d-flex flex-column gap-3">
      <CustomInput
        label="Header (DE)"
        inputProps={{
          id: 'de-header',
          name: `${[field]}.de.header`,
          type: 'text',
          value: formik.values[field]?.de?.header,
          onChange: formik.handleChange,
        }}
        addon={addonHeaderDe}
        error={formik.touched[field]?.de?.header && formik.errors[field]?.de?.header ? formik.errors[field]?.de?.header : ''}
      />
      <CustomInput
        label="Content (DE)"
        inputProps={{
          id: 'de-content',
          name: `${[field]}.de.content`,
          type: 'textarea',
          value: formik.values[field]?.de?.content,
          onChange: formik.handleChange,
        }}
        addon={addonContentDe}
        error={formik.touched[field]?.de?.content && formik.errors[field]?.de?.content ? formik.errors[field]?.de?.content : ''}
      />
    </Col>
    <Col className="d-flex flex-column gap-3">
      <CustomInput
        label="Header (EN)"
        inputProps={{
          id: 'en-header',
          name: `${[field]}.en.header`,
          type: 'text',
          value: formik.values[field]?.en?.header,
          onChange: formik.handleChange,
        }}
        addon={addonHeaderEn}
        error={formik.touched[field]?.en?.header && formik.errors[field]?.en?.header ? formik.errors[field]?.en?.header : ''}
      />
      <CustomInput
        label="Content (EN)"
        inputProps={{
          id: 'en-content',
          name: `${[field]}.en.content`,
          type: 'textarea',
          value: formik.values[field]?.en?.content,
          onChange: formik.handleChange,
        }}
        addon={addonContentEn}
        error={formik.touched[field]?.en?.content && formik.errors[field]?.en?.content ? formik.errors[field]?.en?.content : ''}
      />
    </Col>
  </Row>
);

type TranslationPairFormProps = {
  formik: any;
  field: string;
  addonHeaderDe?: ReactNode;
  addonContentDe?: ReactNode;
  addonHeaderEn?: ReactNode;
  addonContentEn?: ReactNode;
};

export { TranslationPairForm };
