import { AdminAuthRole, Pages } from '../enums';
import { UserState } from '../store/redux/reducer/user/type';

const getDashboardPath = (user: UserState) => {
  const userHasRole = !!user && user?.role;
  const isAdmin = userHasRole && user.role === AdminAuthRole.ADMIN;
  const isDriver = userHasRole && user.role === AdminAuthRole.DRIVER;
  const isCraftsman = userHasRole && user.role === AdminAuthRole.CRAFTSMEN;
  const isShopkeeper = userHasRole && user.role === AdminAuthRole.SHOPKEEPER;

  switch (true) {
    case isAdmin:
      return Pages.DASHBOARD;
    case isDriver:
      return Pages.DRIVER;
    case isCraftsman:
      return Pages.CRAFTSMAN;
    case isShopkeeper:
      return Pages.SHOPKEEPER;
    default:
      return Pages.LOGIN;
  }
};

export { getDashboardPath };
