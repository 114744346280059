import React, { useState } from 'react';
import { Button, Col, Collapse, Row } from 'reactstrap';
import { Contact, Driver } from '../../../../types';
import { ArrowDropDown } from '@styled-icons/material/ArrowDropDown';

const extractOperatorName = ({ firstName, lastName }: { firstName: string; lastName: string } & any) => `${firstName} ${lastName}`;
const extractDriverContacts = (driver: Driver, contactField: keyof Contact) => driver.contact[contactField];

const DriverCell = ({ driver }: DriverCellProps) => {
  const [selectedDriver, setSelectedDriver] = useState<null | Driver['_id']>(null);

  const showDetails = (_id: Driver['_id']) => setSelectedDriver(selectedDriver === _id ? null : _id);

  return (
    <Col>
      <Row className="gx-0">
        <Button color="primary" className={`link-button ${selectedDriver === driver._id ? 'active' : ''}`} onClick={() => showDetails(driver._id)}>
          {extractOperatorName(driver.contact)}
          <ArrowDropDown className="icon" />
        </Button>
      </Row>
      <Row className="gx-0">
        <Collapse isOpen={selectedDriver === driver._id}>
          <Col className="d-flex flex-column gap-2 my-2">
            <p>
              <span>Name: </span>
              <span>{extractOperatorName(driver.contact)}</span>
            </p>
            <p>
              <span>Email: </span>
              <span>{extractDriverContacts(driver, 'email')}</span>
            </p>
            <p>
              <span>Phone number #1: </span>
              <span>{extractDriverContacts(driver, 'phoneNumber')}</span>
            </p>
            <p>
              <span>Phone number #2: </span>
              <span>{extractDriverContacts(driver, 'phoneNumber2')}</span>
            </p>
          </Col>
        </Collapse>
      </Row>
    </Col>
  );
};

type DriverCellProps = {
  driver: Driver;
};

export { DriverCell };
