import { Order } from '../types';

const parsePdf = (invoice: any, orderId: Order['_id']) => {
  // read file
  const byteCharacters = window.atob(invoice);
  const byteNumbers = new Array(byteCharacters.length);

  for (let i = 0; i < byteCharacters.length; i++) {
    byteNumbers[i] = byteCharacters.charCodeAt(i);
  }

  const byteArray = new Uint8Array(byteNumbers);
  // parse bytes to Blob
  const url = window.URL.createObjectURL(new Blob([byteArray], { type: 'application/pdf' }));

  // create download link programmatically
  const link = document.createElement('a');
  link.download = `invoice-${orderId}.pdf`;
  link.href = url;
  document.body.appendChild(link);
  link.click();
  link.remove();
  // download the file
  window.open(url);
  window.URL.revokeObjectURL(url);
};

export { parsePdf };
