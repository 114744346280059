import { useState } from 'react';
import { Dropdown, DropdownItem, DropdownMenu, DropdownToggle, List, Spinner } from 'reactstrap';
import { toastr } from 'react-redux-toastr';
import { Customer, Order } from '../../../../types';
import { apiRequest } from '../../../../utils';
import { OrderEmailTypes } from '../../../../enums';
import '../OrderDetails.scss';

const getEmailTypeLabel = (emailType: OrderEmailTypes): string => {
  switch (emailType) {
    case OrderEmailTypes.DELIVERY_FAILED:
      return 'Send `delivery failed` notification to the customer';
    case OrderEmailTypes.DELIVERY_IN_PROGRESS:
      return 'Send `delivery in progress` notification to the customer';
    case OrderEmailTypes.DELIVERY_RESCHEDULED:
      return 'Send `delivery rescheduled` notification to the customer';
    case OrderEmailTypes.ORDER_CANCELED:
      return 'Send `order canceled` notification to the customer';
    case OrderEmailTypes.ORDER_CLOSED:
      return 'Send `order closed` notification to the customer';
    case OrderEmailTypes.ORDER_PLACED:
      return 'Send `order placed` notification to the customer';
    case OrderEmailTypes.PICKUP_FAILED:
      return 'Send `pick-up failed` notification to the customer';
    case OrderEmailTypes.PICKUP_IN_PROGRESS:
      return 'Send `pick-up in progress` notification to the customer';
    case OrderEmailTypes.PICKUP_RESCHEDULED:
      return 'Send `pick-up rescheduled` notification to the customer';
    case OrderEmailTypes.RATE_REMINDER:
      return 'Send `rate us` reminder to the customer';
    case OrderEmailTypes.REPAIR_IN_PROGRESS:
      return 'Send `repair in progress` notification to the customer';
  }
};

const EmailTriggersOrder = ({ orderId, customerId, disabled }: EmailTriggersProps) => {
  const [isOpen, setOpen] = useState(false);
  const [operationIsPending, setOperationIsPending] = useState(false);

  const triggerEmail = async (emailType: OrderEmailTypes) => {
    const abortController = new AbortController();
    setOperationIsPending(true);
    const { data } = await apiRequest(`/mailer/manual/${emailType}`, {
      method: 'PATCH',
      signal: abortController.signal,
      data: JSON.stringify({ orderId, customerId }),
    });
    const { message, success } = data;

    if (success) {
      toastr.success('Success', message);
    } else {
      toastr.error('Error', message);
    }
    setOperationIsPending(false);
  };

  const toggle = () => {
    setOpen(!isOpen);
  };

  return (
    <List className="m-0 p-0 w-100 h-100">
      <Dropdown className="h-100" isOpen={isOpen} toggle={toggle} disabled={disabled || operationIsPending}>
        <DropdownToggle caret className="w-100 h-100 font-18" disabled={disabled || operationIsPending}>
          {!operationIsPending && <div className="text">Trigger email</div>}
          {operationIsPending && (
            <div className="d-flex align-items-center justify-content-center gap-2">
              <span>Email is preparing</span>
              <Spinner size="sm" color="secondary" />
            </div>
          )}
        </DropdownToggle>
        <DropdownMenu>
          {Object.values(OrderEmailTypes).map((val) => (
            <DropdownItem key={val} onClick={() => triggerEmail(val)}>
              {getEmailTypeLabel(val)}
            </DropdownItem>
          ))}
        </DropdownMenu>
      </Dropdown>
    </List>
  );
};

type EmailTriggersProps = {
  disabled?: boolean;
  orderId: Order['_id'];
  customerId: Customer['_id'];
};

export { EmailTriggersOrder };
