import { createColumnHelper } from '@tanstack/react-table';
import { ShippingOrder } from '../../../../types';
import moment from 'moment';

const columnHelper = createColumnHelper<ShippingOrder>();

const orderColumns = () => [
  columnHelper.accessor('number', {
    cell: (info) => info.getValue(),
    header: () => <span>Number</span>,
    enableColumnFilter: false,
    enableSorting: false,
  }),
  columnHelper.accessor('createdAt', {
    cell: (info) => moment(info.getValue()).format('DD-MM-YYYY'),
    header: () => <span>Creation Date</span>,
    size: 430,
  }),
  columnHelper.accessor('status', {
    cell: (info) => info.getValue(),
    header: () => <span>Status</span>,
    enableColumnFilter: false,
    enableSorting: false,
  }),
  columnHelper.accessor('paymentParams.method', {
    cell: (info) => info.getValue(),
    header: () => <span>Payment method</span>,
    enableColumnFilter: false,
    enableSorting: false,
  }),
  columnHelper.accessor('paymentParams.status', {
    cell: (info) => info.getValue(),
    header: () => <span>Payment</span>,
    size: 340,
  }),
  columnHelper.accessor('status', {
    cell: (info) => info.getValue(),
    header: () => <span>Status</span>,
    size: 340,
  }),
  columnHelper.accessor('paymentParams.referenceNumber', {
    cell: (info) => info.getValue(),
    header: () => <span>Payment ref. number</span>,
    enableColumnFilter: false,
    enableSorting: false,
  }),
];

const contactInformation = () => [
  columnHelper.accessor('address.city', {
    cell: (info) => info.getValue(),
    header: () => <span>City</span>,
    enableColumnFilter: false,
    enableSorting: false,
  }),
  columnHelper.accessor('address.country', {
    cell: (info) => info.getValue(),
    header: () => <span>Country</span>,
    enableColumnFilter: false,
    enableSorting: false,
  }),
  columnHelper.accessor('address.street', {
    cell: (info) => info.getValue(),
    header: () => <span>Street</span>,
    enableColumnFilter: false,
    enableSorting: false,
  }),
  columnHelper.accessor('address.zipCode', {
    cell: (info) => info.getValue(),
    header: () => <span>Zip code</span>,
    enableColumnFilter: false,
    enableSorting: false,
  }),
  columnHelper.accessor('customer.phoneNumber', {
    cell: (info) => info.getValue(),
    header: () => <span>Number</span>,
    enableColumnFilter: false,
    enableSorting: false,
  }),
  columnHelper.accessor('customer.email', {
    cell: (info) => info.getValue(),
    header: () => <span>Email</span>,
    enableColumnFilter: false,
    enableSorting: false,
  }),
  columnHelper.accessor('customer.firstName', {
    cell: (info) => info.getValue(),
    header: () => <span>Name</span>,
    enableColumnFilter: false,
    enableSorting: false,
  }),
  columnHelper.accessor('customer.lastName', {
    cell: (info) => info.getValue(),
    header: () => <span>Last Name</span>,
    enableColumnFilter: false,
    enableSorting: false,
  }),
];

const orderVoucherDiscountColumns = () => [
  columnHelper.accessor('voucher', {
    cell: (info) => (info.row.original.voucher?.code ? info.row.original.voucher?.code : ''),
    header: () => <span>Voucher Code</span>,
    enableColumnFilter: false,
    enableSorting: false,
  }),
  columnHelper.accessor('voucher', {
    cell: (info) => (info.row.original.voucher?.priceCent ? info.row.original.voucher?.priceCent : ''),
    header: () => <span>Voucher Price Cent</span>,
    enableColumnFilter: false,
    enableSorting: false,
  }),
];

const orderDiscountColumns = () => [
  columnHelper.accessor('discount', {
    cell: (info) => (info.row.original.discount?.type ? info.row.original.discount?.type : ''),
    header: () => <span>Discount type</span>,
    enableColumnFilter: false,
    enableSorting: false,
  }),
  columnHelper.accessor('discount', {
    cell: (info) => (info.row.original?.discount?.percent ? `${info.row.original?.discount?.percent}%` : ''),
    header: () => <span>Discount percent</span>,
    enableColumnFilter: false,
    enableSorting: false,
  }),
  columnHelper.accessor('discount', {
    cell: (info) => (info.row.original?.discount?.value ? `${info.row.original?.discount?.value ?? ''}€` : ''),
    header: () => <span>Discount value</span>,
    enableColumnFilter: false,
    enableSorting: false,
  }),
  columnHelper.accessor('discount', {
    cell: (info) => (info.row.original.discount?.code ? info.row.original.discount?.code : ''),
    header: () => <span>Discount code</span>,
    enableColumnFilter: false,
    enableSorting: false,
  }),
  columnHelper.accessor('discount', {
    cell: (info) => (info.row.original.discount?.number ? info.row.original.discount?.number : ''),
    header: () => <span>Discount number</span>,
    enableColumnFilter: false,
    enableSorting: false,
  }),
];

export { orderColumns, contactInformation, orderVoucherDiscountColumns, orderDiscountColumns };
