import * as yup from 'yup';

type Contact = {
  firstName: string;
  lastName: string;
  fullName?: string;
  email: string;
  phoneNumber: string;
  phoneNumber2?: string;
};

const ContactSchema = yup.object({
  fullName: yup.string(),
  email: yup.string().min(1, "This field shouldn't be empty").required('This field is required.'),
  phoneNumber: yup.string().min(1, "This field shouldn't be empty").required('This field is required.'),
  phoneNumber2: yup.string().min(1, "This field shouldn't be empty").optional(),
});

const initialContactData = {
  fullName: '',
  email: '',
  phoneNumber: '',
  phoneNumber2: '',
};

export { ContactSchema, initialContactData };
export type { Contact };
