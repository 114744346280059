enum OrderEmailTypes {
  DELIVERY_FAILED = 'DELIVERY_FAILED',
  DELIVERY_IN_PROGRESS = 'DELIVERY_IN_PROGRESS',
  DELIVERY_RESCHEDULED = 'DELIVERY_RESCHEDULED',
  ORDER_CANCELED = 'ORDER_CANCELED',
  ORDER_CLOSED = 'ORDER_CLOSED',
  ORDER_PLACED = 'ORDER_PLACED',
  PICKUP_FAILED = 'PICKUP_FAILED',
  PICKUP_IN_PROGRESS = 'PICKUP_IN_PROGRESS',
  PICKUP_RESCHEDULED = 'PICKUP_RESCHEDULED',
  RATE_REMINDER = 'RATE_REMINDER',
  REPAIR_IN_PROGRESS = 'REPAIR_IN_PROGRESS',
}

enum CustomerAuthEmailTypes {
  CUSTOMER_LOGIN_LINK = 'CUSTOMER_LOGIN_LINK',
  CUSTOMER_PASSWORD_RESET_LINK = 'CUSTOMER_PASSWORD_RESET_LINK',
  CUSTOMER_ACCOUNT_VERIFICATION = 'CUSTOMER_ACCOUNT_VERIFICATION',
}

enum AdminAuthEmailTypes {
  ADMIN_LOGIN_LINK = 'ADMIN_LOGIN_LINK',
  ADMIN_PASSWORD_RESET_LINK = 'ADMIN_PASSWORD_RESET_LINK',
}

enum EstimationRequestEmailTypes {
  ESTIMATE_REQUEST_RECEIVED = 'ESTIMATE_REQUEST_RECEIVED',
}

export { AdminAuthEmailTypes, CustomerAuthEmailTypes, EstimationRequestEmailTypes, OrderEmailTypes };
