import { useEffect, useState } from 'react';
import { Button, Col, Form, Row, Spinner } from 'reactstrap';
import { toastr } from 'react-redux-toastr';
import { Formik, FormikTouched, setNestedObjectValues } from 'formik';
import { useNavigate, useParams } from 'react-router';
import * as yup from 'yup';
import { ACCEPTED_IMAGES, apiRequest, getBase64, handleError, MAX_ESTIMATION_IMAGES, MAX_IMAGE_SIZE, TOAST_IMPORTANT_TIME_OUT_MS } from '../../utils';
import { Pages, SupportedCities } from '../../enums';
import { CustomInput } from '../../components/Input/CustomInput';
import Divider from '../../components/Divider';
import { Upload } from './components/Upload';
import BackDetailsLink from '../../components/BackDetailsLink/BackDetailsLink';

type EstimationDetailsProps = {
  type?: 'new' | 'existing';
};

const EstimationSchema = yup.object({
  _id: yup.string().optional().nullable(),
  number: yup.string().optional().nullable(),
  email: yup.string().required('This field is required.'),
  firstName: yup.string().required('This field is required.'),
  lastName: yup.string().required('This field is required.'),
  phoneNumber: yup.string().required('This field is required.'),
  country: yup.string().required('This field is required.'),
  city: yup.string().required('This field is required.'),
  street: yup.string().required('This field is required.'),
  zipCode: yup.string().required('This field is required.'),
  creationTime: yup.number().defined(),
  title: yup.string().required('This field is required.'),
  description: yup.string().required('This field is required.'),
  images: yup.array().of(yup.string()).required('This field is required.'),
});
type EstimationSchemaType = yup.InferType<typeof EstimationSchema>;

const EstimationDetails = ({ type = 'new' }: EstimationDetailsProps) => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [values, setValues] = useState<EstimationSchemaType>({
    _id: '',
    number: '',
    email: '',
    firstName: '',
    lastName: '',
    phoneNumber: '',
    country: '',
    city: SupportedCities.DUSSELDORF,
    zipCode: '',
    title: '',
    description: '',
    street: '',
    images: [],
    creationTime: Date.now() * 1000,
  });
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (type === 'new') {
      return;
    }
    onLoad();
  }, [id]);

  const enableLoading = () => {
    setLoading(true);
  };
  const disableLoading = () => {
    setLoading(false);
  };

  const onLoad = async () => {
    const abortController = new AbortController();
    try {
      enableLoading();
      const { data } = await apiRequest(`/estimation/by-id/${id}`, { signal: abortController.signal });
      const { message } = data;

      if (message) {
        if (Array.isArray(message)) {
          message.forEach((msg) => toastr.error('Error', msg, { timeOut: TOAST_IMPORTANT_TIME_OUT_MS }));
        } else {
          toastr.error('Error', message, { timeOut: TOAST_IMPORTANT_TIME_OUT_MS });
        }
        abortController.abort();
        return disableLoading();
      }
      setValues(data);
      disableLoading();
    } catch (err) {
      handleError('FETCH_CUSTOMER', err);
      if (err instanceof DOMException && err.name === 'AbortError') {
        toastr.error('Error', 'Your browser aborted the request', { timeOut: TOAST_IMPORTANT_TIME_OUT_MS });
      } else {
        toastr.error('Error', 'Failed to fetch customer by ID', { timeOut: TOAST_IMPORTANT_TIME_OUT_MS });
      }
      abortController.abort();
    }
  };

  const onCreate = async (values: EstimationSchemaType, action: any) => {
    const abortController = new AbortController();
    try {
      enableLoading();
      const valuesCopy = { ...values };
      delete valuesCopy._id;
      delete valuesCopy.number;

      const { data } = await apiRequest('/estimation/new', { method: 'POST', data: JSON.stringify(valuesCopy) });
      const { message } = data;

      if (message) {
        if (Array.isArray(message)) {
          message.forEach((msg) => toastr.error('Error', msg, { timeOut: TOAST_IMPORTANT_TIME_OUT_MS }));
        } else {
          toastr.error('Error', message, { timeOut: TOAST_IMPORTANT_TIME_OUT_MS });
        }
        action.setSubmitting(false);
        abortController.abort();
        return disableLoading();
      }
      disableLoading();
      toastr.success('Success', 'Estimation created');
      navigate(Pages.ESTIMATIONS);
    } catch (err) {
      handleError('CREATE_CUSTOMER', err);
      if (err instanceof DOMException && err.name === 'AbortError') {
        toastr.error('Error', 'Your browser aborted the request', { timeOut: TOAST_IMPORTANT_TIME_OUT_MS });
      } else {
        toastr.error('Error', 'Failed to create customer', { timeOut: TOAST_IMPORTANT_TIME_OUT_MS });
      }
      abortController.abort();
    }
  };

  const onUpdate = async (values: EstimationSchemaType, action: any) => {
    const abortController = new AbortController();
    try {
      enableLoading();
      const valuesCopy = { ...values };
      delete valuesCopy._id;
      delete valuesCopy.number;
      const { data } = await apiRequest(`/estimation/${values._id}`, { method: 'PUT', data: JSON.stringify(valuesCopy) });
      const { message } = data;

      if (message) {
        if (Array.isArray(message)) {
          message.forEach((msg) => toastr.error('Error', msg, { timeOut: TOAST_IMPORTANT_TIME_OUT_MS }));
        } else {
          toastr.error('Error', message, { timeOut: TOAST_IMPORTANT_TIME_OUT_MS });
        }
        action.setSubmitting(false);
        return disableLoading();
      }
      disableLoading();
      toastr.success('Success', 'Estimation updated');
      navigate(`/estimations/${id}`);
    } catch (err) {
      handleError('UPDATE_CUSTOMER', err);
      if (err instanceof DOMException && err.name === 'AbortError') {
        toastr.error('Error', 'Your browser aborted the request', { timeOut: TOAST_IMPORTANT_TIME_OUT_MS });
      } else {
        toastr.error('Error', 'Failed to update customer', { timeOut: TOAST_IMPORTANT_TIME_OUT_MS });
      }
      abortController.abort();
    }
  };

  const onSubmit = async (formik: any) => {
    const formikErrors = await formik.validateForm();
    if (Object.keys(formikErrors).length) {
      formik.setTouched(setNestedObjectValues<FormikTouched<EstimationSchemaType>>(formikErrors, true));
      return null;
    }

    if (type === 'new') {
      await onCreate(formik.values, {
        setSubmitting: formik.setSubmitting,
      });
    } else if (type === 'existing') {
      await onUpdate(formik.values, {
        setSubmitting: formik.setSubmitting,
      });
    }
  };

  const onImageUpload = async (file: File, clientName: string, setUploading: (state: boolean) => void) => {
    setUploading(true);
    const base64File = (await getBase64(file)) as string;

    if (!base64File) {
      return toastr.error('Error', `Error while uploading ${file.name}`);
    }

    const { data } = await apiRequest(`estimation/image/${clientName}/${values.creationTime}`, {
      method: 'POST',
      data: JSON.stringify({ file: base64File, filename: file.name, filetype: file.type }),
    });
    const { message } = data;

    if (message) {
      if (Array.isArray(message)) {
        message.forEach((msg) => toastr.error('Error', msg, { timeOut: TOAST_IMPORTANT_TIME_OUT_MS }));
      } else {
        toastr.error('Error', message, { timeOut: TOAST_IMPORTANT_TIME_OUT_MS });
      }
      return setUploading(false);
    }

    setUploading(false);
  };

  const onImageDelete = async (filename: string, setDeleting: (state: boolean) => void) => {
    setDeleting(true);
    const { data } = await apiRequest(`estimation/image?filename=${filename}`, {
      method: 'DELETE',
    });
    const { message } = data;

    if (message) {
      if (Array.isArray(message)) {
        message.forEach((msg) => toastr.error('Error', msg, { timeOut: TOAST_IMPORTANT_TIME_OUT_MS }));
      } else {
        toastr.error('Error', message, { timeOut: TOAST_IMPORTANT_TIME_OUT_MS });
      }
      return setDeleting(false);
    }
    setDeleting(false);
  };

  const loadEstimationImages = async (clientsName: string, creationTime: number) => {
    const { data } = await apiRequest(`estimation/images/${clientsName}/${creationTime}`, {
      method: 'GET',
    });
    const { message, images } = data;

    if (message) {
      if (Array.isArray(message)) {
        message.forEach((msg) => toastr.error('Error', msg, { timeOut: TOAST_IMPORTANT_TIME_OUT_MS }));
      } else {
        toastr.error('Error', message, { timeOut: TOAST_IMPORTANT_TIME_OUT_MS });
      }
      return [];
    }

    return images;
  };

  return (
    <Col className="mh-100 overflow-auto px-3">
      <Row>
        <h3 className="text-secondary mb-3">Estimation Details</h3>
      </Row>
      <Formik enableReinitialize initialValues={values} validationSchema={EstimationSchema} onSubmit={onSubmit}>
        {(formik) => {
          const { creationTime, firstName, lastName } = formik.values;
          const clientName = `${firstName}_${lastName}`;
          const onImageChange = (urls: string[]) => {
            formik.setFieldValue('images', urls);
          };

          return (
            <Form id="create-estimation" className="d-flex flex-column gap-4">
              <Row>
                <Col>
                  <h5>Contact information</h5>
                </Col>
                <Col className="d-flex justify-content-end">
                  <BackDetailsLink path={Pages.ESTIMATIONS} />
                </Col>
              </Row>
              <Row>
                <Col>
                  <CustomInput
                    label="First Name"
                    inputProps={{
                      id: 'firstName',
                      name: 'firstName',
                      type: 'text',
                      value: formik.values.firstName,
                      onChange: formik.handleChange,
                    }}
                    error={formik.touched.firstName && formik.errors.firstName ? formik.errors.firstName : ''}
                  />
                </Col>
                <Col>
                  <CustomInput
                    label="Last Name"
                    inputProps={{
                      id: 'lastName',
                      name: 'lastName',
                      type: 'text',
                      value: formik.values.lastName,
                      onChange: formik.handleChange,
                    }}
                    error={formik.touched.lastName && formik.errors.lastName ? formik.errors.lastName : ''}
                  />
                </Col>
              </Row>
              <Row>
                <Col>
                  <CustomInput
                    label="Email"
                    inputProps={{
                      id: 'email',
                      name: 'email',
                      type: 'email',
                      value: formik.values.email,
                      onChange: formik.handleChange,
                    }}
                    error={formik.touched.email && formik.errors.email ? formik.errors.email : ''}
                  />
                </Col>
                <Col>
                  <CustomInput
                    label="Phone Number"
                    inputProps={{
                      id: 'phoneNumber',
                      name: 'phoneNumber',
                      type: 'tel',
                      value: formik.values.phoneNumber,
                      onChange: formik.handleChange,
                    }}
                    error={formik.touched.phoneNumber && formik.errors.phoneNumber ? formik.errors.phoneNumber : ''}
                  />
                </Col>
              </Row>
              <Divider />
              <Row>
                <h5>Location</h5>
              </Row>
              <Row>
                <Col>
                  {' '}
                  <CustomInput
                    label="Country"
                    inputProps={{
                      id: 'country',
                      name: 'country',
                      type: 'text',
                      value: formik.values.country,
                      onChange: formik.handleChange,
                    }}
                    error={formik.touched.country && formik.errors.country ? formik.errors.country : ''}
                  />
                </Col>
                <Col>
                  {' '}
                  <CustomInput
                    label="Street and house number"
                    inputProps={{
                      id: 'street',
                      name: 'street',
                      type: 'text',
                      value: formik.values.street,
                      onChange: formik.handleChange,
                    }}
                    error={formik.touched.street && formik.errors.street ? formik.errors.street : ''}
                  />
                </Col>
                <Col>
                  {' '}
                  <CustomInput
                    label="City"
                    inputProps={{
                      id: 'city',
                      name: 'city',
                      type: 'text',
                      value: formik.values.city,
                      onChange: formik.handleChange,
                    }}
                    error={formik.touched.city && formik.errors.city ? formik.errors.city : ''}
                  />
                </Col>
                <Col>
                  {' '}
                  <CustomInput
                    label="Zip code"
                    inputProps={{
                      id: 'zipCode',
                      name: 'zipCode',
                      type: 'text',
                      value: formik.values.zipCode,
                      onChange: formik.handleChange,
                    }}
                    error={formik.touched.zipCode && formik.errors.zipCode ? formik.errors.zipCode : ''}
                  />
                </Col>
              </Row>
              <Divider />
              <Row>
                <h5>Describe requests for repair</h5>
              </Row>
              <Row>
                <CustomInput
                  label="Title"
                  inputProps={{
                    id: 'title',
                    name: 'title',
                    type: 'text',
                    value: formik.values.title,
                    onChange: formik.handleChange,
                  }}
                  error={formik.touched.title && formik.errors.title ? formik.errors.title : ''}
                />
              </Row>
              <Row>
                <CustomInput
                  label="Description"
                  inputProps={{
                    id: 'description',
                    name: 'description',
                    type: 'textarea',
                    value: formik.values.description,
                    onChange: formik.handleChange,
                  }}
                  error={formik.touched.description && formik.errors.description ? formik.errors.description : ''}
                />
              </Row>
              <Divider />
              <Row>
                <h5>Images</h5>
              </Row>
              <Row>
                <Col>
                  {!(firstName && lastName) && (
                    <p className={Object.values(formik.touched).length ? 'text-danger' : ''}>Please, enter name and surname to create an Upload Folder</p>
                  )}
                  <Upload
                    creationTime={creationTime}
                    accept={ACCEPTED_IMAGES}
                    maxSize={MAX_IMAGE_SIZE}
                    maxFiles={MAX_ESTIMATION_IMAGES}
                    disabled={!(firstName && lastName)}
                    clientFullName={clientName}
                    setImages={onImageChange}
                    loadImages={(creationTime) => loadEstimationImages(clientName, creationTime)}
                    onUpload={(image, setLoading) => onImageUpload(image, clientName, setLoading)}
                    onDelete={(filename, setDeleting) => onImageDelete(filename, setDeleting)}
                  />
                </Col>
              </Row>
              <Row className="mt-5">
                <Col xs={12} md={{ offset: 4, size: 4 }} xxl={{ offset: 5, size: 2 }}>
                  <Button color="primary" type="button" className="w-100 btn-md" disabled={loading} onClick={() => onSubmit(formik)}>
                    {type === 'new' ? 'Create' : 'Update'} Estimation {loading && <Spinner size="sm" color="secondary" />}
                  </Button>
                </Col>
              </Row>
            </Form>
          );
        }}
      </Formik>
    </Col>
  );
};

export { EstimationDetails };
