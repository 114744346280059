import { ChangeEvent, useEffect, useState } from 'react';
import { Button, Card, Col, Collapse, FormFeedback, Input, List } from 'reactstrap';
import { toastr } from 'react-redux-toastr';
import { ProductSearchDropdown } from './ProductSearchDropdown';
import { DropdownListProps, ProductCategory } from './types';
import { apiRequest, handleError, TOAST_IMPORTANT_TIME_OUT_MS } from '../../../../utils';
import { ProductSearchMatch } from '../../../../enums';
import './ProductFrom.scss';

const ProductForm = ({ formik, selectedRepairs, onRepairSelect, disabled }: DropdownListProps & { formik: any }) => {
  const [nestedProducts, setNestedProducts] = useState<ProductCategory[]>([]);
  const [matchType, setMatchType] = useState<ProductSearchMatch>(ProductSearchMatch.NO);
  const [isOpen, setIsOpen] = useState(![ProductSearchMatch.NO, ProductSearchMatch.ALL].includes(matchType));

  const toggle = () => setIsOpen(!isOpen);
  const searchProducts = async (event?: ChangeEvent<HTMLInputElement>) => {
    const abortController = new AbortController();
    let value = '';

    if (event) {
      value = event.target.value;
    }

    const { data: responseData } = await apiRequest(`product/search?search=${value}`, {
      signal: abortController.signal,
    });
    const { message, data, match } = responseData;

    if (message) {
      if (Array.isArray(message)) {
        message.forEach((msg) => toastr.error('Error', msg, { timeOut: TOAST_IMPORTANT_TIME_OUT_MS }));
      } else {
        toastr.error('Error', message, { timeOut: TOAST_IMPORTANT_TIME_OUT_MS });
      }
      return () => abortController.abort();
    }

    setNestedProducts(data);
    setMatchType(match);
  };

  useEffect(() => {
    try {
      searchProducts();
    } catch (err) {
      handleError('PRODUCTS_ORDER_CREATION', err);
    }
  }, []);

  return (
    <div className="d-flex flex-column gap-2 mb-2">
      <div>
        <Button
          color="secondary"
          onClick={toggle}
          className={`d-flex dropdown-toggle justify-content-between align-items-center w-100 form-control ${
            formik.touched.repairList && formik.errors.repairList ? 'border-danger is-invalid' : ''
          }`}
          disabled={disabled}
        >
          <span>Select products</span>
        </Button>
      </div>
      {!!formik.touched.repairList && formik.errors.repairList && !Array.isArray(formik.errors.repairList) && (
        <FormFeedback className="d-block" invalid>
          {formik.errors.repairList?.toString()}
        </FormFeedback>
      )}
      <div>
        <Collapse isOpen={isOpen}>
          <Col className="d-flex flex-column gap-2">
            <Input type="search" name="search" onChange={searchProducts} placeholder="Search a product..." />
            {nestedProducts && !!nestedProducts.length && (
              <Card>
                <Col>
                  <List className="p-0">
                    {nestedProducts.map((category) => (
                      <ProductSearchDropdown
                        key={category._id}
                        category={category}
                        selectedRepairs={selectedRepairs}
                        onRepairSelect={onRepairSelect}
                        matchType={matchType}
                      />
                    ))}
                  </List>
                </Col>
              </Card>
            )}
          </Col>
        </Collapse>
      </div>
    </div>
  );
};

export { ProductForm };
