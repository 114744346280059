import { ReactNode } from 'react';
import { Col, Row } from 'reactstrap';
import { CustomInput } from '../../../components/Input/CustomInput';

const SEOTranslationPairForm = ({ formik, field, addonHeaderDe, addonContentDe, addonHeaderEn, addonContentEn, addonURL }: SEOTranslationPairFormProps) => (
  <>
    <Row>
      <CustomInput
        label="Craftsman URL"
        inputProps={{
          id: 'url',
          name: `${[field]}.url`,
          type: 'text',
          value: formik.values[field]?.url,
          onChange: formik.handleChange,
        }}
        addon={addonURL}
        error={formik.touched[field]?.url && formik.errors[field]?.url ? formik.errors[field]?.url : ''}
      />
    </Row>
    <Row>
      <Col className="d-flex flex-column gap-3">
        <CustomInput
          label="Title (DE)"
          inputProps={{
            id: 'de-title',
            name: `${[field]}.de.title`,
            type: 'text',
            value: formik.values[field]?.de?.title,
            onChange: formik.handleChange,
          }}
          addon={addonHeaderDe}
          error={formik.touched[field]?.de?.title && formik.errors[field]?.de?.title ? formik.errors[field]?.de?.title : ''}
        />
        <CustomInput
          label="Description (DE)"
          inputProps={{
            id: 'de-description',
            name: `${[field]}.de.description`,
            type: 'textarea',
            value: formik.values[field]?.de?.description,
            onChange: formik.handleChange,
          }}
          addon={addonContentDe}
          error={formik.touched[field]?.de?.description && formik.errors[field]?.de?.description ? formik.errors[field]?.de?.description : ''}
        />
      </Col>
      <Col className="d-flex flex-column gap-3">
        <CustomInput
          label="Title (EN)"
          inputProps={{
            id: 'en-title',
            name: `${[field]}.en.title`,
            type: 'text',
            value: formik.values[field]?.en?.title,
            onChange: formik.handleChange,
          }}
          addon={addonHeaderEn}
          error={formik.touched[field]?.en?.title && formik.errors[field]?.en?.title ? formik.errors[field]?.en?.title : ''}
        />
        <CustomInput
          label="Description (EN)"
          inputProps={{
            id: 'en-description',
            name: `${[field]}.en.description`,
            type: 'textarea',
            value: formik.values[field]?.en?.description,
            onChange: formik.handleChange,
          }}
          addon={addonContentEn}
          error={formik.touched[field]?.en?.description && formik.errors[field]?.en?.description ? formik.errors[field]?.en?.description : ''}
        />
      </Col>
    </Row>
  </>
);

type SEOTranslationPairFormProps = {
  formik: any;
  field: string;
  addonHeaderDe?: ReactNode;
  addonContentDe?: ReactNode;
  addonHeaderEn?: ReactNode;
  addonContentEn?: ReactNode;
  addonURL?: ReactNode;
};

export { SEOTranslationPairForm };
