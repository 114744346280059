import { Button, Col, FormFeedback, Row } from 'reactstrap';
import { getTimeframeRange, OrderTimeframe } from '../../../enums';
import { DAYS_GAP_BETWEEN_PICK_UP_ADN_DELIVERY, isDriverWorkingDay, resolveKey } from '../../../utils';
import moment from 'moment';
import DatePicker from '../../../components/Date/DatePicker';

const CreateOrderDateForm = ({ formik, isViewOnly = false, field, isPickupDate }: CreateOrderDateFormProps) => (
  <div className="d-flex flex-column gap-4">
    <Row>
      <Col>
        {isPickupDate && (
          <DatePicker
            label="Date*"
            highlightDates={formik.values.delivery?.date.date ? [new Date(formik.values.delivery?.date.date)] : []}
            placeholderText="Select pick-up date"
            notification="Only Tuesdays and Fridays are available for pick-up and delivery"
            dateFilter={isDriverWorkingDay}
            date={resolveKey(`${field}.date`, formik.values) ? new Date(resolveKey(`${field}.date`, formik.values)) : null}
            maxDate={
              formik.values.delivery?.date.date
                ? moment(formik.values.delivery?.date.date).subtract(DAYS_GAP_BETWEEN_PICK_UP_ADN_DELIVERY, 'days').toDate()
                : null
            }
            onChange={(date) => {
              if (!date) return;
              formik.setFieldValue(`${field}.date`, date);
              formik.setFieldValue(`${field}.day`, date.getDate());
              formik.setFieldValue(`${field}.month`, date.getMonth() + 1);
              formik.setFieldValue(`${field}.year`, date.getFullYear());
            }}
            error={resolveKey(`${field}.date`, formik.touched) && resolveKey(`${field}.date`, formik.errors) ? resolveKey(`${field}.date`, formik.errors) : ''}
            disabled={isViewOnly}
          />
        )}
        {!isPickupDate && (
          <DatePicker
            label="Date*"
            highlightDates={formik.values.pickup?.date.date ? [new Date(formik.values.pickup?.date.date)] : []}
            placeholderText="Select delivery date"
            notification="Only Tuesdays and Fridays are available for pick-up and delivery"
            dateFilter={isDriverWorkingDay}
            date={resolveKey(`${field}.date`, formik.values) ? new Date(resolveKey(`${field}.date`, formik.values)) : null}
            minDate={
              formik.values.pickup?.date.date ? moment(formik.values.pickup?.date.date).add(DAYS_GAP_BETWEEN_PICK_UP_ADN_DELIVERY, 'days').toDate() : null
            }
            onChange={(date) => {
              if (!date) return;
              formik.setFieldValue(`${field}.date`, date);
              formik.setFieldValue(`${field}.day`, date.getDate());
              formik.setFieldValue(`${field}.month`, date.getMonth() + 1);
              formik.setFieldValue(`${field}.year`, date.getFullYear());
            }}
            error={resolveKey(`${field}.date`, formik.touched) && resolveKey(`${field}.date`, formik.errors) ? resolveKey(`${field}.date`, formik.errors) : ''}
            disabled={isViewOnly}
          />
        )}
      </Col>
    </Row>
    <Row>
      <Col>
        <p className="mb-2">Timeframe*:</p>
        <Row>
          {Object.values(OrderTimeframe).map((val) => (
            <Col key={val}>
              <Button
                color="primary"
                className={`btn-md w-100 ${val.toLowerCase() === resolveKey(`${field}.timeframe`, formik.values)?.toLowerCase() ? 'checked' : ''}`}
                onClick={() => {
                  formik.setFieldValue(`${field}.timeframe`, val);
                }}
                disabled={isViewOnly}
                title={`${val} ${getTimeframeRange(val)}`}
              >
                {val}
              </Button>
            </Col>
          ))}
        </Row>
        {!!resolveKey(`${field}.timeframe`, formik.touched) && resolveKey(`${field}.timeframe`, formik.errors) && (
          <FormFeedback className="d-block" invalid>
            {resolveKey(`${field}.timeframe`, formik.errors)}
          </FormFeedback>
        )}
      </Col>
    </Row>
  </div>
);

type CreateOrderDateFormProps = {
  formik: any;
  isViewOnly?: boolean;
  isPickupDate: boolean;
  field: string;
};

export { CreateOrderDateForm };
