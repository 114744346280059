import * as yup from 'yup';
import { CreateAddress, CreateAddressSchema, initialAddress } from './address';
import { OrderTimeframe } from '../enums';

type OrderDate = {
  year: number;
  month: number;
  day: number;
  date: Date;
  timeframe: OrderTimeframe;
  unix: number;
};

const CreateOrderDateSchema = yup
  .object({
    year: yup.number().required('This field is required.'),
    month: yup.number().required('This field is required.'),
    day: yup.number().required('This field is required.'),
    date: yup.date().required('This field is required.'),
    timeframe: yup.mixed<OrderTimeframe>().oneOf(Object.values(OrderTimeframe)).required('This field is required.'),
    unix: yup.number().required('This field is required.'),
  })
  .defined();
type CreateOrderDate = yup.InferType<typeof CreateOrderDateSchema>;
const OrderDateAddressSchema = yup.object({
  date: CreateOrderDateSchema.required(),
  address: CreateAddressSchema.required(),
});
type OrderDateAddress = yup.InferType<typeof OrderDateAddressSchema>;

const initialOrderDate: CreateOrderDate = {
  year: 0,
  month: 0,
  day: 0,
  date: new Date(),
  timeframe: OrderTimeframe.MORNING,
  unix: 0,
};

const initialOrderDateAddress = (date?: CreateOrderDate, address?: CreateAddress): OrderDateAddress => ({
  date: date ?? initialOrderDate,
  address: address ?? initialAddress,
});

const RescheduleDateSchema = yup.object({
  pickup: OrderDateAddressSchema.required(),
  delivery: OrderDateAddressSchema.required(),
});

export { CreateOrderDateSchema, initialOrderDate, OrderDateAddressSchema, initialOrderDateAddress, RescheduleDateSchema };
export type { OrderDate, CreateOrderDate, OrderDateAddress };
