import { Table as BootstrapTable } from 'reactstrap';
import { ColumnDef, flexRender, getCoreRowModel, useReactTable } from '@tanstack/react-table';

type TableProps = {
  data: any[];
  columns: ColumnDef<any, any>[];
  className?: string;
  shortColumn?: boolean;
  valueColumn?: boolean;
};

const Table = ({ columns, data, className, shortColumn, valueColumn }: TableProps) => {
  const { getHeaderGroups, getRowModel } = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
    manualSorting: true,
    manualFiltering: true,
    manualPagination: true,
    enableSorting: false,
    enableColumnFilters: false,
    enableFilters: false,
  });
  const rowModel = getRowModel();
  const headerGroups = getHeaderGroups();

  return (
    <div className="table-details">
      <BootstrapTable responsive hover bordered className={`mb-4 w-100 table ${className}`}>
        <thead>
          <tr className="d-flex font-16">
            <th className={shortColumn ? 'flex-cell-left' : 'flex-cell'}>Label</th>
            <th className={shortColumn ? 'flex-cell-right' : 'flex-cell'}>{valueColumn ? 'Value' : 'Wert'}</th>
          </tr>
        </thead>
        <tbody className="d-flex flex-column">
          {!!headerGroups.length &&
            !!rowModel.rows.length &&
            rowModel.rows.map((row, index) => {
              const headerGroup = headerGroups[index];
              return row.getVisibleCells().map((cell, cellIndex) => {
                const header = headerGroup.headers[cellIndex];
                const isDriverCell = ['pickupDriver', 'deliveryDriver'].includes(header.id);

                return (
                  <tr key={`${header.id}${cell.id}${cellIndex}`} className="d-flex">
                    <td className={shortColumn ? 'flex-cell-left' : 'flex-cell'}>{flexRender(header.column.columnDef.header, header.getContext())}</td>
                    <td className={`flex-cell${shortColumn ? ' flex-cell-right' : ''}${isDriverCell ? ' py-0' : ''}`}>
                      {flexRender(cell.column.columnDef.cell, cell.getContext())}
                    </td>
                  </tr>
                );
              });
            })}
        </tbody>
      </BootstrapTable>
    </div>
  );
};

export default Table;
