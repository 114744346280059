enum Pages {
  MAIN = '/',
  DASHBOARD = '/dashboard',
  DRIVER = '/driver',
  SHOPKEEPER = '/shopkeeper',
  CRAFTSMAN = '/craftsman',
  ASSIGNMENTS = '/assignments',
  TRANSPORT = '/transport',
  ADMINS = '/admins',
  CREATE_ADMIN = '/admin/new',
  ADMIN_DETAILS = '/admins/:id',
  CUSTOMERS = '/customers',
  CREATE_CUSTOMER = '/customer/new',
  CUSTOMER_DETAILS = '/customers/:id',
  DRIVERS = '/drivers',
  CREATE_DRIVER = '/driver/new',
  DRIVER_DETAILS = '/drivers/:id',
  ESTIMATIONS = '/estimations',
  CREATE_ESTIMATION = '/estimation/new',
  ESTIMATION_DETAILS = '/estimations/:id',
  SHIPPING_ORDERS = '/shipping-orders',
  CREATE_SHIPPING_ORDER = '/shipping-orders/new',
  SHIPPING_ORDER_DETAILS = '/shipping-orders/:id',
  ORDERS = '/orders',
  CREATE_ORDER = '/order/new',
  ORDER_DETAILS = '/orders/:id',
  DISCOUNTS = '/discounts',
  CREATE_DISCOUNT = '/discount/new',
  DISCOUNT_DETAILS = '/discounts/:id',
  VOUCHERS = '/vouchers',
  CREATE_VOUCHERS = '/vouchers/new',
  VOUCHERS_DETAILS = '/vouchers/:id',
  PRODUCTS = '/products',
  CREATE_PRODUCT = '/product/new',
  PRODUCT_DETAILS = '/products/:id',
  CRAFTSMEN = '/craftsmen',
  CREATE_CRAFTSMAN = '/craftsman/new',
  CRAFTSMAN_DETAILS = '/craftsmen/:id',
  LOGIN = '/login',
  PASSWORD_RESET = '/reset-password',
  NOT_FOUND = '/not-found',
}

export { Pages };
