import React from 'react';
import ReactDOM from 'react-dom/client';
import ReduxToastr from 'react-redux-toastr';
import { Provider } from 'react-redux';
import { initializeApp } from 'firebase/app';
import App from './App';
import { store } from './store';
import { TOAST_TIME_OUT_MS } from './utils';
import 'react-redux-toastr/lib/css/react-redux-toastr.min.css';
import 'react-datepicker/dist/react-datepicker.css';
import './index.scss';

let options = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
};

(async () => {
  try {
    const response = await fetch('/__/firebase/init.json');
    options = await response.json();
  } catch (err) {
    if (err instanceof Error) {
      console.warn(`Could not load dynamic firebase options. Fall back to the default options: ${err.message}`);
    }
  }
})();

initializeApp(options);

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

root.render(
  <React.StrictMode>
    <Provider store={store}>
      <App />
      <ReduxToastr timeOut={TOAST_TIME_OUT_MS} position="top-right" preventDuplicates />
    </Provider>
  </React.StrictMode>
);
