import React, { FC } from 'react';
import { Times as TimesIcon } from '@styled-icons/typicons/Times';
import { Link } from 'react-router-dom';

interface BackDetailsLinkProps {
  path: string;
}

const BackDetailsLink: FC<BackDetailsLinkProps> = ({ path }) => {
  return (
    <Link className="btn-icon btn btn-secondary" to={path}>
      <TimesIcon className="icon" />
    </Link>
  );
};

export default BackDetailsLink;
