import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { initialState } from './initial-state';
import { extractStrictly } from '../../../../utils';
import { VoucherOrderState } from './type';

const extractShippingOrderStateSpecific = extractStrictly<VoucherOrderState>({
  selectedVoucherOrderId: true,
});
export const VoucherOrderSlice = createSlice({
  name: 'VoucherOrder Object',
  initialState,
  reducers: {
    setVoucherOrderData: (state: VoucherOrderState, action: PayloadAction<VoucherOrderState>) => {
      return extractShippingOrderStateSpecific(action.payload);
    },
  },
});
