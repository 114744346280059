import React from 'react';
import { Star } from '@styled-icons/entypo/Star';

interface StarRatingProps {
  rating: number;
}

const StarRating: React.FC<StarRatingProps> = ({ rating }) => {
  const stars = [];

  for (let i = 0; i < rating; i++) {
    stars.push(<Star key={i} className='stars-rating'/>);
  }

  return (
    <div className='stars-container'>
      {stars.map((star, index) => (
        <span key={index}>{star}</span>
      ))}
    </div>
  );
};

export default StarRating;