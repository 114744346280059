import React, { useEffect, useState } from 'react';
import { toastr } from 'react-redux-toastr';
import { Col, FormFeedback, Row, Spinner } from 'reactstrap';
import { Accept, useDropzone } from 'react-dropzone';
import { ImagePreview } from '../../../../components/ImagePreview/ImagePreview';
import { DownloadableImageNoUserId } from '../../../../types';
import { sizeValidator } from '../../../../utils';
import { CraftsmanProfileImagePreview } from '../CraftsmanProfileImagePreview/CraftsmanProfileImagePreview';
import { SimpleTranslationPairForm } from '../CreateSimpleTranslationForm';
import '../../../../styles/components/Upload.scss';
import ImageViewer from 'react-simple-image-viewer';
import Tooltip from '../../../../components/Tooltip';

const Upload = ({ formik, otherPictures, profilePictures, accept, craftsmanFullName, disabled }: UploadProps) => {
  const {
    maxSize: maxSizeProfile,
    maxFiles: maxFilesProfile,
    profilePicture,
    error: errorProfile,
    loadImages: loadProfileImages,
    onUpload: onProfileImageUpload,
    onDelete: onProfileImageDelete,
    onProfilePictureSelect,
  } = profilePictures;
  const {
    maxSize: maxSizeOther,
    maxFiles: maxFilesOther,
    error: errorOther,
    setAdditionalPicUrls,
    loadImages: loadOtherImages,
    onUpload: onOtherImageUpload,
    onDelete: onOtherImageDelete,
  } = otherPictures;

  const [isRemoving, setIsRemoving] = useState(false);
  const [isUploading, setIsUploading] = useState(false);
  const [otherFiles, setOtherFiles] = useState<DownloadableImageNoUserId[]>([]);
  const [profileFiles, setProfileFiles] = useState<DownloadableImageNoUserId[]>([]);
  const [imagesUrls, setImagesUrls] = useState<string[]>([]);
  const [currentImage, setCurrentImage] = useState<number>(0);
  const [isViewerOpen, setIsViewerOpen] = useState<boolean>(false);
  const jointDisabled = disabled || isUploading || isRemoving;
  const profileIsDisabled = jointDisabled || profileFiles.length === maxFilesProfile;
  const otherIsDisabled = jointDisabled || otherFiles.length === maxFilesOther;
  const { getRootProps: getProfileRootProps, getInputProps: getProfileInputProps } = useDropzone({
    accept,
    maxFiles: maxFilesProfile,
    disabled: profileIsDisabled,
    validator: (file) => sizeValidator(file, maxSizeProfile),
    onError: (err) => toastr.error(err.name, err.message),
    onDrop: async (acceptedFiles) => {
      await Promise.all(acceptedFiles.map((file) => onProfileImageUpload(file, setIsUploading)));
      const images: DownloadableImageNoUserId[] = await loadProfileImages();

      if (!images) {
        return null;
      }

      const filteredImages = images.filter((val) => !!val);
      if (filteredImages && filteredImages.length) {
        setProfileFiles(filteredImages);
      }
    },
  });
  const { getRootProps: getOtherRootProps, getInputProps: getOtherInputProps } = useDropzone({
    accept,
    maxFiles: maxFilesOther,
    disabled: otherIsDisabled,
    validator: (file) => sizeValidator(file, maxSizeOther),
    onError: (err) => toastr.error(err.name, err.message),
    onDrop: async (acceptedFiles) => {
      await Promise.all(acceptedFiles.map((file) => onOtherImageUpload(file, setIsUploading)));
      const images: DownloadableImageNoUserId[] = await loadOtherImages();

      if (!images) {
        return null;
      }

      const filteredImages = images.filter((val) => !!val);
      if (filteredImages && filteredImages.length) {
        setOtherFiles(filteredImages);
        setAdditionalPicUrls(filteredImages.map(({ url }) => url));
      }
    },
  });

  useEffect(() => {
    (async () => {
      if (!craftsmanFullName.trim().length) {
        return;
      }

      if (!profileIsDisabled) {
        const images: DownloadableImageNoUserId[] = await loadProfileImages();

        if (images) {
          const filteredImages = images.filter((val) => !!val);
          if (filteredImages && filteredImages.length) {
            setProfileFiles(filteredImages);
          }
        }
      }

      if (!otherIsDisabled) {
        const images: DownloadableImageNoUserId[] = await loadOtherImages();

        if (images) {
          const filteredImages = images.filter((val) => !!val);
          if (filteredImages && filteredImages.length) {
            setOtherFiles(filteredImages);
          }
        }
      }
    })();
  }, [craftsmanFullName, otherIsDisabled, profileIsDisabled]);

  const openImageViewer = (url: string, profilePics: boolean) => {
    let imagesUrls: string[];
    if (profilePics) {
      imagesUrls = profileFiles.map((image: DownloadableImageNoUserId) => decodeURI(image.url));
    } else {
      imagesUrls = otherFiles.map((image: DownloadableImageNoUserId) => decodeURI(image.url));
    }
    setImagesUrls(imagesUrls);
    setCurrentImage(imagesUrls.indexOf(decodeURI(url)));
    setIsViewerOpen(true);
  };

  const closeImageViewer = () => {
    setCurrentImage(0);
    setIsViewerOpen(false);
  };

  return (
    <>
      {isViewerOpen && (
        <ImageViewer
          src={imagesUrls}
          currentIndex={currentImage}
          disableScroll={false}
          closeOnClickOutside
          onClose={closeImageViewer}
          backgroundStyle={{
            backgroundColor: 'rgba(0,0,0 ,0.7)',
            zIndex: '31',
          }}
        />
      )}
      <Row className="gap-4">
        <Col md={4} className="d-flex flex-column gap-3">
          <div className="d-flex align-items-center gap-3">
            <h5 className="m-0">Profile Pictures</h5>
            <Tooltip placement="bottom" target="profile-tailor" rounded>
              <img src='/images/craftsman/craftsman-picture-profile.png' alt="Example" width={200} height={200} style={{objectFit: 'cover'}}/>
            </Tooltip>
          </div>
          <div {...getProfileRootProps({ className: `h-100 upload-zone ${profileIsDisabled ? 'disabled' : ''} ${errorProfile ? 'border-danger' : ''}` })}>
            <input {...getProfileInputProps({ className: 'dropzone', disabled: profileIsDisabled })} />
            <Col>
              {!profileIsDisabled && <p className="text-center">Drag 'n' drop images here, or click to select them. Maximum {maxFilesProfile} images</p>}
              {(isUploading || isRemoving) && (
                <Row className="success-label justify-content-center align-items-center">
                  <Col xs={8} md={3} className="loading d-flex flex-column align-items-center">
                    <Spinner width={20} color="secondary" />
                    <div className="sr-only">{isUploading ? 'Uploading...' : 'Removing...'}</div>
                  </Col>
                </Row>
              )}
              {disabled && (
                <h6 className="text-center">
                  Drag 'n' drop of images is disabled. You need to enter craftsman's full name first.
                  <br />
                  Be aware: all images are loaded to the folder named after the full name of the craftsman.
                </h6>
              )}
              {errorProfile && (
                <FormFeedback className="d-block text-center" invalid>
                  <h5>{errorProfile}</h5>
                </FormFeedback>
              )}
            </Col>
          </div>
        </Col>
        <Col className="d-flex flex-column gap-3">
          <div className="d-flex align-items-center gap-3">
            <h5 className="m-0">Pictures of Work</h5>
            <Tooltip placement="bottom" target="profile-pictures" rounded>
              <img src="/images/craftsman/craftsman-pictures-example.png" alt="Example" width={400} height={110} />
            </Tooltip>
          </div>
          <SimpleTranslationPairForm
            formik={formik}
            field="workPicturesTranslationKey"
            label="Pictures of work title"
            addonEn={
              <Tooltip placement="bottom" target="workPicturesTranslationKey-example-en">
                <img src="/images/craftsman/craftsman-pictures-label-example-en.png" alt="Example" width={400} height={110} />
              </Tooltip>
            }
            addonDe={
              <Tooltip placement="bottom" target="workPicturesTranslationKey-example-de">
                <img src="/images/craftsman/craftsman-pictures-label-example-de.png" alt="Example" width={400} height={110} />
              </Tooltip>
            }
          />
          <div {...getOtherRootProps({ className: `upload-zone h-100 ${otherIsDisabled ? 'disabled' : ''} ${errorProfile ? 'border-danger' : ''}` })}>
            <input {...getOtherInputProps({ className: 'dropzone', disabled: otherIsDisabled })} />
            <Col>
              {!otherIsDisabled && <p className="text-center">Drag 'n' drop images here, or click to select them. Maximum {maxFilesOther} images</p>}
              {(isUploading || isRemoving) && (
                <Row className="success-label justify-content-center align-items-center">
                  <Col xs={8} md={3} className="loading d-flex flex-column align-items-center">
                    <Spinner width={20} color="secondary" />
                    <div className="sr-only">{isUploading ? 'Uploading...' : 'Removing...'}</div>
                  </Col>
                </Row>
              )}
              {disabled && (
                <h6 className="text-center">
                  Drag 'n' drop of images is disabled. You need to enter craftsman's full name first.
                  <br />
                  Be aware: all images are loaded to the folder named after the full name of the craftsman.
                </h6>
              )}
              {errorOther && (
                <FormFeedback className="d-block text-center" invalid>
                  {Array.isArray(errorOther) && errorOther.map((err) => <h5 key={err}>{err}</h5>)}
                  {!Array.isArray(errorOther) && <h5>{errorOther}</h5>}
                </FormFeedback>
              )}
            </Col>
          </div>
        </Col>
      </Row>
      <Row>
        <Col md={4}>
          {!!profileFiles.length && (
            <Row className="pt-4 preview-box">
              {profileFiles.map((file) => (
                <CraftsmanProfileImagePreview
                  key={file.url}
                  src={file.url}
                  profilePicture={profilePicture ? profilePicture : ''}
                  alt={file.name}
                  disabled={profileIsDisabled}
                  onSelect={() => onProfilePictureSelect(file.url)}
                  onDelete={async () => {
                    await onProfileImageDelete(file.Key, setIsRemoving);
                    const images: DownloadableImageNoUserId[] = await loadProfileImages();
                    const filteredImages = images.filter((val) => !!val);
                    if (filteredImages && filteredImages.length) {
                      setProfileFiles(filteredImages);
                    }
                  }}
                  onImageClick={(e) => openImageViewer((e.target as HTMLInputElement).src, true)}
                />
              ))}
            </Row>
          )}
        </Col>
        <Col>
          {!!otherFiles.length && (
            <Row className="pt-4 preview-box">
              {otherFiles.map((file) => (
                <ImagePreview
                  key={file.url}
                  src={file.url}
                  alt={file.name}
                  colWidth={{ xs: 12, md: 6, lg: 4 }}
                  disabled={otherIsDisabled}
                  onDelete={async () => {
                    await onOtherImageDelete(file.Key, setIsRemoving);
                    const images: DownloadableImageNoUserId[] = await loadOtherImages();
                    const filteredImages = images.filter((val) => !!val);
                    if (filteredImages && filteredImages.length) {
                      setOtherFiles(filteredImages);
                    }
                  }}
                  onImageClick={(e) => openImageViewer((e.target as HTMLInputElement).src, false)}
                />
              ))}
            </Row>
          )}
        </Col>
      </Row>
    </>
  );
};

type OtherPicturesUploadProps = {
  maxSize: number;
  maxFiles: number;
  error: string | string[];
  setAdditionalPicUrls: (urls: string[]) => void;
  loadImages: () => Promise<DownloadableImageNoUserId[]>;
  onUpload: (image: File, setLoading: (state: boolean) => void) => Promise<void>;
  onDelete: (filename: string, setDeleting: (state: boolean) => void) => Promise<void>;
};

type ProfilePicturesUploadProps = {
  maxSize: number;
  maxFiles: number;
  profilePicture: string | undefined;
  error: string;
  loadImages: () => Promise<DownloadableImageNoUserId[]>;
  onUpload: (image: File, setLoading: (state: boolean) => void) => Promise<void>;
  onDelete: (filename: string, setDeleting: (state: boolean) => void) => Promise<void>;
  onProfilePictureSelect: (url: string) => void;
};

type UploadProps = {
  otherPictures: OtherPicturesUploadProps;
  profilePictures: ProfilePicturesUploadProps;
  accept: Accept;
  formik: any;
  disabled: boolean;
  craftsmanFullName: string;
};

export { Upload };
