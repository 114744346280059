import { useState } from 'react';
import { Button, Collapse, Input, List, ListInlineItem } from 'reactstrap';
import { DropdownListProps, ProductType } from './types';
import { ProductSearchMatch } from '../../../../enums';

const DropdownList = ({ selectedRepairs, onRepairSelect, type, matchType }: DropdownListProps & { type: ProductType; matchType: ProductSearchMatch }) => {
  const [isOpen, setIsOpen] = useState(matchType === ProductSearchMatch.PRODUCTS);

  const toggle = () => setIsOpen(!isOpen);

  return (
    <ListInlineItem className="w-100">
      <Button color="primary" onClick={toggle} className="d-flex justify-content-between align-items-center w-100 collapse-toggle dropdown-toggle">
        <span>{type.name?.de}</span>
      </Button>
      <Collapse isOpen={isOpen}>
        <List>
          {type.products &&
            !!type.products.length &&
            type.products.map((product) => (
              <ListInlineItem
                key={product._id}
                className="w-100 d-flex justify-content-between check-item"
                onClick={() => onRepairSelect(product)}
                role="button"
                aria-label="Select product"
              >
                <span>{product.name?.de}</span>
                <Input
                  aria-label="Select product"
                  title="Select product"
                  type="checkbox"
                  className="checkbox"
                  checked={selectedRepairs.some((repair) => repair.product._id === product._id)}
                  onChange={() => onRepairSelect(product)}
                />
              </ListInlineItem>
            ))}
        </List>
      </Collapse>
    </ListInlineItem>
  );
};

export { DropdownList };
