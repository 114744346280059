import { ReactElement, ReactNode, useState } from 'react';
import { Button, Tooltip as BootstrapTooltip } from 'reactstrap';
import * as React from 'react';
import { CircleInfo as CircleInfoIcon } from '@styled-icons/fa-solid/CircleInfo';
import './Tootltip.scss';

const Tooltip = ({ children, placement, target, rounded = false }: TooltipProps) => {
  const [tooltipOpen, setTooltipOpen] = useState(false);

  const toggle = () => setTooltipOpen(!tooltipOpen);

  return (
    <>
      {!!rounded && (
        <div>
          <Button id={target} type="button" className="rounded-circle p-2" color="primary">
            <CircleInfoIcon className="icon primary" width={20} height={20} title="Details" />
          </Button>
          <BootstrapTooltip placement={placement} isOpen={tooltipOpen} target={target} toggle={toggle} trigger="click" autohide={false} fade flip>
            {children}
          </BootstrapTooltip>
        </div>
      )}
      {!rounded && (
        <>
          <Button id={target} className="btn-addon" type="button" color="primary">
            <CircleInfoIcon className="icon primary" width={20} height={20} title="Details" />
          </Button>
          <BootstrapTooltip placement={placement} isOpen={tooltipOpen} target={target} toggle={toggle} trigger="click" autohide={false} fade flip>
            {children}
          </BootstrapTooltip>
        </>
      )}
    </>
  );
};

type TooltipProps = {
  children: string | ReactNode | ReactElement;
  placement:
    | 'top-start'
    | 'top-end'
    | 'bottom-start'
    | 'bottom-end'
    | 'right-start'
    | 'right-end'
    | 'left-start'
    | 'left-end'
    | 'auto'
    | 'auto-start'
    | 'auto-end'
    | 'left'
    | 'right'
    | 'bottom'
    | 'top';
  target: string;
  rounded?: boolean;
};

export { Tooltip };
