const resolveKey = (path: string | string[], obj: any, separator = '.') => {
  const properties = Array.isArray(path) ? path : path.split(separator);
  return properties.reduce((prev, curr) => prev?.[curr], obj);
};

const resolveArrayItemKey = (path: string, obj: any) => {
  const match = path.match(/(\w+)(?:\[(\d+)\])?(.*)/);
  if (match) {
    const key = match[1];
    const index = parseInt(match[2]);
    const properties = match[3] ? match[3].split('.').filter((val) => !!val) : [];
    let value = obj?.[key];
    if (index !== null) {
      value = value?.[index];
    }
    for (let i = 0; i < properties.length; i++) {
      value = value?.[properties[i]];
    }
    return value;
  } else {
    return null;
  }
};

export { resolveKey, resolveArrayItemKey };
