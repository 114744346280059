import { createColumnHelper } from '@tanstack/react-table';
import moment from 'moment';
import { Voucher } from '../../../../types/vouchers';

const columnHelper = createColumnHelper<Voucher>();

const orderColumns = () => [
  columnHelper.accessor('number', {
    cell: (info) => info.getValue(),
    header: () => <span>Number</span>,
    enableColumnFilter: false,
    enableSorting: false,
  }),
  columnHelper.accessor('priceCent', {
    cell: (info) => info.getValue() / 100,
    header: () => <span>Monetary value</span>,
    size: 340,
  }),
  columnHelper.accessor('code', {
    cell: (info) => info.getValue(),
    header: () => <span>Code</span>,
    size: 340,
  }),
  columnHelper.accessor('createdAt', {
    cell: (info) => moment(info.getValue()).format('DD-MM-YYYY'),
    header: () => <span>Creation Date</span>,
    size: 430,
  }),
  columnHelper.accessor('deadline', {
    cell: (info) => moment(info.getValue()).format('DD-MM-YYYY'),
    header: () => <span>Expiration Date</span>,
    size: 430,
  }),
  columnHelper.accessor('paymentParams.method', {
    cell: (info) => info.getValue(),
    header: () => <span>Payment method</span>,
    enableColumnFilter: false,
    enableSorting: false,
  }),
  columnHelper.accessor('paymentParams.status', {
    cell: (info) => info.getValue(),
    header: () => <span>Payment</span>,
    size: 340,
  }),
  columnHelper.accessor('active', {
    cell: (info) => {
      const status = info.getValue();
      return status ? 'Active' : 'Inactive';
    },
    header: () => <span>Status</span>,
    size: 340,
  }),
  columnHelper.accessor('status', {
    cell: (info) => info.getValue(),
    header: () => <span>Status</span>,
    size: 340,
  }),
  columnHelper.accessor('paymentParams.referenceNumber', {
    cell: (info) => info.getValue(),
    header: () => <span>Payment ref. number</span>,
    enableColumnFilter: false,
    enableSorting: false,
  }),
];

const contactInformation = () => [
  columnHelper.accessor('customer.phoneNumber', {
    cell: (info) => info.getValue(),
    header: () => <span>Phone Number</span>,
    enableColumnFilter: false,
    enableSorting: false,
  }),
  columnHelper.accessor('customer.email', {
    cell: (info) => info.getValue(),
    header: () => <span>Email</span>,
    enableColumnFilter: false,
    enableSorting: false,
  }),
  columnHelper.accessor('customer.firstName', {
    cell: (info) => info.getValue(),
    header: () => <span>Name</span>,
    enableColumnFilter: false,
    enableSorting: false,
  }),
  columnHelper.accessor('customer.lastName', {
    cell: (info) => info.getValue(),
    header: () => <span>Last Name</span>,
    enableColumnFilter: false,
    enableSorting: false,
  }),
];

export { orderColumns, contactInformation };
