import { useEffect, useState } from 'react';
import { Button, Col, Form, List, ListInlineItem, Row, Spinner } from 'reactstrap';
import { toastr } from 'react-redux-toastr';
import { Formik, FormikTouched, setNestedObjectValues } from 'formik';
import { useNavigate, useParams } from 'react-router';
import * as yup from 'yup';
import { apiRequest, handleError, TOAST_IMPORTANT_TIME_OUT_MS } from '../../utils';
import { CustomInput } from '../../components/Input/CustomInput';
import { DiscountType, Pages, PaymentMethod, PaymentStatus } from '../../enums';
import moment from 'moment';
import { CustomerSearchForm } from '../Orders/components/CustomerSearchForm/CustomerSearchForm';
import { Customer } from '../../types';
import DatePicker from '../../components/Date/DatePicker';
import { PaymentParamsForm } from '../Orders/components/PaymentParamsForm';
import BackDetailsLink from '../../components/BackDetailsLink/BackDetailsLink';

type DiscountDetailsProps = {
  type?: 'new' | 'existing';
};

const MIN = 0;

const VoucherSchema = yup.object({
  _id: yup.string().optional().nullable(),
  number: yup.string().optional().nullable(),
  code: yup
    .string()
    .matches(/^[a-zA-Z0-9äöüÄÖÜ_-]*$/, 'Wrong symbol, special characters not allowed. Use alphanumeric characters, hyphens and underscores.')
    .required('This field is required.'),
  priceCent: yup.number(),
  customerId: yup.string().required('This field is required.'),
  createdAt: yup.date().nullable().default(new Date()).required('This field is required.'),
  active: yup.boolean(),
  paymentParams: yup
    .object()
    .shape(
      {
        referenceNumber: yup.string().when(['method', 'status'], {
          is: (method: PaymentMethod, status: PaymentStatus) => method || status,
          then: yup.string().required('This field is required.'),
        }),
        method: yup
          .mixed<PaymentMethod>()
          .oneOf(Object.values(PaymentMethod))
          .when(['referenceNumber', 'status'], {
            is: (referenceNumber: string, status: PaymentStatus) => referenceNumber || status,
            then: yup.mixed<PaymentMethod>().oneOf(Object.values(PaymentMethod)).required('This field is required.'),
          }),
        status: yup
          .mixed<PaymentStatus>()
          .oneOf(Object.values(PaymentStatus))
          .when(['method', 'referenceNumber'], {
            is: (method: PaymentMethod, referenceNumber: string) => method || referenceNumber,
            then: yup.mixed<PaymentStatus>().oneOf(Object.values(PaymentStatus)).required('This field is required.'),
          }),
      },
      [
        ['method', 'status'],
        ['referenceNumber', 'status'],
        ['method', 'referenceNumber'],
      ]
    )
    .nullable(),
});
type VoucherSchemaType = yup.InferType<typeof VoucherSchema>;

const VoucherDetails = ({ type = 'new' }: DiscountDetailsProps) => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [values, setValues] = useState<VoucherSchemaType>({
    _id: '',
    number: '',
    code: '',
    priceCent: 0,
    customerId: '',
    createdAt: new Date(),
    paymentParams: null,
    active: true,
  });
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (type === 'new') {
      return;
    }
    onLoad();
  }, [id]);

  const enableLoading = () => {
    setLoading(true);
  };
  const disableLoading = () => {
    setLoading(false);
  };

  const onLoad = async () => {
    const abortController = new AbortController();
    try {
      enableLoading();
      const { data } = await apiRequest(`/vouchers/${id}`, { signal: abortController.signal });
      const { message } = data;

      if (message) {
        if (Array.isArray(message)) {
          message.forEach((msg) => toastr.error('Error', msg, { timeOut: TOAST_IMPORTANT_TIME_OUT_MS }));
        } else {
          toastr.error('Error', message, { timeOut: TOAST_IMPORTANT_TIME_OUT_MS });
        }
        abortController.abort();
        return disableLoading();
      }
      setValues(data);
      disableLoading();
    } catch (err) {
      handleError('FETCH_DISCOUNT', err);
      if (err instanceof DOMException && err.name === 'AbortError') {
        toastr.error('Error', 'Your browser aborted the request', { timeOut: TOAST_IMPORTANT_TIME_OUT_MS });
      } else {
        toastr.error('Error', 'Failed to fetch vouchers by ID', { timeOut: TOAST_IMPORTANT_TIME_OUT_MS });
      }
      abortController.abort();
    }
  };

  const onCreate = async (values: VoucherSchemaType, action: any) => {
    const abortController = new AbortController();
    try {
      enableLoading();
      const valuesCopy = { ...values };
      delete valuesCopy._id;
      delete valuesCopy.number;
      const { data } = await apiRequest('/vouchers', { method: 'POST', data: JSON.stringify({ ...valuesCopy }) });
      const { message } = data;

      if (message) {
        if (Array.isArray(message)) {
          message.forEach((msg) => toastr.error('Error', msg, { timeOut: TOAST_IMPORTANT_TIME_OUT_MS }));
        } else {
          toastr.error('Error', message, { timeOut: TOAST_IMPORTANT_TIME_OUT_MS });
        }
        action.setSubmitting(false);
        abortController.abort();
        return disableLoading();
      }
      disableLoading();
      toastr.success('Success', 'Voucher created');
      navigate(Pages.VOUCHERS);
    } catch (err) {
      handleError('CREATE_DISCOUNT', err);
      if (err instanceof DOMException && err.name === 'AbortError') {
        toastr.error('Error', 'Your browser aborted the request', { timeOut: TOAST_IMPORTANT_TIME_OUT_MS });
      } else {
        toastr.error('Error', 'Failed to create voucher', { timeOut: TOAST_IMPORTANT_TIME_OUT_MS });
      }
      abortController.abort();
    }
  };

  const onUpdate = async (values: VoucherSchemaType, action: any) => {
    const abortController = new AbortController();
    try {
      enableLoading();
      const valuesCopy = { ...values };
      delete valuesCopy._id;
      delete valuesCopy.number;
      const { data } = await apiRequest(`/vouchers/${values._id}`, {
        method: 'PUT',
        data: JSON.stringify({ ...valuesCopy }),
      });
      const { message } = data;

      if (message) {
        if (Array.isArray(message)) {
          message.forEach((msg) => toastr.error('Error', msg, { timeOut: TOAST_IMPORTANT_TIME_OUT_MS }));
        } else {
          toastr.error('Error', message, { timeOut: TOAST_IMPORTANT_TIME_OUT_MS });
        }
        action.setSubmitting(false);
        return disableLoading();
      }
      disableLoading();
      toastr.success('Success', 'Voucher updated');
      navigate(Pages.VOUCHERS_DETAILS);
    } catch (err) {
      handleError('UPDATE_DISCOUNT', err);
      if (err instanceof DOMException && err.name === 'AbortError') {
        toastr.error('Error', 'Your browser aborted the request', { timeOut: TOAST_IMPORTANT_TIME_OUT_MS });
      } else {
        toastr.error('Error', 'Failed to update voucher', { timeOut: TOAST_IMPORTANT_TIME_OUT_MS });
      }
      abortController.abort();
    }
  };

  const onSubmit = async (formik: any) => {
    const formikErrors = await formik.validateForm();
    if (Object.keys(formikErrors).length) {
      formik.setTouched(setNestedObjectValues<FormikTouched<VoucherSchemaType>>(formikErrors, true));
      return null;
    }

    if (type === 'new') {
      await onCreate(formik.values, {
        setSubmitting: formik.setSubmitting,
      });
    } else if (type === 'existing') {
      await onUpdate(formik.values, {
        setSubmitting: formik.setSubmitting,
      });
    }
  };

  return (
    <Col className="mh-100 overflow-auto px-3">
      <Row>
        <Col>
          <h3 className="text-secondary mb-3">Voucher Details</h3>
        </Col>
        <Col className="d-flex justify-content-end">
          <BackDetailsLink path={Pages.VOUCHERS} />
        </Col>
      </Row>
      <Formik enableReinitialize initialValues={values} validationSchema={VoucherSchema} onSubmit={onSubmit}>
        {(formik) => {
          const onCustomerSelect = (customer: Customer) => {
            formik.setFieldValue(`customerId`, customer._id);
          };

          return (
            <Form id="create-discount" className="d-flex flex-column gap-4">
              <CustomerSearchForm formik={formik} selectedCustomerId={formik.values.customerId} onCustomerSelect={onCustomerSelect} disabled={false} />
              <Row>
                <DatePicker
                  label="Created At*"
                  placeholderText="Select creation date"
                  date={formik.values.createdAt ? moment(formik.values.createdAt, 'DD-MM-YYYY').toDate() : null}
                  onChange={(date) => formik.setFieldValue('createdAt', date)}
                  error={formik.touched.createdAt && formik.errors.createdAt ? (formik.errors.createdAt as string) : ''}
                />
              </Row>
              <Row>
                <Col>
                  <CustomInput
                    label="Code"
                    inputProps={{
                      id: 'code',
                      name: 'code',
                      type: 'text',
                      value: formik.values.code,
                      onChange: formik.handleChange,
                    }}
                    error={formik.touched.code && formik.errors.code ? formik.errors.code : ''}
                  />
                </Col>
                <Row>
                  <CustomInput
                    label="Value €"
                    inputProps={{
                      id: 'priceCent',
                      name: 'priceCent',
                      type: 'number',
                      min: MIN,
                      value: formik.values.priceCent ? formik.values.priceCent / 100 : '',
                      onChange: formik.handleChange,
                    }}
                    error={formik.touched.priceCent && formik.errors.priceCent ? formik.errors.priceCent : ''}
                  />
                </Row>
                <Col md={3}>
                  <Row>
                    <p className="mb-2">Active or innactive voucher</p>
                  </Row>
                  <List className="p-0">
                    <ListInlineItem>
                      <Button className={`btn-md ${formik.values.active ? 'checked' : ''}`} onClick={() => formik.setFieldValue('active', true)}>
                        Active
                      </Button>
                    </ListInlineItem>
                    <ListInlineItem>
                      <Button className={`btn-md ${!formik.values.active ? 'checked' : ''}`} onClick={() => formik.setFieldValue('active', false)}>
                        Inactive
                      </Button>
                    </ListInlineItem>
                  </List>
                </Col>
              </Row>
              <Row>
                <PaymentParamsForm formik={formik} isViewOnly={false} />
              </Row>
              <Row className="mt-5">
                <Col xs={12} md={{ offset: 4, size: 4 }} xxl={{ offset: 5, size: 2 }}>
                  <Button color="primary" type="button" className="w-100 btn-md" disabled={loading} onClick={() => onSubmit(formik)}>
                    {type === 'new' ? 'Create' : 'Update'} Voucher {loading && <Spinner size="sm" color="secondary" />}
                  </Button>
                </Col>
              </Row>
            </Form>
          );
        }}
      </Formik>
    </Col>
  );
};

export { VoucherDetails };
