import { ReactNode } from 'react';
import { Button, Col } from 'reactstrap';
import { Delete as DeleteIcon } from '@styled-icons/fluentui-system-filled/Delete';
import './CraftsmanProfileImagePreview.scss';

const CraftsmanProfileImagePreview = ({
  src,
  alt,
  onDelete,
  onSelect,
  profilePicture,
  disabled,
  children,
  onImageClick,
}: CraftsmanProfileImagePreviewProps) => (
  <Col xs={12} className="mb-5 d-flex flex-column gap-3">
    {children}
    <Button className="border-0" onClick={onImageClick}>
      <img src={src} alt={alt} className="thumb-img" height="100%" />
    </Button>
    {!disabled && (
      <div className="d-flex justify-content-center flex-column gap-2 text-white">
        <Button color="secondary" className={`w-100 btn-md ${src === profilePicture ? 'checked' : ''}`} onClick={onSelect} disabled={disabled}>
          Set as profile photo
        </Button>
        <Button color="danger" className="w-100" onClick={onDelete} disabled={disabled}>
          Delete photo <DeleteIcon className="icon danger" />
        </Button>
      </div>
    )}
  </Col>
);

type CraftsmanProfileImagePreviewProps = {
  src: string;
  profilePicture: string;
  alt: string;
  disabled: boolean;
  onSelect: () => void;
  onDelete: () => void;
  children?: ReactNode;
  onImageClick?: (e: any) => void;
};

export { CraftsmanProfileImagePreview };
