import { Col, Dropdown, DropdownItem, DropdownMenu, DropdownToggle, FormFeedback, Label, Row } from 'reactstrap';
import { CustomInput } from '../../../components/Input/CustomInput';
import { PaymentMethod, PaymentStatus } from '../../../enums';
import { useState } from 'react';

const PaymentParamsForm = ({ formik, isViewOnly = false }: PaymentParamsFormProps) => {
  const [paymentMethodOpen, setPaymentMethodOpen] = useState(false);
  const [paymentStatusOpen, setPaymentStatusOpen] = useState(false);

  const togglePaymentStatus = () => setPaymentStatusOpen(!paymentStatusOpen);
  const togglePaymentMethod = () => setPaymentMethodOpen(!paymentMethodOpen);

  return (
    <div className="d-flex flex-column gap-4">
      <Row>
        <Col>
          <CustomInput
            label="Reference Number"
            inputProps={{
              id: 'referenceNumber',
              name: 'paymentParams.referenceNumber',
              type: 'text',
              placeholder: 'Invoice number etc.',
              value: formik.values.paymentParams?.referenceNumber,
              onChange: formik.handleChange,
              disabled: isViewOnly,
            }}
            error={
              formik.touched.paymentParams?.referenceNumber && formik.errors.paymentParams?.referenceNumber ? formik.errors.paymentParams?.referenceNumber : ''
            }
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <Label>Payment Method</Label>
          <Dropdown isOpen={paymentMethodOpen} toggle={togglePaymentMethod} disabled={isViewOnly}>
            <DropdownToggle
              caret
              className={`d-flex dropdown-toggle justify-content-between align-items-center w-100 form-control ${
                formik.touched.paymentParams?.method && formik.errors.paymentParams?.method ? 'border-danger is-invalid' : ''
              }`}
              disabled={isViewOnly}
            >
              {formik.values?.paymentParams?.method || 'Select method'}
            </DropdownToggle>
            <DropdownMenu className="w-100">
              {Object.values(PaymentMethod).map((val) => (
                <DropdownItem
                  key={val}
                  className={val.toLowerCase() === formik.values.paymentParams?.method?.toLowerCase() ? 'checked' : ''}
                  onClick={() => {
                    formik.setFieldValue('paymentParams.method', val);
                  }}
                >
                  {val}
                </DropdownItem>
              ))}
            </DropdownMenu>
          </Dropdown>
          {!!formik.touched.paymentParams?.method && formik.errors.paymentParams?.method && (
            <FormFeedback className="d-block" invalid>
              {formik.errors.paymentParams?.method.toString()}
            </FormFeedback>
          )}
        </Col>
        <Col>
          <Label>Payment Status</Label>
          <Dropdown isOpen={paymentStatusOpen} toggle={togglePaymentStatus} disabled={isViewOnly}>
            <DropdownToggle
              caret
              className={`d-flex dropdown-toggle justify-content-between align-items-center w-100 form-control ${
                formik.touched.paymentParams?.status && formik.errors.paymentParams?.status ? 'border-danger is-invalid' : ''
              }`}
              disabled={isViewOnly}
            >
              {formik.values?.paymentParams?.status || 'Select status'}
            </DropdownToggle>
            <DropdownMenu className="w-100">
              {Object.values(PaymentStatus).map((val) => (
                <DropdownItem
                  key={val}
                  className={val.toLowerCase() === formik.values.paymentParams?.status?.toLowerCase() ? 'checked' : ''}
                  onClick={() => {
                    formik.setFieldValue('paymentParams.status', val);
                  }}
                >
                  {val}
                </DropdownItem>
              ))}
            </DropdownMenu>
          </Dropdown>
          {!!formik.touched.paymentParams?.status && formik.errors.paymentParams?.status && (
            <FormFeedback className="d-block" invalid>
              {formik.errors.paymentParams?.status.toString()}
            </FormFeedback>
          )}
        </Col>
      </Row>
    </div>
  );
};

type PaymentParamsFormProps = {
  formik: any;
  isViewOnly?: boolean;
};

export { PaymentParamsForm };
