import React, { useState } from 'react';
import { Button, Col, Collapse, Row } from 'reactstrap';
import { Contact, Craftsman } from '../../../../types';
import { ArrowDropDown } from '@styled-icons/material/ArrowDropDown';
import StarRating from '../../components/StarRating';

const extractOperatorName = ({ firstName, lastName }: { firstName: string; lastName: string } & any) => `${firstName} ${lastName}`;
const extractCraftsmanContacts = (craftsman: Craftsman, contactField: keyof Contact) => craftsman.addressSecondary?.contact![contactField];
const craftsmanAddressToString = (craftsman: Craftsman) => {
  const { country, city, state, street, workingHours } = craftsman.addressSecondary;
  const stateParsed = state ? `${state}, ` : '';
  const workingHoursParsed = workingHours ? ` ${workingHours}` : '';

  return `${country}, ${stateParsed}${city}, ${street}${workingHoursParsed}`;
};

const CraftsmanCell = ({ craftsmen }: CraftsmanCellProps) => {
  const [selectedCraftsman, setSelectedCraftsman] = useState<null | Craftsman['_id']>(null);

  const showDetails = (_id: Craftsman['_id']) => setSelectedCraftsman(selectedCraftsman === _id ? null : _id);

  return (
    <Row className="gx-0 w-100">
      {craftsmen.map((craftsman) => (
        <div key={craftsman._id}>
          <Row className="gx-0">
            <Button color="primary" className={`link-button ${selectedCraftsman === craftsman._id ? 'active' : ''}`} onClick={() => showDetails(craftsman._id)}>
              {extractOperatorName(craftsman.addressSecondary.contact)}
              <ArrowDropDown className="icon" />
            </Button>
          </Row>
          <Row className="gx-0 my-2">
            <Collapse isOpen={selectedCraftsman === craftsman._id}>
              <Col className="d-flex flex-column gap-2">
                <p>
                  <span>Name: </span>
                  <span>{extractOperatorName(craftsman.addressSecondary.contact)}</span>
                </p>
                <p>
                  <span>Email: </span>
                  <span>{extractCraftsmanContacts(craftsman, 'email')}</span>
                </p>
                <p>
                  <span>Phone number #1: </span>
                  <span>{extractCraftsmanContacts(craftsman, 'phoneNumber')}</span>
                </p>
                <p>
                  <span>Phone number #2: </span>
                  <span>{extractCraftsmanContacts(craftsman, 'phoneNumber2')}</span>
                </p>
                <p>
                  <span>Address #1: </span>
                  <span>{craftsman.addressPrimary}</span>
                </p>
                <p>
                  <span>Address #2: </span>
                  <span>{craftsmanAddressToString(craftsman)}</span>
                </p>
                <p>
                  <span>Type: </span>
                  <span>{craftsman.type}</span>
                </p>
                {craftsman.rating[0] && (
                  <>
                    {!!craftsman.rating[0].tailorRating && (
                      <p className="d-flex align-items-center gap-2">
                        <span>Rank: </span>
                        <StarRating rating={craftsman.rating[0].tailorRating} />
                      </p>
                    )}
                    {!!craftsman.rating[0].tailorComment && (
                      <p>
                        <span>Comment: </span>
                        <span>{craftsman.rating[0].tailorComment}</span>
                      </p>
                    )}
                  </>
                )}
              </Col>
            </Collapse>
          </Row>
        </div>
      ))}
    </Row>
  );
};

type CraftsmanCellProps = {
  craftsmen: Craftsman[];
};

export { CraftsmanCell };
