import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { initialState } from './initial-state';
import { ShippingOrderState } from './type';
import { extractStrictly } from '../../../../utils';

const extractShippingOrderStateSpecific = extractStrictly<ShippingOrderState>({
  selectedShippingOrderId: true,
});
export const ShippingOrderSlice = createSlice({
  name: 'ShippingOrder Object',
  initialState,
  reducers: {
    setShippingOrderData: (state: ShippingOrderState, action: PayloadAction<ShippingOrderState>) => {
      return extractShippingOrderStateSpecific(action.payload);
    },
  },
});
