import { useState } from 'react';
import { Button, Collapse, List, ListInlineItem } from 'reactstrap';
import { DropdownList } from './DropdownList';
import { DropdownListProps, ProductCategory } from './types';
import { ProductSearchMatch } from '../../../../enums';

const ProductSearchDropdown = ({
  category,
  selectedRepairs,
  onRepairSelect,
  matchType,
}: DropdownListProps & { category: ProductCategory; matchType: ProductSearchMatch }) => {
  const [isOpen, setIsOpen] = useState([ProductSearchMatch.TYPES, ProductSearchMatch.PRODUCTS].includes(matchType));

  const toggle = () => setIsOpen(!isOpen);

  return (
    <ListInlineItem key={category._id} className="w-100">
      <Button color="primary" onClick={toggle} className="d-flex justify-content-between align-items-center w-100 collapse-toggle dropdown-toggle">
        <span>{category.name?.de}</span>
      </Button>
      <Collapse isOpen={isOpen}>
        <List>
          {category.types &&
            !!category.types.length &&
            category.types.map((type) => (
              <DropdownList key={type._id} type={type} selectedRepairs={selectedRepairs} onRepairSelect={onRepairSelect} matchType={matchType} />
            ))}
        </List>
      </Collapse>
    </ListInlineItem>
  );
};

export { ProductSearchDropdown };
