import { Col, Row } from 'reactstrap';
import { CustomInput } from '../Input/CustomInput';
import { resolveKey } from '../../utils';

const CreateAddressForm = ({ formik, isViewOnly = false, field, hasWorkingHours = false, isOrderForm = false }: CreateAddressFormProps) => {
  return (
    <div>
      <Row className="mb-2">
        <Col md={6}>
          <CustomInput
            label="Street*"
            inputProps={{
              id: `${[field]}.street`,
              name: `${[field]}.street`,
              type: 'text',
              value: resolveKey(`${field}.street`, formik.values),
              onChange: formik.handleChange,
              disabled: isViewOnly,
            }}
            error={
              resolveKey(`${field}.street`, formik.touched) && resolveKey(`${field}.street`, formik.errors) ? resolveKey(`${field}.street`, formik.errors) : ''
            }
          />
        </Col>
        <Col md={6} className="mb-2">
          <CustomInput
            label="Zip Code*"
            inputProps={{
              id: `${[field]}.zipCode`,
              name: `${[field]}.zipCode`,
              type: 'text',
              value: resolveKey(`${field}.zipCode`, formik.values),
              onChange: formik.handleChange,
              disabled: isViewOnly,
            }}
            error={
              resolveKey(`${field}.zipCode`, formik.touched) && resolveKey(`${field}.zipCode`, formik.errors)
                ? resolveKey(`${field}.zipCode`, formik.errors)
                : ''
            }
          />
        </Col>
      </Row>
      <Row>
        <Col md={6} className="mb-2">
          <CustomInput
            label="City*"
            inputProps={{
              id: `${[field]}.city`,
              name: `${[field]}.city`,
              type: 'text',
              value: resolveKey(`${field}.city`, formik.values),
              onChange: formik.handleChange,
              disabled: isViewOnly,
            }}
            error={resolveKey(`${field}.city`, formik.touched) && resolveKey(`${field}.city`, formik.errors) ? resolveKey(`${field}.city`, formik.errors) : ''}
          />
        </Col>
        <Col md={6} className="mb-2">
          <CustomInput
            label="Country*"
            inputProps={{
              id: `${[field]}.country`,
              name: `${[field]}.country`,
              type: 'text',
              value: resolveKey(`${field}.country`, formik.values),
              onChange: formik.handleChange,
              disabled: isOrderForm || isViewOnly,
            }}
            error={
              resolveKey(`${field}.country`, formik.touched) && resolveKey(`${field}.country`, formik.errors)
                ? resolveKey(`${field}.country`, formik.errors)
                : ''
            }
          />
        </Col>
        {/*<Col md={hasWorkingHours ? 3 : 6} className="mb-2">*/}
        {/*  <CustomInput*/}
        {/*    label="State"*/}
        {/*    inputProps={{*/}
        {/*      id: `${[field]}.state`,*/}
        {/*      name: `${[field]}.state`,*/}
        {/*      type: 'text',*/}
        {/*      value: resolveKey(`${field}.state`, formik.values),*/}
        {/*      onChange: formik.handleChange,*/}
        {/*      disabled: isOrderForm || isViewOnly,*/}
        {/*    }}*/}
        {/*    error={*/}
        {/*      resolveKey(`${field}.state`, formik.touched) && resolveKey(`${field}.state`, formik.errors) ? resolveKey(`${field}.state`, formik.errors) : ''*/}
        {/*    }*/}
        {/*  />*/}
        {/*</Col>*/}
      </Row>
      {hasWorkingHours && (
        <Row>
          <Col md={6}>
            <CustomInput
              label="Working Hours"
              inputProps={{
                id: `${[field]}.workingHours`,
                name: `${[field]}.workingHours`,
                type: 'textarea',
                value: resolveKey(`${field}.workingHours`, formik.values),
                onChange: formik.handleChange,
                disabled: isViewOnly,
              }}
              error={
                resolveKey(`${field}.workingHours`, formik.touched) && resolveKey(`${field}.workingHours`, formik.errors)
                  ? resolveKey(`${field}.workingHours`, formik.errors)
                  : ''
              }
            />
          </Col>
        </Row>
      )}
    </div>
  );
};

type CreateAddressFormProps = {
  formik: any;
  isViewOnly?: boolean;
  field: string;
  hasWorkingHours?: boolean;
  isOrderForm?: boolean;
};

export { CreateAddressForm };
