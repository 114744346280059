import { ReactDatePickerCustomHeaderProps } from 'react-datepicker';
import { Button } from 'reactstrap';
import { CaretLeftFill, CaretRightFill } from '@styled-icons/bootstrap';

const START_YEAR = 2022;
const YEARS = Array(new Date().getFullYear() - START_YEAR + 5)
  .fill(0)
  .map((_, index) => START_YEAR + index);
const MONTHS = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];

const DatePickerHeader = ({
  date,
  changeYear,
  changeMonth,
  decreaseMonth,
  increaseMonth,
  prevMonthButtonDisabled,
  nextMonthButtonDisabled,
}: ReactDatePickerCustomHeaderProps) => {
  return (
    <div className="d-flex justify-content-around gap-2 px-2">
      <Button onClick={decreaseMonth} disabled={prevMonthButtonDisabled} className="btn-icon">
        <CaretLeftFill className="icon" />
      </Button>
      <select value={date ? new Date(date).getFullYear() : new Date().getFullYear()} onChange={({ target: { value } }) => changeYear(+value)}>
        {YEARS.map((option) => (
          <option key={option} value={option}>
            {option}
          </option>
        ))}
      </select>
      <select value={MONTHS[date ? new Date(date).getMonth() : new Date().getMonth()]} onChange={({ target: { value } }) => changeMonth(MONTHS.indexOf(value))}>
        {MONTHS.map((option) => (
          <option key={option} value={option}>
            {option}
          </option>
        ))}
      </select>
      <Button onClick={increaseMonth} disabled={nextMonthButtonDisabled} className="btn-icon">
        <CaretRightFill className="icon" />
      </Button>
    </div>
  );
};

export { DatePickerHeader };
