import { Button, Col, Row } from 'reactstrap';
import { CustomInput } from '../../../components/Input/CustomInput';
import { FieldArray } from 'formik';
import { Delete as DeleteIcon } from '@styled-icons/fluentui-system-filled/Delete';
import { ReactNode } from 'react';

const SectionTranslationPairForm = ({ formik, field, values, touched, errors, index, addonTitle, addonContent }: SectionTranslationPairFormProps) => {
  return (
    <Col className="d-flex flex-column gap-4">
      <Row>
        <CustomInput
          label="Paragraph Title"
          inputProps={{
            id: `title-${index}-${field}`,
            name: `${field}.title`,
            type: 'text',
            value: values?.title,
            onChange: formik.handleChange,
          }}
          addon={addonTitle}
          error={touched?.[index]?.title && errors?.title ? errors?.title : ''}
        />
      </Row>
      <Row>
        <FieldArray
          name={`${field}.texts`}
          render={(arrayHelpers) => (
            <Col>
              {values?.texts?.map((text: string, index: number) => (
                <Row key={`text-${index}`}>
                  <Col>
                    <CustomInput
                      label={`Paragraph #${index + 1}`}
                      inputProps={{
                        id: `paragraph-${index}-${field}`,
                        name: `${field}.texts[${index}]`,
                        type: 'textarea',
                        value: text,
                        onChange: formik.handleChange,
                      }}
                      addon={addonContent}
                      error={touched?.[index]?.texts[index] && errors?.texts[index] ? errors?.texts[index] : ''}
                    />
                  </Col>
                  {values?.texts?.length > 1 && (
                    <Col md={2} xxl={1} className="d-flex flex-column align-items-end">
                      <Button type="button" color="danger" onClick={() => arrayHelpers.remove(index)}>
                        <DeleteIcon className="icon danger" />
                      </Button>
                    </Col>
                  )}
                </Row>
              ))}
              <Row className="justify-content-center my-3">
                <Col md={6} xxl={3} className="d-flex justify-content-center">
                  <Button type="button" className="w-100 btn-md" onClick={() => arrayHelpers.push('')} color="primary">
                    Add a paragraph
                  </Button>
                </Col>
              </Row>
            </Col>
          )}
        />
      </Row>
    </Col>
  );
};

type SectionTranslationPairFormProps = {
  formik: any;
  field: string;
  values: any;
  touched: any;
  errors: any;
  index: number;
  addonTitle?: ReactNode;
  addonContent?: ReactNode;
};

export { SectionTranslationPairForm };
