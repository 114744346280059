import { Suspense, useEffect } from 'react';
import { Navigate, useNavigate } from 'react-router';
import { shallowEqual, useSelector } from 'react-redux';
import ErrorBoundary from '../ErrorBoundary';
import { Pages } from '../../enums';
import { getDashboardPath } from '../../utils';
import { AppState } from '../../store/redux/interfaces';
import { Row } from 'reactstrap';
import Loader from '../Loader';

const NotFoundRoute = ({ auth }: { auth: boolean }) => {
  const navigate = useNavigate();
  const { user } = useSelector(
    (state: AppState) => ({
      user: state.user,
    }),
    shallowEqual
  );

  useEffect(() => {
    const dashboardPath = getDashboardPath(user);
    navigate(dashboardPath);
  }, [user]);

  if (!auth) {
    return (
      <ErrorBoundary>
        <Suspense
          fallback={
            <Row className="align-items-center justify-content-center w-100">
              <Loader size="xl" />
            </Row>
          }
        >
          <Navigate to={Pages.LOGIN} />
        </Suspense>
      </ErrorBoundary>
    );
  }

  return null;
};

export { NotFoundRoute };
