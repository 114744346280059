import React, { useEffect, useState } from 'react';
import { toastr } from 'react-redux-toastr';
import { Col, Row, Spinner } from 'reactstrap';
import { Accept, useDropzone } from 'react-dropzone';
import { ImagePreview } from '../../../../components/ImagePreview/ImagePreview';
import { DownloadableImageNoUserId } from '../../../../types';
import { sizeValidator } from '../../../../utils';
import '../../../../styles/components/Upload.scss';
import ImageViewer from 'react-simple-image-viewer';
import { VideoPreview } from '../../../../components/VideoPreview/VideoPreview';

const Upload = ({ accept, maxSize, maxFiles, disabled, clientFullName, setImages, loadImages, onUpload, onDelete, creationTime }: UploadProps) => {
  const [isRemoving, setIsRemoving] = useState(false);
  const [isUploading, setIsUploading] = useState(false);
  const [imagesUrls, setImagesUrls] = useState<string[]>([]);
  const [currentImage, setCurrentImage] = useState<number>(0);
  const [isViewerOpen, setIsViewerOpen] = useState<boolean>(false);
  const [files, setFiles] = useState<DownloadableImageNoUserId[]>([]);
  const isDisabled = isUploading || isRemoving || files.length === maxFiles;
  const allDisabled = disabled || isDisabled;
  const { getRootProps, getInputProps } = useDropzone({
    accept,
    maxFiles,
    disabled: allDisabled,
    validator: (file) => sizeValidator(file, maxSize),
    onError: (err) => toastr.error(err.name, err.message),
    onDrop: async (acceptedFiles) => {
      await Promise.all(acceptedFiles.map((file) => onUpload(file, setIsUploading)));
      const images: DownloadableImageNoUserId[] = await loadImages(creationTime);

      if (!images) {
        setFiles([]);
        return setImages([]);
      }

      const filteredImages = images.filter((val) => !!val);
      if (filteredImages && filteredImages.length) {
        setFiles(filteredImages);
        setImages(filteredImages.map(({ url }) => url));
      }
    },
  });

  useEffect(() => {
    (async () => {
      if (!clientFullName.trim().length) {
        return;
      }

      const images: DownloadableImageNoUserId[] = await loadImages(creationTime);

      if (!images) {
        setFiles([]);
        return setImages([]);
      }

      const filteredImages = images.filter((val) => !!val);
      if (filteredImages && filteredImages.length) {
        setFiles(filteredImages);
      }
    })();
  }, [clientFullName, disabled, isDisabled]);

  const openImageViewer = (url: string) => {
    const imagesUrls = files.map((image: DownloadableImageNoUserId) => decodeURI(image.url));
    setImagesUrls(imagesUrls);
    setCurrentImage(imagesUrls.indexOf(decodeURI(url)));
    setIsViewerOpen(true);
  };

  const closeImageViewer = () => {
    setCurrentImage(0);
    setIsViewerOpen(false);
  };

  return (
    <Col>
      {isViewerOpen && (
        <ImageViewer
          src={imagesUrls}
          currentIndex={currentImage}
          disableScroll={false}
          closeOnClickOutside
          onClose={closeImageViewer}
          backgroundStyle={{
            backgroundColor: 'rgba(0,0,0 ,0.7)',
            zIndex: '31',
          }}
        />
      )}
      {!disabled && (
        <div {...getRootProps({ className: `upload-zone ${allDisabled ? 'disabled' : ''}` })}>
          <input {...getInputProps({ className: 'dropzone', disabled: allDisabled })} />
          {!allDisabled && <p className="text-center">Drag 'n' drop images here, or click to select them. Maximum {maxFiles} images</p>}
          {(isUploading || isRemoving) && (
            <Row className="success-label justify-content-center align-items-center">
              <Col xs={8} md={3} className="loading d-flex flex-column align-items-center">
                <Spinner width={20} color="secondary" />
                <div className="sr-only">{isUploading ? 'Uploading...' : 'Removing...'}</div>
              </Col>
            </Row>
          )}
          {disabled && (
            <h6 className="text-center">
              Drag 'n' drop of images is disabled. You need to click 'Update' at the top of the modal or enter client's full name first
            </h6>
          )}
        </div>
      )}
      <Row>
        {!!files.length && (
          <Row className="pt-4 preview-box">
            {files.map((file) => (
              <Col key={file.name} xs={12} md={6} className="mb-4 d-flex">
                {file.url.includes('.mp4') ? (
                  <VideoPreview
                    src={file.url}
                    alt={file.name}
                    disabled={allDisabled}
                    colWidth={{ xs: 12 }}
                    onDelete={async () => {
                      await onDelete(file.Key, setIsRemoving);
                      const images: DownloadableImageNoUserId[] = await loadImages(creationTime);
                      const filteredImages = images.filter((val) => !!val);
                      if (filteredImages && filteredImages.length) {
                        setFiles(filteredImages);
                      }
                    }}
                    onVideoClick={(e) => openImageViewer((e.target as HTMLInputElement).src)}
                  />
                ) : (
                  <ImagePreview
                    src={file.url}
                    alt={file.name}
                    disabled={allDisabled}
                    colWidth={{ xs: 12 }}
                    onDelete={async () => {
                      await onDelete(file.Key, setIsRemoving);
                      const images: DownloadableImageNoUserId[] = await loadImages(creationTime);
                      const filteredImages = images.filter((val) => !!val);
                      if (filteredImages && filteredImages.length) {
                        setFiles(filteredImages);
                      }
                    }}
                    onImageClick={(e) => openImageViewer((e.target as HTMLInputElement).src)}
                  />
                )}
              </Col>
            ))}
          </Row>
        )}
      </Row>
    </Col>
  );
};

type UploadProps = {
  accept: Accept;
  creationTime: number;
  maxSize: number;
  maxFiles: number;
  disabled: boolean;
  clientFullName: string;
  setImages: (urls: string[]) => void;
  loadImages: (creationTime: number) => Promise<DownloadableImageNoUserId[]>;
  onUpload: (image: File, setLoading: (state: boolean) => void) => Promise<void>;
  onDelete: (filename: string, setDeleting: (state: boolean) => void) => Promise<void>;
};

export { Upload };
